@import '../../../../styles/variables';

.entityFormFieldsContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    margin-top: 30px;
  }
}
