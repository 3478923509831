@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.search {
  margin: spacing(3) 0;
  text-align: right;

  div {
    display: inline-block;
  }

  input {
    margin-right: spacing(2);
    background-color: var(--white);
    border: 2px solid var(--white);
  }
}

.emptyState {
  padding: 20px 0px;
  max-width: 700px;
}

.infoBox {
  background-color: white;
}
