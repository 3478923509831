@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  width: 100%;

  .row {
    display: grid;
    grid-template-columns: 1fr 32px;
    grid-template-rows: auto spacing(1);
    margin-bottom: spacing(3);
    grid-gap: spacing(0.5) spacing(2);
  }

  .title {
    grid-column: 1;
    grid-row: 1;
    font-weight: var(--font-weight-medium);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .value {
    grid-column: 2;
    grid-row: 2;
    text-align: right;
    align-self: center;
  }

  .bar {
    grid-column: 1;
    grid-row: 2;
    border-radius: 4px;
    background-color: var(--grayscale5);
    width: 100%;
    overflow: hidden;

    &Inner {
      height: 100%;
      min-width: 1%;
    }
  }
}
