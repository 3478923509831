@import 'frontend/styles/functions';

.buildItemDescriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(0.5);
  & .title {
    font-size: var(--font-size-medium);
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacing(0.5);
    &.textSizeSmall {
      font-size: var(--font-size-small);
    }
    &.textSizeLarge {
      font-size: var(--font-size-large);
    }
    & .rightAdornment {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: center;
    }
  }
}
