@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.heading {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 spacing(2);
  position: relative;
  padding: spacing(2);
  border-bottom: 1px solid var(--grayscale15);
  align-items: start;
}

.metric {
  color: var(--text-grayscale70);
  margin-left: 10px;
}

.leftContent,
.rightContent {
  display: inline-block;
}

.title {
  color: var(--text-grayscale90);
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: var(--font-weight-medium);
}

.subtitle {
  color: var(--text-grayscale70);
  margin: 0;
  font-size: 13px;
  grid-column: 1;
}

.icon {
  cursor: pointer;
  width: 10px;
  margin-left: 10px;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(0);
}

.infoButton {
  background-color: transparent;
  margin-left: spacing(1);

  &:hover {
    background-color: var(--grayscale5);
  }
}
