@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonWrap {
  display: inline-block;
  margin: spacing(4) spacing(2);

  a {
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);

    &:hover {
      text-decoration: none;
      background-color: var(--grayscale30);
    }
  }

  svg path {
    stroke: var(--text-grayscale90);
  }
}
