@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.range {
  width: 100%;
  display: flex;
  align-items: center;

  :global {
    .rc-slider {
      max-width: 80%;
    }
    .rc-slider-track {
      background-color: var(--blue-ribbon);
    }
    .rc-slider-rail {
      background-color: var(--grayscale20);
    }
    .rc-slider-handle {
      background-color: var(--blue-ribbon);
      border-color: var(--blue-ribbon);

      &:focus {
        box-shadow: 0 0 9px 2px #60a0ff;
        @include noOutline {
          box-shadow: none;
        }
      }
      &:active {
        box-shadow: none;
      }
    }
  }

  &Deactive {
    opacity: 0.3;
    pointer-events: none;
  }
}

.unit {
  margin-left: spacing(3);
  white-space: nowrap;
}
