@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.actionsButton {
  margin-left: spacing(1);
}

.formContainer {
  max-width: 400px;
  margin-bottom: spacing(6);
}

.formGroup {
  margin-bottom: spacing(1);
}

.formButton {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: spacing(3);
}
.closeEdit {
  padding-left: 0;
}

.fieldAction {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.editForm {
  display: flex;
  align-items: center;
}
.save {
  margin-left: spacing(1);
}

.enableForm {
  @include noOutline;
  padding: spacing(1) 0;

  &:hover {
    cursor: pointer;
    color: var(--black);
  }
}
