@import 'frontend/styles/functions';

.container {
  display: flex;
  flex-direction: column;
  margin-top: spacing(1);
  gap: spacing(0.5);
  color: var(--text-grayscale90);
  position: relative;
  &::before {
    content: '';
    width: calc(100% + spacing(3));
    height: 1px;
    background-color: rgba(13, 13, 13, 0.08);
    position: absolute;
    left: - spacing(1.5);
  }

  & .title {
    padding-top: spacing(0.5);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
    margin-bottom: spacing(0.625);
  }

  & .referenceList {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(1);
    & .reference {
      font-size: var(--font-size-small);
      color: var(--text-grayscale90);
      border: 1px solid rgba(13, 13, 13, 0.2);
      padding: spacing(0) spacing(0.375);
      font-weight: normal;
    }
  }

  & .referenceText {
    margin-left: 3px;
    line-height: 16px;
    font-size: var(--font-size-small);
  }

  & .sup {
    position: relative;
    font-size: 0.7em;
  }
}
