@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.content {
  max-width: 1040px;
  padding-top: 0;
}

.docs {
  color: var(--grayscale70);
  display: block;
  text-align: right;
}

.logo {
  width: 180px;
  margin-bottom: spacing(6);
}

.buttonContainer {
  margin: spacing(4) spacing(2);

  a {
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);

    &:hover {
      text-decoration: none;
      background-color: var(--grayscale30);
    }
  }

  svg path {
    stroke: var(--text-grayscale90);
  }
}

.textContainer {
  h3 {
    font-weight: var(--font-weight-medium);
  }

  a {
    color: #0069ff;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.snippet {
  display: block;
  max-width: 670px;
  margin: spacing(3) 0 spacing(2);
  white-space: pre-wrap;
}
