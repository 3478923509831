@import '../../styles/variables';

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 50px;
  align-items: center;
  text-align: center;
}

.avatar {
  width: 210px;
  margin: 0 auto;
}
