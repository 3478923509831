@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.wrapper {
  border-radius: var(--border-radius-medium);
  padding: 20px 0 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.title {
  font-size: var(--font-size-h4);
  margin-bottom: 15px;
}

.infoButton {
  position: absolute;
  right: 0px;
  top: 21px;
  background-color: var(--grayscale90);
  border: none;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include noOutline;

  &:hover {
    background-color: var(--white);
    border: 1px solid var(--border-color-base);
  }

  svg {
    height: 13px;
  }
}
