// Kindly Sans
@font-face {
  font-family: KindlySans;
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/KindlySans-Regular.otf') format('opentype');
}

// IBM Plex
@font-face {
  font-family: IBMPlex;
  font-weight: 100;
  font-style: normal;
  src: url('../assets/fonts/IBMPlexSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 100;
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 500;
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 600;
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 800;
  font-style: normal;
  src: url('../assets/fonts/IBMPlexSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: IBMPlex;
  font-weight: 800;
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans-BoldItalic.ttf') format('truetype');
}
