@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonListWrapper {
  display: flex;
  flex-direction: column;
  margin-top: spacing(2);
  align-items: flex-start;
}

.button {
  &Dragging {
    opacity: 0.3;
  }

  &:hover .options {
    transform: scaleX(1);
    transition: transform 0.1s linear;
  }
}

.trashIcon {
  position: relative;
  top: 1px;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  width: calc(100% - 2px);
  border: none;
  transform: scaleX(0);
  transition: transform 0.1s linear;
  overflow: hidden;
  left: 1px;
  height: calc(100% - 2px);
  top: 1px;
  background: var(--white);
  border-radius: 30px;

  &Buttons {
    display: flex;
    width: 70px;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &Item {
      opacity: 0.35;

      &:hover {
        opacity: 1;
      }

      svg {
        height: 16px;
        cursor: pointer;
      }
    }
  }
}
