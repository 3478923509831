@import 'frontend/styles/variables';

.toolTip {
  background: white;
  padding: 5px;

  &Text {
    display: 'block';
    font-weight: var(--font-weight-medium);
  }

  &Label {
    display: 'inline-block';
    margin-bottom: '5px';
  }
}
