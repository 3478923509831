@import 'frontend/styles/variables';

.listItem {
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}

.chevron {
  padding: 14px;
  font-size: 16px;
  color: var(--grayscale60);
  cursor: pointer;
  &:hover {
    color: var(--grayscale90);
  }
}
