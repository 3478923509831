@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.summary {
  border-top: 1px solid var(--grayscale30);
  margin: 0;
  padding: spacing(2) 0;

  &:last-child {
    border-bottom: 1px solid var(--grayscale30);
  }
}
