@import './variables';

@mixin noOutline {
  :global(body:not(.using-tab)) & {
    outline: none;
    @content;
  }
}

@mixin sharedDotsStyle($color, $size) {
  content: '';
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $color;
  position: absolute;
}

// Orientation can be 'horisontal' or 'vertical'
@mixin threeDots($color: var(--text-grayscale90), $orientation: 'horisontal', $size: 3px, $space: 7px) {
  @include sharedDotsStyle($color, $size);

  &:before,
  &:after {
    @include sharedDotsStyle($color, $size);
  }
  &:before {
    @if ($orientation == 'horisontal') {
      left: -$space;
    }
    @if ($orientation == 'vertical') {
      top: -$space;
      left: 0;
    }
  }
  &:after {
    @if ($orientation == 'horisontal') {
      left: $space;
    }
    @if ($orientation == 'vertical') {
      top: $space;
      left: 0;
    }
  }
}

@mixin buttonReset {
  background: transparent;
  border: 0;
  outline: 0;
}

/*
  Override default scrollbar design for webkit browsers.
  Using border and box-shadow so we can have a wider 
  scrollbar on hover without jumping content.
*/
@mixin scrollBars() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px 4px transparent;
    border: solid 2px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 4px 4px var(--grayscale20);
    border: solid 1px transparent;
    border-radius: 5px;
  }
  &:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 4px 4px var(--grayscale70);
    border: solid 0px transparent;
    transition: all linear 400ms;
  }
}
