@import 'frontend/styles/variables';

:export {
  /* stylelint-disable-next-line */
  trainingColor: var(--blue-ribbon);
}

.actionPanel {
  margin-top: 20px;
}
.dailyTrainingMessage {
  padding-left: 20px;
  color: var(--text-grayscale70);
  u {
    text-decoration: none;
    font-weight: 600;
  }
}

.panel {
  margin: 10px 0 50px;
}

.header {
  margin-bottom: 20px;
}

.languageToggles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selectContainer {
  width: 100%;
  max-width: 260px;
  margin: 10px 0;
}
