@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.optionsButton {
  width: 24px;
  height: 24px;
  opacity: 0;
  pointer-events: none;
  margin-top: 4px;
}

.listHeader {
  margin-bottom: spacing(2);

  span {
    font-weight: var(--font-weight-medium);
  }
}

.listContainer {
  .panel {
    border-width: 2px;
    align-items: center;
    padding: spacing(0.5) spacing(2);

    &:hover .optionsButton {
      opacity: 1;
      pointer-events: auto;
    }

    &:not(:last-child) {
      margin-bottom: spacing(1);
    }
  }
}

.listHeader,
.listContainer .panel {
  display: grid;
  grid-template-columns: 130px 1fr 30px;
}

.name {
  background-color: var(--grayscale40);
  max-width: fit-content;
  padding: 1px 4px;
  border-radius: var(--border-radius-small);
}
