@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.sectionHeader {
  width: 100%;
  border-top: 1px solid var(--grayscale30);
  padding: spacing(3) 0;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: spacing(2);
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: var(--shadow-color-dark);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;

  @include noOutline;

  &Visible {
    opacity: 1;
    visibility: visible;
    transition: all 300ms linear;
  }
}
