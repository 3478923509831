@import '../../../../styles/variables';

.skillSamples {
  display: flex;
  flex-direction: column;
  color: var(--text-grayscale70);
  margin-bottom: 40px;

  .skillSampleItem {
    position: relative;
    font-size: 14px;
    margin: 8px 0;
    line-height: 22px;
  }
}
