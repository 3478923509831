@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.dialogueRow {
  display: flex;

  .icon {
    margin-right: spacing(1);
    width: 38px;
    height: 38px;
    padding-left: 6px;
    border-radius: 4px;
    background-color: #fff4db;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .iconItem {
    transform: rotate(-45deg);
    color: #8e6a06;
  }

  .inputField {
    flex: 1;

    .input input {
      flex: 1;

      padding: spacing(1);
      border-color: var(--grayscale5);
      background-color: var(--grayscale5);

      &:hover {
        border-color: var(--blue-ribbon);
        cursor: text;
      }
    }
  }
}

.link {
  display: block;
  text-decoration: underline;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: var(--blue-ribbon);
  }
}

.emptyPanel {
  display: grid;
  background-color: var(--grayscale5);
  border: 2px solid var(--grayscale5);
  box-shadow: none;

  span {
    font-size: 14px;
  }
}
