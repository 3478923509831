@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.carouselContainer {
  position: relative;
  overflow: hidden;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 14px;
    transform: translate(0);
  }
}

.product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  &Wrapper {
    text-decoration: none;
    color: initial;
    cursor: pointer;
    width: 100%;
  }

  &Image {
    width: 100%;
    height: 155px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .content {
    max-width: 100%;
  }

  .title {
    font-weight: 500;
  }

  .description {
    font-weight: 400;
  }

  .price {
    font-weight: 500;
  }

  .link,
  .title,
  .description,
  .price {
    display: block;
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .link {
    color: #333;
    margin-top: 8px;
    text-decoration: underline;
    cursor: pointer;
  }
}
