@import 'frontend/styles/functions';

.container {
  position: relative;
  height: 34px;
  width: 34px;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  cursor: pointer;

  &:focus-within,
  &:hover {
    border-color: var(--primary-color);
  }
}

.image {
  display: block;
  max-width: 100%;
  padding: 2px;

  .container:focus-within &,
  .container:hover & {
    opacity: 0.5;
  }
}

.overlay {
  position: absolute;
  display: none;
  inset: 0;
  justify-content: center;
  align-items: center;

  > svg {
    color: var(--primary-color);
    font-size: 15px;
  }

  .container:focus-within &,
  .container:hover & {
    display: flex;
  }
}

.tooltip.tooltip.tooltip {
  padding: spacing(0.5);
}
