@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

div.wrapper:not(:disabled) {
  button.viewButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: unset;
    border: none;
    border-radius: var(--border-radius-small);
    text-align: left;
    font-weight: var(--font-weight-medium);
    padding: spacing(1);
    color: var(--text-grayscale70);

    &:hover:not(:disabled) {
      background-color: var(--grayscale5);
      color: var(--text-grayscale90);
    }

    &Active,
    &Active:hover:not(:disabled) {
      background-color: var(--blue-ribbon);
      color: var(--white);
      cursor: default;

      div.count {
        color: var(--white);
      }
    }

    .count {
      margin-left: spacing(0.5);
      color: var(--blue-ribbon);
    }
  }
}
