@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.pageContent {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-gap: spacing(4);

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
