@import 'frontend/styles/functions';

.infoModalContent {
  color: var(--text-grayscale80);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-450);
  line-height: 20px;
  padding: spacing(1.5) spacing(2);
}

.infoModalHeader {
  padding-bottom: spacing(0.5);
  line-height: 20px;
}
