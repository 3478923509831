@import 'frontend/styles/forms';

.formControl {
  border-radius: var(--border-radius-large);
  font-family: var(--font-family);
  resize: none;
  vertical-align: middle; // to avoid extra space below input/textarea without touching display

  // Remove arrows from input type number
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &Small {
    padding: 4px 11px;
    font-size: 12px;
  }

  &Medium {
    padding: 6px 11px;
  }

  &Hidden {
    display: none;
  }

  &ReadOnly {
    opacity: 0.6;
  }

  &Error,
  &Error:focus,
  &Error:hover {
    border-color: var(--red-pomegranate);
  }

  &Error:focus ~ .errorMessage {
    display: inline-block;
  }

  &:disabled:hover {
    border-color: var(--grayscale20);
  }

  &IconPaddingLeft {
    padding-left: 23px;
  }
}

.inlineAdornment {
  position: absolute;
  top: 50%;
  font-size: 14px;
  pointer-events: none;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  & > *:not(svg) {
    padding: 0 4px;
  }
  & > svg {
    &:only-child {
      padding: 0;
    }
    font-size: 14px;
  }
}

.adornmentPositionRight {
  right: 9px;
}

.adornmentPositionLeft {
  left: 8px;
}

.errorMessage {
  display: none;
}

.labelWrapper {
  display: flex;
  gap: spacing(1);
  justify-content: space-between;
  align-items: center;
  & > label {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: spacing(0.5);
  }
}

.inlineLimit {
  font-size: 12px;
  pointer-events: none;
  color: var(--text-grayscale90);

  &Error {
    color: var(--red-pomegranate);
  }

  &.aboveLimit {
    color: var(--red-pomegranate);
  }
}
