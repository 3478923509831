@import 'frontend/styles/functions';

.notificationPreviewContainer {
  margin: spacing(1);
  display: flex;
  padding: 12px;
  justify-content: space-between;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: var(--box-shadow-card);
  width: 344px;

  .notificationContent {
    margin: 0;
  }

  .languageIcon {
    display: flex;
    align-items: center;
    font-size: 27px;
    height: 100%;
    margin-right: 12px;
  }

  .exitCircleIcon {
    display: inline;
    min-width: 24px;
    min-height: 24px;
  }
}

.quickLink {
  background: var(--grayscale0);
  border-radius: 4px;
  display: inline-block;
  padding-inline: spacing(0.5);

  svg {
    vertical-align: sub;
  }
}
