@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.plusMinus {
  &Number {
    width: 3em;
    border: none;
    padding: 0 5px;
    font-size: 16px;
    text-align: center;
    background-color: inherit;

    &:focus {
      @include noOutline;
    }
  }
  &Infinity {
    padding: 0 10px;
  }
  &Button {
    padding-left: 0;
    padding-right: 0;
    min-width: 39px !important;
    max-width: 39px;

    svg {
      margin: 0;
    }
  }
}

.helpText {
  font-style: italic;
  font-size: 12px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: -30px;

  p {
    margin: 0;
  }
}

.chooseDateRange {
  text-align: center;
  padding: spacing(1) 0 0;
  margin-left: -(spacing(5));
}

.formErrors {
  position: relative;
  top: 0;
  max-width: unset;
  margin-left: spacing(4);
}

.lanuageSelector {
  max-width: 250px;
  margin: 0 0 spacing(3);
}

.button {
  display: inline-flex;
  vertical-align: middle;

  svg {
    margin: 0;
  }
}
