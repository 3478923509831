@import 'frontend/styles/functions';

.trigger {
  padding: spacing(0.5) spacing(1);
}

.filtersDropdownOverlay {
  padding: spacing(1);
  overflow: auto;
  width: max-content;
  max-width: 480px;
}

.inboxFiltersEmptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.inboxFiltersIcon {
  background-color: var(--triad-blue-light);
  border-radius: var(--border-radius-medium);
  padding: spacing(1);
  align-self: center;
  width: 56px;
  height: 56px;
  margin-bottom: spacing(3);
  & .inboxFilterIcon {
    width: 40px;
    height: 40px;
    color: var(--triad-blue-medium);
  }
}

.inboxFiltersTitle {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale90);
  font-feature-settings:
    'clig' off,
    'liga' off;
  margin-bottom: spacing(1);
}

.inboxFiltersDescription {
  font-size: var(--font-size-medium);
  line-height: 20px;
  font-feature-settings:
    'clig' off,
    'liga' off;
  color: var(--text-grayscale70);
  text-align: center;
  max-width: 270px;
  margin-bottom: spacing(2);
}

.inboxActiveFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-bottom: none;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  flex: initial;
  & .inboxActiveIconWrapper {
    display: flex;
    align-items: center;
    flex: initial;
    & svg {
      margin: spacing(0.5) spacing(0.75);
    }
  }

  & .inboxButtonWrapper {
    display: flex;
    align-items: center;
    & > div {
      border-left: 1px solid var(--grayscale15);
      border-right: 1px solid var(--grayscale15);
    }
    & svg {
      margin: spacing(0.5) spacing(0.75);
    }
    & .horizontalDropdownButton {
      border: none;
      border-radius: 0;
      height: 26px;
      padding: 0 spacing(1.5);
    }
  }
}
