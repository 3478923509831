@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.inputContainer {
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 1fr 1fr;

  .dialogueRow {
    display: flex;
    align-items: center;
    min-width: 300px;

    .icon {
      margin-right: spacing(1);
      width: 38px;
      height: 38px;
      border-radius: var(--border-radius-small);
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #ffe4dc;
      flex-shrink: 0;
    }
    .dialogue {
      display: flex;
      align-items: center;

      .icon {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: spacing(1);
      }

      .titleContainer {
        max-width: clamp(225px, 25vw, 400px);

        .title {
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          align-items: center;
        }
      }
    }
  }

  .field {
    align-self: center;
  }

  .seperator {
    margin: spacing(1) 0;
    border-bottom: 1px solid var(--grayscale20);
  }

  .action {
    display: flex;
    align-items: center;

    .text {
      margin-left: 3px;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  &:hover {
    cursor: default;
  }
}
