@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.infoButton {
  margin-left: spacing(1);
}

.imageSizesWrapper {
  all: unset;

  display: flex;
  padding-top: spacing(2);
  align-items: center;
  gap: spacing(2);

  legend {
    display: contents; // `legend` are special elements, this makes it inline
    font-weight: var(--font-weight-medium);
  }
}
