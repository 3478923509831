@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.label {
  display: flex;
  justify-content: space-between;
  margin-top: spacing(4);
  margin-bottom: 4px;
}

.inputContainer {
  width: 50%;

  @media (max-width: $desktop-small) {
    width: 100%;
  }
}

.paragraph {
  margin-top: spacing(3);
}
