@import 'frontend/styles/forms';
@import 'frontend/styles/functions';

@mixin selectInput {
  display: block;
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  color: var(--text-grayscale90);
  margin: 0;
  font-family: var(--font-family);
  padding: 0 spacing(1);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.renderDefaultOption {
  display: inline-block;
  padding: spacing(1);
  cursor: pointer;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}

.container {
  position: relative;
  background: var(--grayscale5);
  border-color: var(--grayscale5);
  border-radius: var(--border-radius-large);
  font-family: var(--font-family);

  &:hover,
  &:focus {
    outline: none;
    border-color: var(--blue-ribbon);
  }

  &::before {
    content: '';
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    z-index: 1;
    cursor: pointer;
    pointer-events: none;

    border-top: 7px solid var(--grayscale60);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

.input {
  @include selectInput;
}

.selectSearchError {
  @include selectInput;
  border: 2px solid var(--red-pomegranate);
  border-radius: var(--border-radius-large);
}

.shortDropdown {
  @include selectInput;
  max-height: 160px !important;
}

.container:not(.has-focus) .select {
  display: none;
}

.options {
  padding: 0;
  margin: 0;
}

.select {
  display: block;
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  background: var(--white);
  border-radius: var(--border-radius-large);
  overflow: auto;
  box-shadow:
    0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 30px;
  max-height: 310px !important;
  padding: spacing(2) spacing(2) spacing(3);
}

.has-focus .select {
  display: block;
}

.multiple .select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.row {
  font-size: 13px;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  user-select: none;
  position: relative;
  list-style-type: none;

  &:first-child {
    border-top: none;
  }
  &[role='menuitem']:hover {
    background: var(--grayscale5);
    border-radius: var(--border-radius-large);
  }
}

.is-selected {
  font-weight: var(--font-weight-medium);
  background: var(--grayscale5);
  border-radius: var(--border-radius-large);

  &:after {
    display: block;
    content: '';
    width: 13px;
    height: 12px;
    top: 11px;
    right: 8px;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 13'%3E%3Ctitle%3ECheck icon%3C/title%3E%3Cpath fill='none' stroke='%23000000' d='M2 6.5L5.61 11 12 2'/%3E%3C/svg%3E");
  }
}

.group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
