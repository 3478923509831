@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.warning {
  background: var(--grayscale5);
  padding: 0 spacing(1) spacing(1);
  border-radius: var(--border-radius-large);
  font-size: 14px;
  margin-top: spacing(2);
  line-height: 22px;

  span {
    font-weight: var(--font-weight-medium);
  }

  a {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--text-grayscale70);
    }
  }
}

.warningIcon {
  margin-right: spacing(0.5);
  position: relative;
  top: 7px;
}
