.content {
  padding: 0;
}

.headline {
  display: flex;

  button {
    margin-left: 1em;
  }
}
