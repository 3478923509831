@import 'frontend/styles/variables';

.container {
  display: flex;
  height: 22px;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  max-width: fit-content;

  &:has(.checkbox:focus-visible) {
    box-shadow: 0px 0px 1px 2px #60a0ff;
    border-radius: 1px;
  }

  &:has(.checkbox:disabled) {
    opacity: 0.4;
    cursor: not-allowed;
    user-select: none;
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
  left: -999999px;
}

.switch {
  position: relative;
  height: 18px;
  width: 36px;
  outline: none;
  display: inline-block;
  border-radius: 100px;
  background-color: var(--blue-ribbon);

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 0;
    width: 14px;
    height: 14px;
    background-color: var(--white);
    border-radius: 50%;
    transform: translateX(-100%);
  }
}

.switchOn {
  background-color: var(--blue-ribbon);

  &:after {
    left: calc(100% - 2px);
    transition: left 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}

.switchOff {
  background-color: var(--grayscale30);

  &:after {
    left: 16px;
    transition: left 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}
