@import 'frontend/styles/functions';

.container {
  display: flex;
  color: var(--grayscale70);
  margin: spacing(1);
  align-items: center;
}

.button {
  all: unset;
  outline: revert;
  margin-left: auto;
  cursor: pointer;

  svg {
    display: block;
  }
}
