@import 'frontend/styles/functions';

.newAnalyticsWrapper {
  display: flex;
  flex-direction: row;
  & .newAnalyticsContentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & .newAnalyticsMenu {
    min-width: 208px;
    margin: spacing(2);
  }
}
