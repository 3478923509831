@import 'frontend/styles/functions';

.wrapper {
  display: inline-block;
  box-shadow: var(--box-shadow-card);
  border-radius: var(--border-radius-large);
  background-color: #fff;
  width: 100%;
  position: relative;
}

.labelContainer {
  margin-top: spacing(3);

  > span {
    margin: 0;
  }
}

.outlinePicker label,
.gridContainer label {
  margin-top: spacing(1.25);
  margin-bottom: spacing(0.5);
  padding: 0;
  letter-spacing: 1px;
  font-size: var(--font-size-small);
}

.appearanceInput {
  max-width: 150px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: spacing(1) spacing(4);
  align-items: center;

  input {
    cursor: pointer;
  }
}

.avatarField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: spacing(2);

  .avatar {
    height: 72px;
    min-height: 72px;
    width: 72px;
    margin-right: spacing(2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
  }
}

.appearanceColor {
  height: 16px;
  width: unset;
  left: 0;
  right: 0;
}

.switchContainer {
  display: flex;
  align-items: center;

  .switchWrapper {
    margin-right: spacing(2);
  }
}

.seasons {
  width: 150px;
}

.appearanceItemSeparator {
  margin-top: spacing(3);
}

.sectionDescription {
  max-width: 500px;
}
