@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.publishButtons {
  display: flex;
  align-items: center;

  &Wrapper {
    text-align: center;
    position: relative;
    height: fit-content;
  }
}

.publishChangesButton {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: spacing(1);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-large);
}

.dropdownTrigger {
  display: flex;
  width: 28px;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  color: var(--text-grayscale90);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grayscale15);
  transition: all 150ms cubic-bezier(0.17, 0.67, 0.83, 0.67);

  &:hover {
    background-color: var(--grayscale0);
    cursor: pointer;
  }

  &:active,
  &[aria-expanded='true'] {
    background-color: var(--grayscale5);
  }
}

.plusIcon {
  color: var(--grayscale70);

  .dropdownTrigger:active:not(:disabled) &,
  .dropdownTrigger[aria-expanded='true'] & {
    color: var(--blue-ribbon);
  }
}
