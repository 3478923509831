@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.chatButtonWrapper {
  display: flex;
  margin-top: 14px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.attachements {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.imageCarousel {
  margin-top: 10px;

  &Image {
    max-height: 250px;
    border-radius: 18px;
  }
}
