@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.panel {
  margin: auto;
  display: block;
  max-width: 630px;
}

.wrapper {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 400px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 var(--shadow-color);
  z-index: calc(var(--navbar-z-index) + 10);
  text-align: left;
  font-size: var(--font-size-h4);
  color: var(--text-grayscale90);
  border-radius: var(--border-radius-small);

  @media (max-width: $tablet) {
    right: -102px;
    width: calc(100vw - 30px);
  }
}

.dropdownOpen {
  display: block;
}

.close {
  padding: 0;

  svg {
    color: white !important;
  }
}

.iconText {
  display: flex;
  align-items: center;

  svg {
    margin-right: spacing(0.5);
  }
}

.text {
  margin: 0;
  padding: spacing(2);
}

.link {
  text-align: right;
  padding: 0 spacing(2) spacing(2);
}

.linkButton {
  display: inline-block;
}

.warning {
  @include buttonReset;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms ease;
  padding: 0;

  &:hover {
    background-color: var(--grayscale5);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background: var(--red-pomegranate);
  padding: spacing(1);
  border-top-left-radius: var(--border-radius-small);
  border-top-right-radius: var(--border-radius-small);
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.deleteButton {
  display: inline-flex;
  height: auto;

  svg {
    font-size: 12px;
  }
}

.newAlertButton {
  display: inline-flex;
}
