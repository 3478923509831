@import '../../../styles/variables';
@import '../../../styles/functions';

.formErrors * {
  color: var(--white);
}

.flexContainer {
  display: flex;
  align-items: center;

  &Spaced {
    justify-content: space-between;
    span {
      top: 0;
    }
  }
}

.chatbubbleSettingsWrapper {
  position: relative;
}

.selectLanguage {
  margin-bottom: spacing(2);
  position: absolute;
  top: spacing(2);
  right: spacing(2);
}

.inputContainer {
  width: 50%;

  @media (max-width: $desktop-small) {
    width: 100%;
  }
}
