@import '../../../../styles/variables';
@import '../../../../styles/functions';

.noPaddingRight {
  padding-right: 0;
}

.statContainer {
  text-align: center;
  padding: 0 spacing(2);
  border-left: 1px solid var(--grayscale30);

  p,
  h1 {
    margin: 0;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .metaContainer,
  .botContainer {
    display: flex;
    align-items: center;
  }

  .bot {
    &Logo {
      height: 74px;
      width: 74px;
      object-fit: cover;
      margin-right: spacing(4);
      border-radius: var(--border-radius-large);
    }

    &Name {
      margin-bottom: 0;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-extralarge);
    }
  }

  .languagesContainer {
    display: flex;
    .languageCode {
      background-color: var(--grayscale5);
      border-radius: var(--border-radius-medium);
      display: inline-block;
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      padding: 3px 9px;
      margin-right: spacing();
    }
  }

  .connectContainer {
    height: 20px;
    margin-right: spacing(3);

    img {
      height: 100%;
      margin-left: spacing();
    }
  }
}
