@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.containerPreview {
  grid-column: 1;
  grid-row: 2;
  border-right: 1px solid var(--grayscale5);
  padding: spacing(2) spacing(3);
  position: relative;
}

.previewBox {
  height: 600px;
  position: sticky;
  top: 130px;
  border-radius: var(--border-radius-small);
}

.intent {
  &Container {
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-large);
    text-align: center;
    padding: spacing(2);
    margin-bottom: spacing(2);
  }

  &Title {
    background-color: var(--white);
    border-radius: 30px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    padding: spacing(1) spacing(2);
    margin-bottom: spacing(0.5);
    display: inline-block;
  }

  &Description {
    font-size: 12px;
    margin: 0;
    display: block;
  }
}
