@import 'frontend/styles/functions';

.insightsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: var(--box-shadow-card);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  & .insightsFilter {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.insightsConversationWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 2px solid var(--grayscale15);

  & .insightsConversationListWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 320px;
    background-color: var(--white);
    border-right: 1px solid var(--grayscale15);
    & .insightsConversationSearchWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacing(0.5);
      padding: spacing(1);
      position: relative;
      &.insightsConversationSearchWrapperScrolled {
        z-index: 1;
        box-shadow: var(--box-shadow-card-bottom);
      }
      & .search {
        flex: 1;
        & > input {
          padding-right: spacing(4);
        }
      }
      & .insightsConversationSearchInput {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        flex: 1;
        & .insightsConversationSearchInputClose {
          position: absolute;
          right: spacing(1);
          top: 50%;
          transform: translateY(-50%);
        }
        & .searchLoader {
          position: absolute;
          right: spacing(4);
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          padding: spacing(1);
          background-color: var(--gray-mischka);
          & > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  & .insightsConversationInfoWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

.trigger {
  padding: spacing(0.5) spacing(0.5);
  margin: 0;
  min-width: auto !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > svg {
    margin: 0;
  }
}

.dropdown {
  overflow-y: auto;
  padding: spacing(0.5) spacing(1);
}
