.container {
  ul {
    list-style: none;
  }

  .showMore {
    text-decoration: underline;
    cursor: pointer;
  }
}
