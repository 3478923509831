@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow);
  top: 133px;
  position: sticky;
}

.link {
  display: block;
  padding: spacing(2) spacing(3);
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale90);
  text-decoration: none;
  font-size: 14px;
  position: relative;
  transition: background-color 0.1s ease;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grayscale5);
  }

  &:before {
    content: '';
    width: 6px;
    height: 36px;
    position: absolute;
    left: -6px;
    top: 7px;
    z-index: -1;
    border-radius: 20px 0 0 20px;
    transition: background-color 0.1s ease;
  }

  &:hover {
    background: var(--grayscale5);

    &:before {
      background: var(--grayscale10);
    }
  }

  &Active {
    color: var(--blue-ribbon);

    &:before {
      // use important here to override hover color when active
      background: var(--blue-ribbon) !important;
    }
  }
}

.chatbubbleSettingsWrapper {
  z-index: 0;
  margin: auto;
  width: 100%;
  max-width: 807px;

  .languagesWrapper {
    max-width: 250px;
    margin-bottom: 30px;

    select {
      border-radius: var(--border-radius-large);
    }
  }
}

.panelInner {
  max-width: 390px;
}
