@import 'frontend/styles/functions';

.modalContent {
  padding: 0 50px;
  min-height: 300px;

  pre {
    width: 100%;
    max-height: 300px;
    overflow: scroll;
    font-size: 0.63em;
    line-height: 1.45;
    text-align: start;
    background-color: var(--grayscale20);
    padding: spacing(1);
  }
}

.warning {
  color: var(--error-color);
  line-height: 1.2;
}
