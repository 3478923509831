@import '../../../../../../styles/variables';
@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.formModalFooter {
  padding: 20px 16px 16px 16px;
  grid-gap: 0;
}

.formModalContent {
  padding: 16px 16px 8px 16px;
  font-size: 14px;
  color: var(--grayscale80);
}

.formModalHeader {
  padding: 16px 16px 8px 16px;
}

.formSettingsWrapper {
  width: 280px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 2px solid var(--grayscale15);
  & .formSettingsHeader {
    display: flex;
    flex: 0 0 70px;
    justify-content: space-between;
    align-items: center;
    padding: spacing(2.2);
    font-size: inherit;
    vertical-align: middle;
    border-left: 2px var(--grayscale5) solid;
    border-bottom: 2px var(--grayscale5) solid;

    & .heading {
      display: inline-flex;
      margin-block: auto;
      justify-content: center;
      align-items: center;
      gap: spacing(0.5);
      font-size: inherit;
      font-weight: var(--font-weight-medium);
      color: var(--grayscale90);
    }

    & .closeButton {
      padding: 0;
      color: var(--grayscale90);
      background-color: transparent;
      border: 1px var(--grayscale15) solid;
      border-radius: var(--border-radius-medium);
      cursor: pointer;

      svg {
        vertical-align: bottom;

        &:active {
          color: var(--blue-ribbon);
        }
      }

      &:hover {
        background-color: var(--grayscale10);
      }
    }
  }

  & .formSettingsContent {
    margin: 0 16px;
    & .formHorizontalSeparator {
      width: 100%;
      height: 1px;
      background-color: var(--grayscale15);
    }
    & .formSettingsInput {
      padding: 8px 0 16px;
      & .formSettingsInputDescription {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-normal);
        color: var(--grayscale70);
        line-height: 18px;
      }
    }

    & .formSettingsAction {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 12px;
      margin-bottom: 136px;
    }
  }
}

.labelContainer {
  font-size: var(--font-size-small);
  color: var(--grayscale80);
  display: flex;
  flex-direction: row;
  align-items: center;
  &.checkbox {
    color: var(--grayscale90);
  }
}
