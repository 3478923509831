@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.addKindlyEntities {
  font-size: 14px;
  background-color: var(--grayscale20);

  > div {
    grid-row-gap: 0;
    grid-column-gap: 0;
  }

  &Warning {
    line-height: 22px;
    margin-bottom: 10px;
  }

  &Row {
    cursor: pointer;
    @include noOutline;

    &:hover > div {
      background: var(--grayscale5);
    }
  }

  .description {
    line-height: 22px;
    @include noOutline;
  }

  .toggleContainer {
    position: relative;
    top: 3px;
  }
}

.waitButton {
  text-align: right;
  margin: spacing(5) 0 0;
}
