@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';
@import 'frontend/features/Inbox/inbox-styles';

.conversationContainer,
.sidebarContainer {
  height: $inboxHeight;
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .conversationHeader {
    display: flex;
    width: 100%;
    background: var(--white);

    .navigationButtons {
      flex: 0;
      justify-content: flex-end;
      padding: spacing(2);
    }

    .subjectContainer {
      flex: 1;
      font-weight: 500;
      padding: 16px 0 16px 16px;
      display: flex;
      align-items: center;
      min-width: 0;
      .subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: $phone) {
    padding: 0 10px;
  }
}

.sidebarContainer {
  grid-column: 3;

  %shared {
    padding: var(--gutter-medium);
    border-bottom: 1px solid var(--grayscale5);
  }
}

.notFound {
  grid-column-start: 2;
  grid-column-end: 5;
}
