@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.webhookFooter {
  display: flex;
  margin-top: 14px;
  justify-content: space-between;

  p {
    margin-top: 4px;
  }

  svg {
    margin: spacing(0.5) 0;
    padding: 0;
  }
}
