.cancelButton {
  font-weight: var(--font-weight-medium);
  padding: 8px 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  svg {
    font-size: 20px;
  }
}
