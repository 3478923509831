@import 'frontend/styles/functions';

div.datePickersWrapper:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: spacing(1);

  label:first-child {
    margin-bottom: spacing(1);
  }
}
