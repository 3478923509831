@import 'frontend/styles/typography';

.newsLayout {
  .panel,
  .newsItem {
    margin: 80px auto;
    display: block;
    max-width: 628px;

    textarea {
      min-height: 360px;
    }
  }
  .newsItem {
    padding-left: 115px;
    padding-right: 115px;
    margin-bottom: 40px;
    line-height: 170%;

    .title {
      margin-bottom: var(--gutter-small);
      line-height: 150%;
    }

    .date {
      color: var(--text-grayscale70);
    }
  }
}
.content {
  img {
    max-width: 100%;
  }
}
