@import 'frontend/styles/functions';

.container {
  display: flex;
  gap: spacing(1);
  align-items: center;
  background-color: var(--yellow-sunglow-light);
  color: var(--yellow-sunglow-dark);
  padding: spacing(1) spacing(2);
}

.link {
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.closeButton {
  margin-left: auto;
  background: none;
  border: none;
  padding: spacing(0.5);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    display: block;
  }
}
