@import 'frontend/styles/functions';

.autoGeneratedItemsList {
  display: flex;
  flex-direction: row;
  margin-top: spacing(1);
  flex-wrap: wrap;

  .entity {
    margin: 5px;
  }
}

.buttonAddGeneratedTag {
  margin-top: spacing(1);
}
