@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/features/Inbox/inbox-styles';

.container {
  height: $inboxHeight;
  overflow-y: auto;
  text-align: left;
  position: relative;

  @media screen and (max-width: $phone) {
    width: 100%;
    left: 0;
    right: 0;
    height: $inboxMobileHeight;
  }
}

.unlistedChats {
  padding: 10px 20px;
  background-color: var(--blue-ribbon);
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
  border-radius: var(--border-radius-large);
  border: none;
  margin: spacing(2) spacing(2) spacing(2) spacing(0.5);
  box-shadow: var(--box-shadow);
  text-align: center;
  width: calc(100% - 20px);
  box-sizing: border-box;
  font-size: 13px;
  font-family: var(--font-family);

  @include noOutline;

  &:hover {
    background-color: var(--blue-ribbon--hover);
  }
}

.showNewChatsDivider {
  border-top: 1px solid var(--grayscale60);
  margin: 20px 30px;
  opacity: 0.4;
}
