@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.contactBanner {
  padding: spacing(4);
  background: var(--grayscale5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactLink:link {
  text-decoration: underline;
}
