@import 'frontend/styles/functions';

.flowModal {
  position: fixed;
  left: spacing(2);
  right: spacing(1);
  top: 86px;
  bottom: spacing(1);
  width: calc(100vw - 24px);
  z-index: var(--context-menu-z-index);
  padding: 0;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: spacing(3);
  border-bottom: 2px solid var(--grayscale5);
  padding: spacing(2);
  align-items: center;
}

.exitIcon.exitIcon {
  min-width: unset;
  padding: 0 spacing(0.5);

  svg {
    margin: 0;
  }
}
