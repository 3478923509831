.floatingToolbar {
  view-transition-name: floating-toolbar-1730380297;

  position: absolute;
  display: flex;
  height: 36px;
  color: var(--grayscale0);
  background: color-mix(in oklch, var(--grayscale90) 90%, transparent);
  box-shadow: var(--box-shadow-level1);
  border-radius: var(--border-radius-large);
  z-index: 10;
  padding: 2px;

  &:not(.isVisible) {
    opacity: 0;
    pointer-events: none;
  }

  &.isVisible {
    transition: opacity 0.2s;
    opacity: 1;
    pointer-events: auto;
  }
}

::view-transition-old(floating-toolbar-1730380297),
::view-transition-new(floating-toolbar-1730380297) {
  height: 100%;
  overflow: clip;
}
