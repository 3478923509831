.inputField {
  padding: 6px 11px;
  border-radius: 3px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--white);
  font-size: 14px;
  color: var(--grayscale90);
  border: 1px solid var(--grayscale20);
  min-width: 190px;

  &:focus {
    border-color: var(--blue-ribbon);
    outline: none;
  }
  &:hover {
    border-color: var(--blue-ribbon);
  }
}

.itemList {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  .item {
    margin-top: 5px;
  }

  .generateButton {
    height: 25px;
    width: 25px;
    padding: 0;
    margin: 8px 5px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: opacity 0.5s linear;

    &:hover {
      background-color: var(--grayscale20);
    }

    &Disabled {
      cursor: default;
      opacity: 0.2;
    }
  }

  .itemsCount {
    font-size: 14px;
    margin: 10px 5px 0;

    &Number {
      color: var(--blue-ribbon);

      &Warning {
        color: var(--yellow-sunglow);
      }

      &Error {
        color: var(--red-pomegranate);
      }
    }
  }
}

.error {
  background: var(--red-pomegranate);
  color: var(--white);
  padding: 3px;
  border-radius: 3px;
  width: fit-content;
  margin-top: 6px;
}
