@import 'frontend/styles/functions';

.container {
  display: flex;
  max-width: var(--_maxWidth);
  margin-inline: auto;
  flex-direction: column;
  align-items: center;
  gap: spacing(1);

  & .iconWrapper {
    position: relative;
    width: var(--_iconWidth);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    &.icon {
      background-color: var(--_backgroundColor);
      border-radius: var(--border-radius-large);
    }

    & .icon {
      position: absolute;
      inset: 0;
      margin: auto;
      font-size: 40px;
      color: var(--_iconColor);
    }
  }

  &.emptyStateHorizontal {
    flex-direction: row;
    gap: spacing(10.5);

    & .content {
      align-items: flex-start;
      gap: 0;
      max-width: 900px;
      padding: spacing(3.5) 0;
      & .title {
        font-size: var(--font-size-h1);
        margin-bottom: spacing(2);
      }

      & .description {
        font-size: var(--font-size-large);
        color: var(--black);
        line-height: 24px;
      }

      & .actionButtonWrapper {
        margin-top: spacing(4);
      }
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(1);

    & .statusCode {
      font-size: var(--font-size-small);
      color: var(--text-grayscale70);
      font-weight: var(--font-weight-normal);
      margin: 0;
    }

    & .title {
      all: unset;
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-medium);
    }

    & .description {
      all: unset;
      text-align: center;
      font-size: var(--font-size-medium);
      color: var(--grayscale70);
      line-height: 1.45;
    }
  }
}
