@import 'frontend/styles/functions';

.list {
  list-style-type: none;
  margin: spacing(1) 0;
  padding: unset;
}

.showText {
  padding-inline: var(--labels-section_list-padding-inline);
  margin: unset;
  font-size: var(--font-size-small);
  color: var(--grayscale60);
}
