@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.container {
  position: relative;

  &:hover .options {
    display: flex;
  }
}

.pinned {
  position: absolute;
  left: spacing(-3);
  top: spacing(-3);
  cursor: default;
  display: none;
  &True {
    display: initial;
  }
  &Manage {
    cursor: pointer;
  }
}

.content {
  display: grid;
  grid-template-columns: 80px 1fr auto auto auto;
  grid-gap: spacing(3);
  align-items: center;
  position: relative;
}

.title {
  font-size: 20px;
  color: var(--text-grayscale90);
  margin-bottom: spacing(0.5);
  font-weight: var(--font-weight-medium);

  &ProjectName {
    margin-bottom: spacing(1);
  }
}

.description {
  margin-bottom: spacing(1);
}

.botAvatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.languageCode {
  background-color: var(--grayscale5);
  border-radius: var(--border-radius-medium);
  display: inline-block;
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  padding: 3px 9px;
  margin-right: spacing();
}

.agents {
  display: flex;
  align-items: center;

  &Avatar,
  &Extra {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 2px solid var(--white);
    margin-left: -10px;
    object-fit: cover;
  }

  &Extra {
    min-width: 22px;
    width: auto;
    padding: 0 5px 0 4px;
    background-color: var(--grayscale5);
    font-weight: var(--font-weight-medium);
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    font-size: 11px;
  }
}

.statsContainer {
  .stats {
    padding: 0 0 0 spacing(3);
    text-align: center;
    border-left: 1px solid var(--grayscale30);

    &Title {
      font-size: 12px;
      color: var(--text-grayscale70);
      font-weight: var(--font-weight-medium);
    }

    &Amount {
      font-size: 18px;
      color: var(--text-grayscale90);
    }
  }
}

.options {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.options :global(.divider) {
  height: 1px;
  background-color: var(--grayscale15);
  margin: 2px 0;
  padding: 0;
  pointer-events: none;
}
