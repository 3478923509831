@import '../../../../styles/variables';

.chatDeleted {
  grid-column: 3 / 5;
  grid-row: 1 / 3;

  &Splash {
    color: var(--text-grayscale90);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 24px;
      max-width: 500px;
      margin-top: 0;
      text-align: center;
    }
  }

  &List {
    color: var(--text-grayscale70);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    p {
      font-size: 14px;
      margin: 5px 0;
    }
  }
  &Image {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
    position: relative;
  }
  .talkBubble {
    width: 100px;
    height: 100px;
    position: absolute;
    right: -72px;
    top: -40px;
  }
}
