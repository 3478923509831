@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

$padding-vertical: 55px;
$padding-horizontal: 50px;
$padding-vertical-small: 35px;
$padding-horizontal-small: 20px;

.panel {
  position: relative; // to use with Panel.Separator
  padding: $padding-vertical $padding-horizontal;
  box-shadow: var(--box-shadow-card);
  border-radius: var(--border-radius-large);
  background-color: var(--white);
  width: 100%;

  &Centered {
    max-width: 628px;
    margin: 80px auto;
    display: block;
  }
  &Side {
    max-width: 950px;
  }
  &Small {
    padding: spacing(1);
  }
  &Medium {
    padding: spacing(2);
  }
  &Large {
    padding: spacing(6);
  }
  &NoPadding {
    padding: 0;
  }
  &Nested {
    border: 1px solid var(--grayscale5);
    box-shadow: none;
  }

  &Hover {
    cursor: pointer;
    transition: box-shadow 300ms ease;

    &:hover {
      box-shadow: var(--box-shadow-level2);
    }
  }
  @media (max-width: $tablet-wide) {
    padding: $padding-vertical-small $padding-horizontal-small;
    &NoPadding {
      padding: 0;
    }

    &Small {
      padding: spacing(1);
    }
  }
}

/* Static components Panel.LoadingState, Panel.ErrorState, etc... */
.separator {
  padding: spacing(6) 0;

  span {
    width: 100%;
    height: 2px;
    background-color: var(--grayscale5);
    position: absolute;
    left: 0;
  }
}

.loadingState {
  margin: 0 !important;
}

.errorState {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;

  svg {
    color: var(--grayscale60);
    margin-top: 20px;
    width: 50px;
    height: 50px;
  }
}

.horizontalRule {
  border: 1px solid var(--grayscale5);
  margin: $padding-vertical (-$padding-horizontal);

  @media (max-width: $tablet-wide) {
    margin: $padding-vertical-small (-$padding-horizontal-small);
  }
}

.emptyState {
  display: flex;
  align-items: center;

  &Content {
    display: flex;
    flex-direction: column;
  }
}

.icon {
  height: 50px;
  margin-right: spacing(3);
}

.panelHeader {
  & .panelHeaderContainer {
    display: flex;
    flex-direction: row;
  }
  & .headerSeparator {
    padding: spacing(2.5) 0 0;
  }
}
