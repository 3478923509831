@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.proactivtyNavLink {
  padding-left: spacing(3) !important;
  .item {
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--grayscale10);
    }

    &Disabled {
      color: var(--grayscale60);
      text-decoration: line-through;
      text-decoration-color: var(--grayscale90);
      word-break: break-word;
    }
  }

  .dropdownTriggerBtn {
    padding: 0;
    min-width: initial !important;
    background-color: transparent !important;
    border: none !important;
    visibility: hidden;
    line-height: 8px;
  }

  &:hover {
    & .dropdownTriggerBtn {
      visibility: visible;
    }
  }
}
