@import '../../../../../../styles/variables';
@import '../../../../../../styles/functions';

.messageTag {
  border-radius: var(--border-radius-small);
  margin-right: spacing(1);
  border: 1px solid var(--grayscale90);
  color: var(--grayscale90);
  font-size: 11px;
  padding: 1px 4px 1px;
  white-space: nowrap;
}
