@import 'frontend/styles/functions';

.logoWrapper {
  margin-left: spacing(1);
  margin-right: spacing(2);
}

.logo {
  position: relative;
  top: 3px;
  margin-right: 0;
  width: auto;
  height: 32px;
}
