@import 'frontend/styles/functions';

.label {
  display: flex;
  width: max-content;
  max-width: 100%;
  padding: spacing(0.5) spacing(1.5);
  margin-block: spacing(1);
  align-items: center;
  font-size: var(--font-size-small);
  background-color: var(--_label-background-color, var(--grayscale10, lightgray));
  border-radius: var(--border-radius-small);
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.dot {
  min-width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: spacing(0.5);
  background-color: var(--_label-color, var(--grayscale90, red));
}

.square {
  min-width: 6px;
  aspect-ratio: 1;
  border-radius: 0;
  margin-right: spacing(0.5);
  background-color: var(--_label-color, var(--grayscale90, red));
}

.text {
  display: inline-block;
  color: black;
  background-color: var(--_label-background-color, var(--grayscale10, lightgray));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .label:hover & {
    transition: transform;
    transition-duration: 3.5s;
    transition-timing-function: linear;
    transition-delay: 0.6s;
    transform: translateX(calc(-100% + var(--_label-overflow-delta)));
    text-overflow: unset;
    min-width: max-content;
  }
}
