@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrap {
  display: flex;
  flex-direction: row;
}

.avatar {
  height: 50px;
  min-height: 50px;
  width: 50px;
  margin-right: spacing(2);
  background-size: contain;
  background-repeat: no-repeat;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.details {
  min-width: 300px;
  padding: spacing(2) spacing(3);
  margin-right: spacing(3);
  margin-bottom: spacing(3);
}
.item {
  margin-bottom: spacing(1);

  &Content {
    font-size: 17px;
    margin-top: spacing(0.5);
  }
}

.link {
  text-decoration: underline;
  display: inline-block;

  a {
    font-size: 17px;
    margin: 0;

    &:hover {
      color: var(--blue-ribbon);
    }
  }
  &Small {
    p {
      font-size: 12px;
    }
  }
}
