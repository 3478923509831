@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.linkList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  a {
    text-decoration: none;
    color: var(--text-grayscale90);
    margin: spacing(1) 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
