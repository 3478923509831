@import '../../../../styles/variables';

span.sampleOptions {
  position: absolute;
  left: 5px;
  top: 8px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s linear;

  &Mounted {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}

div.dropdown {
  button {
    cursor: pointer;
  }

  .icon {
    background-image: url(../../../../assets/images/sample-option.svg?url);
    width: 15px;
    height: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
  }

  &Content {
    border-radius: 5px;
    top: calc(100% + 1px);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--grayscale5);
  }

  &Item {
    background-color: #fafafa;
    min-width: 110px;
    padding: 7px;

    &:hover {
      background-color: var(--grayscale5);
    }
  }
}
