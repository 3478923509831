@import 'frontend/styles/variables';

.button {
  color: var(--text-grayscale90);
  border: none;
  background: var(--grayscale5);
  padding: 7px 17px 7px;
  margin: 0 10px 10px 0px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;

  &Click {
    background: var(--white);
    border: 1px solid var(--grayscale30);
  }
}
