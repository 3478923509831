@import 'frontend/styles/variables';

.avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--grayscale20);
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;

  &.large {
    width: 40px;
    height: 40px;
    background-size: 20px;
  }
  &.small {
    width: 32px;
    height: 32px;
    background-size: 16px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &.extraSmall {
    width: 18px;
    height: 18px;
  }
}

.imageAvatar {
  background-color: var(--grayscale5);
  border: 1px solid var(--grayscale5);
}

.autoAvatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
