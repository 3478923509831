@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.logo {
  position: relative;
  top: 1px;
  margin-right: 0;
  width: auto;
  height: 32px;

  &Container {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: background-color 150ms ease;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--grayscale5);
    padding: spacing();

    &:hover {
      background-color: var(--grayscale5);
    }
  }

  @media (max-width: $phone) {
    display: none;
  }

  &.admin {
    margin-right: spacing(2);
  }

  &.circle {
    width: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0;
  }
}

.dropdownWrapper button {
  padding: 0;
  margin-right: spacing();
}

.dropdown {
  margin-left: 8px;
  margin-top: 1px;
  z-index: 200 !important;
}

.logoIcon {
  font-size: 26px;
  color: var(--text-grayscale90);

  .dropdownTrigger[aria-expanded='true'] & {
    transform: rotate(180deg);
  }
}
