@import 'frontend/styles/functions';

.buttonFormWrapper {
  display: flex;
  padding-bottom: spacing(2);
  flex-direction: column;
  gap: spacing(1);
}

.fakeButtonsWrapper {
  display: flex;
  padding-block: spacing(3);
  flex-wrap: wrap;
  gap: spacing(2);
}

.extraBottomSpace {
  padding-bottom: 70px; // So that the select dropdown doesn't get cut off
}
