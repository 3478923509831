@import '../../../../../styles/variables';

.selectionContainer {
  .filterButton {
    border-radius: var(--border-radius-small);
    border: 1px solid var(--grayscale20);
    text-decoration: none;
    transition: 0.1s all ease;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.active {
      color: #fff;
      border-color: var(--blue-ribbon);
      background-color: var(--blue-ribbon);
    }
  }

  .uploadButton {
    float: right;
  }
}

.welcomePageImage {
  width: 360px;
  height: 257px;
  background-size: cover;
  background-position: center;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--grayscale5);
  margin: 10px 0 5px;
}
