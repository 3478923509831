@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.dropdownContainer {
  position: absolute;
  border: 1px solid var(--grayscale30);
  top: calc(100% + 8px);
  background: var(--white);
  border-radius: var(--border-radius-medium);
  padding-top: spacing(2);
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 5px 10px 0 var(--shadow-color);
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.15s ease-in;
  font-size: var(--font-size-medium);

  &Open {
    transform: scaleY(1);
    transition: transform 0.15s ease-out;
  }
}
