@import '../../styles/variables';
@import '../../styles/mixins';

.button {
  display: flex;
  align-items: center;

  // button.link stuff
  border: 0;
  background-color: unset;
  margin: 0;
  &:focus {
    @include noOutline;
  }

  .icon {
    opacity: 0;
    height: 12px;
    width: 12px;
    padding: 0 0 0 11px;

    &.showIcon {
      animation: fade-in-out 2s linear;

      @keyframes fade-in-out {
        0% {
          opacity: 1;
        }
        20% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  // reset animation onMouseDown
  &:active {
    .showIcon {
      opacity: 1;
      animation-name: none !important;
    }
  }
}
