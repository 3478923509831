.wrapper {
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.errorWrapper {
  display: contents;
}

.input {
  width: 100%;
  background-color: var(--grayscale5);
  border-radius: var(--border-radius-medium);
  padding: 6px 8px;

  p {
    margin: unset;
  }
}

.placeholder {
  position: absolute;
  top: 6px;
  left: 8px;
  pointer-events: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-code {
  background-color: #f0f2f5;
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.text-highlight {
  margin: 0 5px;
}

.text-link {
  color: var(--blue-ribbon);
  text-decoration: underline;
  cursor: pointer;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-subscript {
  vertical-align: sub;
}

.text-superscript {
  vertical-align: super;
}
