@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: spacing(1);
  background-color: var(--white);
  color: var(--text-grayscale90);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--grayscale0);
  }
  &:active,
  &.active {
    & .textContainer {
      color: var(--blue-ribbon);
    }
  }
}

.active {
  background-color: var(--grayscale0);
}

.textContainer {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-450);
  line-height: 20px;
  color: var(--text-grayscale80);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.header {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
  justify-content: space-between;
  font-weight: var(--font-weight-medium);
  line-height: 18px;
  & .leftContent {
    display: flex;
    .tooltipTitle {
      align-items: center;
      margin-bottom: 0;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-small);
    }
    .title {
      align-items: center;
      margin-bottom: 0;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-small);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      flex: 1;
      max-width: 142px;
    }

    .time {
      font-weight: var(--font-weight-normal);
    }

    .avatar {
      position: relative;
      width: 16px;
      height: 16px;
      flex: initial;
      margin-right: spacing(0.5);

      img {
        width: 8px;
        height: 8px;
      }
    }
  }

  & .rightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: spacing(0.5);
  & .leftContent {
    flex: 1;
  }
  & .rightContent {
    display: flex;
    flex: initial;
    flex-direction: row;
    align-items: center;
  }
}

.searchHighlight {
  background-color: var(--blue-ribbon);
  color: var(--white);
  padding-right: spacing(0.5);
}
