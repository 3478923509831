@import 'frontend/styles/functions';

.eventMessageWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  & .eventMessageRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: spacing(1);
    max-width: 436px;
    & .eventMessageInput,
    & .eventMessageWrapper,
    & .eventMessageTrigger {
      width: 100%;
    }

    & .eventMessageTrigger {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  & .removeIcon {
    margin-bottom: spacing(0.725);
  }
}
