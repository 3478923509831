@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.container {
  margin: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(2);
}
.search {
  display: inline-block;
  input {
    max-width: 300px;
  }
}
.botFilters {
  margin-bottom: spacing(2);

  & > div {
    display: inline-block;
    width: auto;
  }
}
.filters {
  margin-bottom: spacing(2);
  display: flex;

  & > div {
    display: block;
    margin-right: spacing(2);
  }
}

.botListSearch input {
  background-color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  padding-left: 32px;
}
.tableBadge {
  top: 1px;
}

.languageCode {
  background-color: var(--grayscale5);
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px 9px;
  margin-right: 8px;
}

.filterButton {
  background: white;
  border-radius: 0;
  border: 1px solid var(--grayscale20);
  text-decoration: none;
  transition: 0.1s all ease;

  &:last-child {
    border-top-right-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
    border-left: 0;
  }

  &:first-child {
    border-top-left-radius: var(--border-radius-small);
    border-bottom-left-radius: var(--border-radius-small);
    border-right: 0;
  }

  &.active {
    color: var(--text-grayscale90);
    background-color: var(--yellow-sunglow);

    &.default {
      color: var(--text-grayscale90);
      background-color: var(--grayscale5);
    }
  }
}

.button {
  padding: 0;

  div {
    display: block;
    padding: 9px 30px 8px;
    border-radius: 0px;
    color: var(--text-grayscale90);
    margin: 0;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--grayscale20);
    border-right: none;
    text-align: center;

    &:after {
      content: none;
    }
  }

  @include noOutline;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &:first-child div {
    border-radius: 5px 0 0 5px;
  }
  &:last-child div {
    border-right: 1px solid var(--grayscale20);
    border-radius: 0 5px 5px 0;
  }

  input:checked ~ div {
    background-color: var(--blue-ribbon);
    color: var(--white);
  }

  &.hideHourly {
    pointer-events: none;
    opacity: 0.4;
  }
}
