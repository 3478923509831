.container {
  margin-left: auto;
}

.content {
  border-radius: var(--border-radius-large);
}

.editButton {
  visibility: hidden;
  padding: unset;

  [data-label-hover]:hover & {
    visibility: unset;
  }
}
