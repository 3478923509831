.chatPreviewButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 48px;
  & > div {
    border-radius: 48px;
    height: 48px;
    min-width: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.previewWrapper {
  align-self: flex-start;
}
