@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.sample {
  user-select: none;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  cursor: pointer;
  @include noOutline;

  &Selected {
    cursor: move;
  }

  &Wrapper {
    position: relative;
    padding-left: 20px;
  }

  &Container {
    color: var(--text-grayscale90);
    border-radius: 5px;
    position: relative;
    transition:
      background-color 0.2s linear,
      color 0.2s linear;
    margin: 8px 0px;
    padding: 3px 5px;
    border: 1px solid transparent;

    &:hover {
      color: var(--text-grayscale90);
      background-color: var(--white);
      text-decoration: underline;
    }

    &Selected {
      color: var(--text-grayscale90);
      background-color: var(--blue-ribbon--very-light);

      &:hover {
        color: var(--text-grayscale90);
        background-color: var(--blue-ribbon--very-light);
      }
    }

    &Editing {
      color: var(--text-grayscale90);
      background-color: var(--grayscale5);
      transition: none;
      border: none;
      padding: 0;

      &:hover {
        background-color: var(--grayscale5);
      }

      .sample {
        visibility: hidden;
        position: absolute;
        pointer-events: none;
      }
    }
  }
}

:global {
  .editSampleContainer {
    font-size: 14px;
    line-height: 22px;

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }
}
