@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  margin: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: spacing(5);
}

.pagination {
  display: flex;
  justify-content: center;
}
