@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonContainer {
  display: flex;
  margin: spacing(1) spacing(3);
  gap: spacing(1);
}

.DialogueCandidateSection {
  margin-bottom: 0;

  &.DialogueCandidateSectionEmpty {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  & .DialogueCandidateSectionContent {
    flex: 1;
  }
}

.DialogueCandidateEmptyState {
  justify-content: center;
  height: 100%;
}
