@import 'frontend/styles/functions';

.searchFilters {
  display: flex;
  gap: spacing(0.5);
  flex-wrap: wrap;
}

.dropdown {
  &Wrapper {
    margin-top: spacing();
  }

  &Button {
    display: block;
    min-width: 90px;
    padding: 5px spacing();
    border: 1px solid var(--grayscale20);
    border-radius: 7px;

    &:hover,
    &[aria-expanded='true'],
    &.filterSelected {
      background: var(--grayscale10);
      border: 1px solid var(--grayscale15);
    }

    &:active,
    &[aria-expanded='true'],
    &.filterSelected {
      color: var(--blue-ribbon);
    }
  }

  &Overlay {
    max-width: 300px;
    max-height: 500px;
    border-radius: var(--border-radius-large);
    box-shadow: 0 0 8px 0 var(--shadow-color);
    overflow: auto;
  }
}

.icon {
  font-size: 18px;
  margin-right: 4px;
  vertical-align: bottom;
  color: var(--grayscale90);

  :is(.dropdownButton[aria-expanded='true'], .filterSelected) > & {
    color: var(--blue-ribbon);
  }
}
