@import 'frontend/styles/functions';

.feedbackWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(0.25);
  & .feedbackBadge {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-small);
    color: var(--text-grayscale80);
    font-weight: var(--font-weight-450);
    gap: spacing(0.25);
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-small);
    padding: spacing(0.125) spacing(0.25) spacing(0.125) spacing(0.25);
    &.feedbackBadgeExtraRightPadding {
      padding-right: spacing(0.75);
    }
  }
}
