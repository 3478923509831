@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  display: grid;
  grid-template-columns: minmax(250px, 428px) 1fr;
  grid-template-rows: auto auto;
}

// For later use
// .containerSidebar {
//   grid-column: 1;
//   grid-row: 2;
//   border-right: 1px solid var(--grayscale5);
//   padding: spacing(1);
//   min-width: 20px;
// }

.containerEditor {
  grid-column: 2;
  grid-row: 2;
  padding: spacing(4);
}

.seperator {
  width: 100%;
  height: 2px;
  background-color: var(--grayscale5);
  margin: spacing(3) 0;
}

// =====================

.formPanel {
  position: relative;
  padding: spacing(0);

  display: block;

  .title,
  .smallTitle,
  label {
    font-weight: var(--font-weight-medium);
  }

  .title {
    font-size: 20px;
  }

  .smallTitle {
    font-size: 16px;
  }

  label {
    padding-bottom: 4px;
  }
}

.inputContainer {
  width: 50%;

  @media (max-width: $tablet-wide) {
    width: 100%;
  }
}

.helpText {
  display: block;
  margin-top: spacing(2);
  margin-bottom: spacing(1);
}

.toggleContainer {
  display: flex;
  align-items: center;

  > label {
    margin-right: spacing(2);
  }
}

.buttonContainer > div {
  display: flex;
  justify-content: space-between;
}

.carousel {
  p {
    margin-top: 0;
  }

  h3 {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
  }
}
