@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.libraryHeader {
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: spacing(2) spacing(2) spacing(2) spacing(2);
  gap: spacing(1);

  & .languagePickerWrapper {
    display: flex;
    gap: spacing(1);

    & > button {
      margin: 0;
      flex: 1;
    }
  }

  & .libraryHeaderTitleWrapper {
    display: flex;
    align-items: center;
    height: 34px;
    &.libraryHeaderTitleWrapperColumn {
      display: grid;
      grid-template-columns: 40px 1fr;
    }
    & .libraryHeaderTitleLeft {
      display: flex;
      flex: 1;
      margin: 0 spacing(1);

      & .libraryHeaderTitle {
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-medium);
      }
    }

    & .libraryHeaderTitleRight {
      display: flex;
      & .searchBtn {
        margin-left: spacing(1);
        width: 28px;
        height: 28px;
        padding: 4px;
      }
    }
  }
}
