@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.zodiacWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text {
  margin-left: spacing(0.5);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
}
.icon {
  position: relative;
  left: -2px;
}
