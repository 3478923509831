@import 'frontend/styles/variables';

.modal {
  max-width: 800px;
}
.content {
  padding: 0;
  overflow-x: auto;
  font-size: var(--font-size-medium);
}
