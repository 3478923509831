@import '../../../../styles/variables';

$minPanelWidth: 140px;

.miniPanel {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &Tiny {
    width: $minPanelWidth;
    padding: 15px 20px;
    height: 94px;
  }
}

.metricWrapper {
  display: inline-block;
  position: relative;

  &Center {
    text-align: center;
  }
}

.title,
.metric {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.title {
  color: var(--text-grayscale70);
  font-size: 14px;
  line-height: 17px;
}

.metric {
  font-size: 32px;
  display: inline-block;
  position: relative;
}

$arrowSize: 6px;

.arrow {
  position: absolute;
  font-size: 18px;
  top: calc(50% - 8px);
  right: -24px;
  transform: rotate(180deg);

  &Down {
    transform: rotate(0deg);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, $minPanelWidth));
  grid-gap: 30px;

  @media (max-width: $tablet-wide) {
    grid-gap: 15px;
  }
}
