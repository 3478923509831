@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.demoLayout {
  .content {
    padding: 0;
  }

  .sectionTitle {
    font-weight: var(--font-weight-medium);
  }

  h5 {
    font-weight: 600;
  }

  .settingsContainer {
    .demoIntroduction {
      margin-bottom: 40px;
    }

    .flexGroup {
      display: flex;
    }

    .flexLeft,
    .flexRight {
      flex: 1;
    }

    .flexRight {
      img {
        float: right;
        max-width: 190px;
        max-height: 215px;
      }
    }

    .uploadButtons {
      display: flex;

      button {
        margin-right: var(--gutter-small);
      }
    }
  }
}

.url {
  padding: spacing(1);
  border: 2px solid var(--grayscale5);
  border-right: none;
  border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
  background: var(--white);
  height: 34px;

  & + div input {
    border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  }
}
