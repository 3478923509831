@import '../../styles/variables';

.publicLayout {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: var(--white);
  color: var(--text-grayscale90);
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  .centeredComponent {
    &Footer {
      height: unset;
    }
  }

  .headerContainer {
    width: 100%;
    padding: 30px;
    max-width: 1600px;
    margin: auto;
    box-sizing: border-box;

    .headerInner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headerBranding {
        display: flex;
        z-index: 20;
        align-items: center;

        .blobContainer {
          width: 45px;
          height: 40px;
          margin-right: 15px;
        }

        a {
          font-size: 40px;
          text-decoration: none;
        }

        .kindlySpan {
          color: var(--blue-ribbon);
          font-family: KindlySans, sans-serif;
        }
      }
    }
  }
}
