@import 'frontend/styles/functions';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(1);
  gap: spacing(1);
}

.inputTriangle {
  transform: rotate(180deg);
}

.ruleDropdownOverlay {
  overflow: unset; // we need the rule settings menu to overflow the dropdown
}
