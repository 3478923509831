@import './variables';
@import './functions';

.inputGroup {
  display: flex;
}

label {
  display: block;
  padding: spacing(1) 0;
  font-weight: var(--font-weight-medium);
}

.formControl {
  font-size: 14px;
  color: var(--text-grayscale90);
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--white);
  border: 2px solid var(--grayscale5);
  transition: box-shadow ease-out 0.1s;

  &:hover,
  &:focus {
    outline: none;
    border-color: var(--blue-ribbon);
  }

  &Mischka {
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-large);

    &:hover {
      background-color: var(--grayscale10);
      border-color: var(--grayscale10);
    }

    &:focus {
      outline: none;
      border-color: var(--blue-ribbon);
      background-color: var(--white);
    }
  }
}
