@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.dropdownList {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: spacing(1);
  border-top: 1px solid var(--grayscale20);

  &Row {
    display: contents;
    cursor: pointer;

    &:hover > span {
      background: var(--grayscale5);
    }

    > span {
      display: inline-flex;
      border-bottom: 1px solid var(--grayscale20);
      padding: spacing(2) spacing(2) spacing(1);
      @include noOutline;

      &:first-child {
        padding-left: spacing(3);
        border-right: 1px solid var(--grayscale20);
      }

      &:focus {
        background: var(--grayscale5);
      }
    }
  }

  &Expand {
    padding: spacing(2) spacing(3);
    text-align: left;
  }

  .textDescription {
    margin-bottom: spacing(1);
  }
}
