@import 'frontend/styles/functions';

.tooltip {
  background-color: rgba(35, 47, 65, 0.9);
  color: var(--white);
  border-radius: var(--border-radius-medium);
  box-shadow: 0 0 12px var(--shadow-color);
  z-index: 99999;
  padding: spacing(0.5) spacing(0.75) spacing(0.625) spacing(0.75);
  position: fixed;
  top: unset;
  left: unset;
  letter-spacing: 0.06px;
  opacity: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-450);
  display: none;
  pointer-events: none;
}
