@import 'frontend/styles/functions';

.button {
  margin-left: auto;
  color: var(--primary-color);
  flex-shrink: 0;

  > svg {
    margin-right: spacing(1);
    font-size: 18px;
    color: var(--primary-color);
  }
}

.colorText {
  padding-inline: var(--labels-section_list-padding-inline);
  font-size: var(--font-size-small);
  color: var(--grayscale80);
}

.colorsContainer {
  display: flex;
  padding: 0 var(--labels-section_list-padding-inline) var(--labels-section_list-padding-inline);
  gap: spacing(1.5);
  flex-wrap: wrap;
}

.color {
  width: 18px;
  aspect-ratio: 1 / 1;
  background-color: var(--_background);
  border: unset;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
}

.dot {
  display: block;
}
