@import 'frontend/styles/functions';

.iconAndTextWrapper {
  display: flex;
  padding-left: spacing(3);
  overflow: hidden;

  .icon:after {
    top: 4px;
    left: -19px;
  }
}
