@import 'frontend/styles/functions';

.overlaySpan {
  display: block;
  margin-bottom: spacing(1);
  color: var(--text-grayscale70);
  font-weight: var(--font-weight-medium);
}

.languages {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: spacing(2);
}

.overlay {
  position: relative;
  background-color: var(--grayscale5);

  > div {
    padding: spacing(1) spacing(1) 0;
  }
}
