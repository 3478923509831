@import './variables';

%textBold {
  font-weight: 400;
}

p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: normal;
  margin: 0 0 30px;
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: normal;
  line-height: 30px;
  margin: 0 0 15px;
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  margin: 0 0 10px;
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: normal;
  margin: 0 0 10px;
}

h5 {
  font-size: var(--font-size-medium);
  font-weight: normal;
  margin: 0 0 10px;
}
code {
  font-family: var(--font-family-monospace);
}
