@import 'frontend/styles/functions';

.pageBar.pageBar {
  display: flex;
  position: relative;
  min-width: max-content;
  top: 0;
  padding: 0;
}

.copyOutputButton {
  margin-right: spacing(2);
}
