@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.addAdornment {
  font-size: var(--font-size-small);
  color: var(--grayscale70);
  display: flex;
  flex-direction: row;
  align-items: center;
  & .addSvgWrapper {
    padding: spacing(0.5);
    background-color: var(--white);
    border: 1px solid var(--grayscale15);
    border-radius: 4px;
    display: flex;
    margin-right: spacing(0.5);
    & > svg {
      font-size: var(--font-size-small);
    }
  }
}
