@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.content {
  flex: 1;
}

.email {
  padding: spacing(0) spacing(1);
  display: flex;
  align-items: center;
  font-size: 14px;
}

.avatar {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: spacing(1);
}
.mentionOrgInvite {
  margin-top: spacing(1);
  a {
    color: var(--blue-ribbon);
  }
}
