@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.invitationList {
  font-size: 14px;
}

.invitationHeader,
.invitationItem {
  display: grid;
  grid-template-columns: 5fr 3fr 1fr 1fr;
  align-items: center;
}

.sortButton {
  color: var(--black);
  font-weight: var(--font-weight-medium);
  background: none;
  border: none;
  font-size: 14px;
  display: flex;
  padding: 0;
  line-height: 170%;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;

  @include noOutline;
}

.invitationHeader {
  padding: 0 spacing(1);

  *[role='button'] {
    cursor: pointer;
  }

  div {
    margin: 14px 0;

    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.invitationsContent > div {
  margin-bottom: spacing(2);
}

.invitationItem {
  white-space: nowrap;

  .name,
  .roles {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    .avatar {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      object-fit: cover;
      background-color: var(--grayscale5);
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      margin-right: spacing();
    }
  }

  .actions {
    text-align: right;
  }

  .options {
    button {
      width: 36px;
      height: 24px;
      padding: 0;
      cursor: pointer;

      &:hover .optionsButton {
        @include threeDots(var(--blue-ribbon));
      }

      .optionsButton {
        @include threeDots();
      }
    }
  }
}

.invitationBotLink {
  color: var(--blue-ribbon);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
