@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.userList {
  font-size: 14px;
}

.emptyList {
  margin-left: 20px;
}

.userHeader,
.userItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 75px 35px;
  grid-column-gap: spacing(1);
  align-items: center;
}

.sortButton {
  color: var(--black);
  font-weight: var(--font-weight-medium);
  background: none;
  border: none;
  font-size: 14px;
  display: flex;
  padding: 0;
  line-height: 170%;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;

  @include noOutline;

  .sortRoles {
    &:hover {
      text-decoration: underline;
    }
    color: var(--blue-ribbon);
  }
}

.userHeader {
  padding: 0 spacing(1);

  *[role='button'] {
    cursor: pointer;
  }

  div {
    margin: 14px 0;

    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.usersContent > div {
  margin-bottom: spacing(2);
}

.userItem {
  white-space: nowrap;

  .name,
  .bots {
    white-space: normal;
  }

  .name {
    .avatar {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      object-fit: cover;
      background-color: var(--grayscale5);
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      margin-right: spacing();
    }

    a,
    a:visited {
      color: var(--blue-ribbon);
    }
  }

  .actions {
    text-align: right;
  }

  .options {
    button {
      width: 36px;
      height: 24px;
      padding: 0;
      cursor: pointer;

      &:hover .optionsButton {
        @include threeDots(var(--blue-ribbon));
      }

      .optionsButton {
        @include threeDots();
      }
    }
  }
}

.userBotLink {
  color: var(--blue-ribbon);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
