@import 'frontend/styles/functions';

.headingSection {
  display: flex;
  gap: spacing(3);
  padding: unset;
  margin-bottom: spacing(7);

  h3 {
    font-weight: var(--font-weight-medium);
  }

  h3,
  p {
    margin: unset;
  }
}

.radioWrapper {
  display: flex;
  column-gap: spacing(3);
  flex-wrap: wrap;
}

.toggleSwitch {
  padding-block: spacing(1);
  > span {
    flex-shrink: 0;
  }
}

.smallText {
  font-size: 12px;
  display: block;
  margin-top: spacing(1);
  color: var(--text-grayscale70);
}
