@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  padding: spacing(2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--white);
  height: 100%;
  width: 226px;

  & .chatDetailsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & .chatDetailsHeaderLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: var(--font-size-medium);
      color: var(--text-grayscale80);
      font-weight: var(--font-weight-medium);
      & svg {
        margin-right: spacing(0.5);
      }
    }
  }

  & .chatDetailsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media all and (max-width: 1470px) {
    border-left: 2px solid var(--grayscale15);
  }

  & .accordionTitle {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    color: var(--text-grayscale70);
    display: flex;
    flex-direction: row;
    align-items: center;
    & svg {
      margin-right: spacing(0.25);
    }
  }
}

.chatDetailsInfo {
  color: var(--text-grayscale90);
  word-break: break-all;
  display: flex;
  flex-direction: column;

  & .chatDetailsInfoRow {
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight-normal);
    color: var(--text-grayscale70);
    margin-top: spacing(0.5);
    font-size: var(--font-size-small);
  }

  span {
    font-weight: var(--font-weight-medium);
    margin-left: spacing(0.5);
    &:not(:first-child) {
      margin-top: spacing(0.5);
    }
  }

  a {
    color: var(--text-grayscale90);

    &:hover {
      color: var(--blue-ribbon);
    }
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);
  margin: spacing(0.5);
}
