@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.paginationWrapper {
  margin: 10px;
  text-align: center;
}

.actions {
  text-align: right;
}

.orgOrBot {
  display: block;
}

.options {
  button {
    width: 36px;
    height: 24px;
    padding: 0;
    cursor: pointer;

    &:hover .optionsButton {
      @include threeDots(var(--blue-ribbon));
    }

    .optionsButton {
      @include threeDots();
    }
  }
}
