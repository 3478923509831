@import 'frontend/styles/functions';

.bubbleHeader {
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: row;
  align-items: center;
  &.fromUser {
    justify-content: flex-end;
  }
}
