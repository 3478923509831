@import 'frontend/styles/functions';

.wrapper {
  padding: spacing(1.5) spacing(1.5);
  cursor: pointer;

  &:hover,
  &.active {
    background-color: var(--grayscale0);
  }

  &FromUser {
    margin-bottom: spacing(2);
  }

  &.isCarousel {
    padding-right: 0;
  }
}
