@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

button.addToAllowlist {
  background-color: var(--grayscale30);
  padding: 4px;
  border: none;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  display: block;
  position: absolute;
  width: max-content;
  top: 27px;

  &:hover {
    background-color: var(--grayscale40);
  }
}

.attachmentContainer {
  display: flex;
  max-width: 100%;
  max-height: 200px;

  img {
    object-fit: scale-down;
    max-height: 100%;
    max-width: 100%;
    object-position: left;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  @include noOutline;

  .correctedText {
    border-bottom: 2px dotted var(--red-pomegranate);

    &Clickable {
      cursor: pointer;
    }
  }

  .removedText {
    text-decoration: var(--red-pomegranate) dotted line-through;
    margin: 0 5px;
  }

  div {
    top: 30px;
  }

  &:first-child .removedText {
    margin-left: 0;
  }

  &:last-child .removedText {
    margin-right: 0;
  }
}

div.loader {
  display: inline-block;
  position: absolute;
  top: 3px;
  right: -(spacing(3));
}
