@import 'frontend/styles/functions';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(5);
}

.emptyCustomRoles {
  font-size: 14px;
  line-height: 20px;
  max-width: 650px;
}
