@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.logItemWrapper {
  margin: spacing(1) 0;

  .header {
    font-weight: var(--font-weight-medium);
    margin-bottom: spacing(0.5);
  }

  .logItemContent {
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: flex-start;
    background: var(--grayscale5);
    border-radius: var(--border-radius-medium);
    padding: spacing(0.5) spacing(1);

    &SingleLine {
      div {
        white-space: nowrap;
      }
    }

    &Clickable {
      &:hover {
        cursor: pointer;
        background: var(--grayscale10);
      }
    }

    .icon {
      display: flex;
      align-items: center;
      min-height: 32px;
      font-weight: var(--font-weight-medium);
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: spacing(1);
    }

    .timeStamp {
      margin: spacing(1) 0;
    }
  }
}
