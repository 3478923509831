@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.checkMark {
  width: 21px;
  height: 21px;
  border: 2px solid var(--grayscale30);
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  @include noOutline;
}
