@import 'frontend/styles/functions';

.modalContent {
  padding: 20px 20px 0 20px;
}

.container {
  display: flex;
  font-weight: var(--font-weight-medium);
  flex-direction: column;
  align-items: flex-start;
  gap: spacing(2);
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: spacing(1);
  width: 100%;
  padding-right: 30px;
  font-size: var(--font-size-large);
  border-width: 2px;
  overflow: auto;
  scrollbar-width: none;

  svg {
    flex-shrink: 0;
  }
}

.ruleTitle {
  border: 2px solid transparent;
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-large);

  &.invalid {
    border: 2px solid var(--red-pomegranate);
  }

  &:focus {
    border: 2px solid var(--blue-ribbon);
  }
}

.ifText {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
}

.formErrors {
  position: absolute;
  top: auto;
  bottom: 20px;
  left: -10px;
}

.operator {
  &:not(.visible) {
    display: none;
  }

  &.visible {
    display: block;
  }
}

.toggleSwitchContainer {
  border-top: 1px solid var(--grayscale15);
  padding-top: spacing(2);
  text-align: start;

  &:not(.visible) {
    display: none;
  }

  &.visible {
    display: block;
  }
}

.toggleSwitchDescription {
  padding-left: calc(36px + 8px); // width of toggle switch + padding
  line-height: 1.45;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  color: var(--grayscale70);
}
