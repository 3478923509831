@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  display: grid;
  grid-template-columns: 250px minmax(350px, auto);
  grid-gap: spacing(3);

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}

.sideMenu {
  position: sticky;
  top: 153px; // Navbar (103px) + PageContent padding (50px)
  z-index: 1;
}

.number {
  margin-left: auto;
  color: var(--grayscale60);
}

.adminButton {
  all: unset;
  outline: revert;
  cursor: pointer;
}
