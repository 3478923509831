.ghostCircle,
.ghostCircle2 {
  position: relative;
  width: 60px;
  height: 100px;
}
.ghostLines,
.ghostLines2 {
  position: relative;
  width: 100%;
  height: 100px;
}

.ghostContainer,
.ghostContainer2 {
  position: relative;
  overflow: hidden;
  height: 100px;
  border-radius: 8px;
  margin: 0 0 15px 0;
}
.ghostContainer2,
.ghostLines2,
.ghostCircle2 {
  height: 85px;
}
