@import '../../styles/variables';

.badgeWrapLabel {
  margin-left: 6px;
  display: inline-block;
  position: relative;
  top: -2px;
  padding: 2px 5px 3px;
  border-radius: 4px;
  background: var(--grayscale5);
  line-height: 1;
  font-size: 13px;
}

.badgeLabel {
  color: var(--text-grayscale90);
  font-size: 10px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.pushDown {
  margin-top: 14px;
}
