@import '../../styles/variables';
@import '../../styles/mixins';

.footer {
  margin: 10px 0 5px;
  display: inline-block;
}

:global {
  // Tag
  .react-tagsinput {
    &-input {
      width: 100%;
      padding: 6px 11px;
      font-size: 14px;
      border-radius: var(--border-radius-small);
      border: 2px solid var(--grayscale20);
      box-sizing: border-box;
      font-family: var(--font-family);
      padding-top: 9px;
      box-shadow: none;
      transition:
        box-shadow 0.1s linear,
        border-color 0.1s linear;

      &:hover {
        border-color: var(--blue-ribbon);
      }

      &:focus {
        @include noOutline;
        border-color: var(--blue-ribbon);
      }
    }

    &-tag {
      background-color: var(--blue-ribbon);
      border-radius: 13px;
      padding: 5px 11px 3px;
      margin-right: 7px;
      margin-bottom: 7px;
      color: var(--white);
      display: inline-grid;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      grid-gap: 12px;
      position: relative;
      font-size: 14px;
      word-break: break-word;
    }

    &-remove {
      position: relative;
      width: 10px;
      height: 100%;

      &:after {
        content: '';
        background-image: $x-icon-fat;
        position: absolute;
        right: -3px;
        top: calc(50% - 8px);
        cursor: pointer;
        width: 10px;
        height: 100%;
        background-size: 7px;
        background-repeat: no-repeat;
        background-position: 0px 4px;
      }
    }

    &--focused input {
      border-color: var(--blue-ribbon);
    }
  }
}
