@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.container {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: spacing(4);
  align-items: center;
}

.illustrationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: var(--font-weight-medium);
}

.description > * {
  font-size: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
