@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  min-width: 200px;
  white-space: nowrap;
}

.checkbox {
  padding: spacing(0.5) spacing(2);
  border-bottom: 1px solid var(--grayscale5);
}

// Overwrite Radio component values
.radio {
  padding: 14px spacing(2);
  flex: 1;

  > div {
    padding-left: 35px;

    &:after {
      left: 0;
    }
  }
}
