@import '../../styles/variables';
@import '../../styles/mixins';

$iconColors: (
  'default': var(--grayscale60),
  'primary': var(--primary-color),
  'warning': var(--error-color),
  'success': var(--success-color),
  'orange': var(--orange-color),
  'dark': var(--blue-ribbon--active),
  'white': var(--white),
  'black': var(--grayscale90),
  'gray': var(--grayscale60),
  'lightGray': var(--grayscale20),
  'darkGray': var(--grayscale80),
  'text': var(--text-grayscale90),
  'support': var(--yellow-sunglow),
  'kindlyAdmin': var(--pink-heliotrope),
);

.icon {
  font-size: 24px;
}

.isButton {
  cursor: pointer;
  @include noOutline;
}

@each $name, $color in $iconColors {
  .#{$name} {
    color: $color;
  }
  .#{$name}Hover:hover {
    color: $color;
  }
}
