@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.title {
  font-size: 24px;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
}

.description {
  margin-top: spacing(1);
}

.panel {
  padding: spacing(3);
}

.content {
  font-size: 14px;
  margin-bottom: spacing(4);

  ul {
    margin-top: spacing();

    li {
      margin-bottom: spacing(0.5);
    }
  }
}
