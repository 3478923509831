@import '../../../../styles/variables';
@import '../../../../styles/functions';

.skillsMenu {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(7);
}

.inputContainer {
  max-width: 190px;
}

.listHeader {
  padding: 0 spacing(2);

  span {
    color: var(--black);
    font-weight: var(--font-weight-medium);
    margin-bottom: spacing(2);

    &:not(:first-child) {
      padding: 0 spacing(3) - 1px;
    }
  }

  display: grid;
  grid-template-columns: 1fr auto auto;
}

.noResult {
  font-size: 24px;
  font-weight: var(--font-weight-medium);
}
