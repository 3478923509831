@import 'frontend/styles/functions';

.filterOverlayWrapper {
  width: 100%;
  max-width: 480px;
  min-width: 230px;
  & > * {
    padding: 0 spacing(2);
    margin-bottom: spacing(0.25);

    &:first-child {
      margin-top: spacing(1);
    }

    &:last-child {
      margin-bottom: spacing(1);
    }
  }
}

.separatorTitle {
  padding: spacing(2.5) spacing(1.5) spacing(0.5);
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
  font-weight: var(--fontwe-weight-normal);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & .seeAll {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.filtersPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: spacing(0.75);
  background-color: var(--white);
  padding: spacing(1);
  width: 100%;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-card);
}

@mixin filterButton {
  padding: spacing(0.5) spacing(1);
  margin: 0;
  min-width: auto !important;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.trigger {
  @include filterButton;
}

.clearFiltersBtn {
  @include filterButton;
  color: var(--text-grayscale70);
  letter-spacing: 0.5px;
  font-weight: 400;
}

.dropdown {
  overflow-y: auto;
}

.selectedSavedFilter {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid var(--grayscale15);
  border-collapse: collapse;
  border-radius: var(--border-radius-medium);
  max-height: 28px;
  height: 100%;
  & > div {
    padding: 0 spacing(0.75);
    &:last-child {
      font-weight: var(--font-weight-medium);
      border-left: 1px solid var(--grayscale15);
    }
  }
}

.saveButton {
  max-height: 28px;
  padding: 0 spacing(1.5);
  align-items: center;
  align-self: center;
  height: 100%;
}
