@import '../../styles/variables';

.form {
  height: 100%;
  text-align: left;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: var(--white);
  color: var(--text-grayscale90);

  .logo {
    width: 120px;
    position: absolute;
    left: 40px;
    top: 40px;
  }

  .avatar {
    width: 140px;
    height: 140px;
    background-color: var(--white);
    border-radius: 50%;
    border: 20px solid var(--white);
    box-sizing: border-box;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    font-weight: 100;
    font-size: 30px;
    margin-bottom: 40px;

    .skillName {
      font-family: KindlySans, sans-serif;
      color: var(--blue-ribbon);
    }
  }

  label {
    font-size: 16px;
  }

  .submit {
    display: block;
    margin: auto;
  }
}
