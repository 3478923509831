@import '../../styles/variables';
@import '../../styles/functions';

.errorState {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 100px;
    height: 100px;
  }
}

.errorStack {
  .message,
  .stack {
    color: red;
  }
  .message {
    font-size: var(--font-size-h3);
  }

  ol {
    list-style: none;
    white-space: nowrap;
    padding: 0;
  }

  .expandButton {
    margin-bottom: spacing(2);
  }
}
