@import 'frontend/styles/functions';

.labelBox {
  padding-inline: var(--labels-section_list-padding-inline);
  margin-block: spacing(2);
  background-color: var(--grayscale0);
}

.p {
  padding-inline: var(--labels-section_list-padding-inline);
  color: var(--grayscale90);

  &:first-of-type {
    margin-block: unset;
  }

  &:last-of-type {
    margin-bottom: unset;
  }

  & + .p {
    margin-top: spacing(1);
  }
}

.buttonsContainer {
  display: flex;
  margin-block: spacing(2);
  gap: spacing(1.5);
  justify-content: center;
  flex-wrap: wrap;
}
