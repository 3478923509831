.ghostLines {
  position: relative;
  width: 100%;
  height: 60px;
}

.ghostContainer {
  position: relative;
  overflow: hidden;
  height: 60px;
  max-width: 300px;
  margin: 0;
}
