@import 'frontend/styles/functions';

.chatContainerWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 2px solid var(--grayscale15);
  background-color: var(--white);
  & .chatContainerContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    & .chatContainerVerticalToolbar {
      display: flex;
      flex-direction: column;
      padding: spacing(1.125);
      gap: spacing(1.125);
      border-right: 2px solid var(--grayscale15);
      background-color: var(--white);
    }

    & .chatContainerContent {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      background-color: var(--grayscale0);
    }
    & .chatContainerMeta {
      display: flex;
      flex-direction: column;
    }
  }
}
