@import 'frontend/styles/functions';

.tabs {
  display: flex;
  gap: spacing(1);
  flex-wrap: wrap;

  ul {
    margin-bottom: spacing(2);
  }
}

.buildPageWrapper {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
}

.buildContentContainer {
  flex: 1;
  padding: 0;
  margin: 19px 0;
  margin-inline: 18px 8px;
  max-width: 100%;
}
