@import 'frontend/styles/functions';

.updateWrapper {
  margin-bottom: spacing(2);

  p {
    margin: spacing(1) 0;
  }

  span {
    font-weight: var(--font-weight-medium);
  }

  button {
    margin-right: spacing(1);
  }
}

.connectionsContainer {
  display: grid;
  grid-gap: spacing(3);
  grid-template-columns: 1fr 1fr;

  margin-top: spacing(3);
}

.FBPageLink {
  color: revert;
  text-decoration: revert;
}
