@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.textContainer {
  h3,
  span {
    font-weight: var(--font-weight-medium);
  }

  h3,
  h4 {
    margin-bottom: spacing(2);
  }
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin-top: spacing(5);
  justify-content: space-between;
  padding-right: spacing(2);

  h3 {
    margin-bottom: spacing();
    font-weight: var(--font-weight-medium);

    span {
      color: var(--blue-ribbon);
      margin-left: spacing();
    }
  }

  button {
    outline: none;
    background: none;
    border: none;
  }
}

.grid {
  margin: spacing(2) 0;
  display: grid;
}
