@import '../../../../styles/variables';
@import '../../styles';

.grid {
  display: grid;
  grid-gap: $analytics-column-space;
}

.autoFlowColumn {
  grid-auto-flow: column;
  margin-bottom: $analytics-column-space;
}
.autoFlowRow {
  display: block;
  & > * {
    margin-bottom: $analytics-column-space;
  }
}

.autoFlowColumn.columns1 {
  grid-template-columns: 100%;
}
.autoFlowColumn.columns2 {
  grid-template-columns: 1fr 1fr;
}
.autoFlowColumn.columns3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.noMargin {
  margin: 0;
}

@media (max-width: $tablet-wide) {
  .autoFlowColumn.columns2 {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
  .autoFlowColumn.columns3 {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}
