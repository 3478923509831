@import 'frontend/styles/functions';

.messageListElement {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  .message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .goTo {
    flex-shrink: 0;
    margin-left: spacing(1);
    font-size: 1.5em;
  }
  &:hover {
    .message {
      text-decoration: underline;
    }
    .goTo {
      color: var(--blue-ribbon--hover);
    }
  }
}
