@import 'frontend/styles/functions';

.previewCardInput {
  height: 75px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-medium);
  text-align: left;

  > span {
    display: inline-block;
    padding: spacing(1);
  }
}
