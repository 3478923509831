@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

$analytics-dot-size: 10px;
$analytics-column-space: 50px;

:export {
  /* stylelint-disable */
  primaryColor: var(--blue-ribbon);
  secondaryColor: var(--text-grayscale70);
  supportiveColor: var(--yellow-sunglow);
  successColor: var(--green-harlequin);
  /* stylelint-enable */
}

%dot {
  width: $analytics-dot-size;
  height: $analytics-dot-size;
  border-radius: 50%;
}

%primaryDot {
  @extend %dot;
  background-color: var(--blue-ribbon);
}
%secondaryDot {
  @extend %dot;
  background-color: var(--text-grayscale70);
}

.datepickerHeaderGrid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin-bottom: $analytics-column-space;
  grid-gap: 20px;
  align-items: center;

  &WithLanguageCodes {
    grid-template-columns: 2fr 2fr 2fr;
  }

  @media (max-width: $tablet-wide) {
    grid-template-columns: 100%;
  }
}

.hoverBox {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 20px var(--shadow-color);
  }
}

.inboxBridgeBox {
  padding: spacing(4);
}

.buttons {
  text-align: right;
  width: 100%;
  border-top: 1px solid #f1f4f8;
  padding: 10px;
  box-sizing: border-box;

  button:last-child {
    margin-left: 10px;
  }
}

.datepickerWrapper {
  &.hideCompare {
    :global {
      .rdrDateDisplay {
        &:last-of-type {
          padding-top: 25px;
          &:after {
            margin-top: 7px;
            content: 'Date period';
          }
        }
      }
    }
  }

  &.hideDatepickerStaticRange {
    :global {
      .rdrDateRangePickerWrapper {
        .rdrDefinedRangesWrapper {
          width: 100%;
          border-right: none;
        }
      }
    }

    &.contentDisplayVertical {
      :global {
        .rdrDateRangePickerWrapper {
          display: block;
          .rdrStaticRanges {
            display: grid;
            grid-template-columns: 1fr 1fr;
            text-align: center;
          }
          .rdrInputRanges {
            padding: 0;
          }
        }
      }
    }
  }

  :global {
    .select-search-box__search {
      color: rgb(105, 105, 105);
      font-size: 14px;
    }

    .select-search-box__select--display {
      width: 100%;
    }

    .rdrDateDisplay {
      padding-top: 25px;
      position: relative;
      background-color: transparent;

      &:first-of-type {
        padding-top: 25px;

        &:after {
          content: 'Date period';
          position: absolute;
          top: 10px;
          color: var(--text-grayscale70);
        }

        .rdrDateDisplayItemActive {
          border-color: var(--blue-ribbon);
        }
      }
      &:last-of-type {
        &:after {
          margin-top: 7px;
          content: 'Compare with';
          position: absolute;
          top: 0px;
          color: var(--text-grayscale70);
        }

        .rdrDateDisplayItemActive {
          border-color: var(--text-grayscale70);
        }
      }
    }

    .rdrDateDisplayItem {
      border: 1px solid var(--border-color-base);

      input {
        @include noOutline;
        font-weight: 500;
        color: var(--text-grayscale70);
      }
    }
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
      color: var(--text-grayscale90);
    }
    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      border-radius: 0 !important;
    }
    .rdrMonth .rdrDayNumber {
      font-weight: 500;
    }
  }
}

.label {
  color: rgb(105, 105, 105);
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
  display: block;
  letter-spacing: 0px;
}

.disableEvents {
  pointer-events: none;
}
