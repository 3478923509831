@import '../../../../../../styles/variables';

.score {
  border-radius: var(--border-radius-small);
  margin-right: 8px;
  background-color: var(--blue-ribbon);
  color: #fff;
  font-size: 12px;
  padding: 2px 6px 2px;
  border: none;
  height: 16px;

  &:hover {
    background: var(--blue-ribbon--hover);
    cursor: pointer;
  }
}
