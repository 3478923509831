@import 'frontend/styles/functions';

.btnContainer {
  display: flex;

  p {
    color: var(--text-grayscale70);
  }
}

.separator {
  background-color: var(--grayscale20);
  border: 0;
  height: 1px;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(2);
}

.infoButton {
  background-color: var(--grayscale10);
  margin-left: spacing();

  &:hover {
    background-color: var(--grayscale15);
  }
}

.contextAccordionBody {
  margin-top: 0;
}

.addContextButton {
  margin-block: spacing(1) spacing(3);
}

.contextGrid {
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  gap: spacing(1);
  margin-bottom: spacing(1);

  .keyLabel,
  .valueLabel {
    font-weight: var(--font-weight-medium);
  }

  .keyLabel {
    grid-column: 1 / 2;
  }

  .valueLabel {
    grid-column: 2 / 4;
  }

  .deleteContextButton {
    all: unset;
    outline: revert;

    padding: spacing(0.5);
    cursor: pointer;
  }
}

.urlTriggerSelection {
  max-width: 150px;
}
