@import '../../styles/variables';

.header {
  font-weight: 300;
  margin-bottom: 0;
}

.forgotPassword {
  width: 360px;
}
.paragraph {
  font-size: 16px;
  margin-bottom: 24px;
}

.backButton {
  padding-left: 0;
  color: var(--blue-ribbon);
}

.submitButton,
.backButton {
  font-size: 16px;
}

.mailWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 60px;

  svg {
    margin-top: 20px;
  }
}
