@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:first-of-type {
    padding-top: 2px;
  }
  &:last-of-type {
    padding-bottom: 2px;
  }

  &:hover {
    background-color: var(--grayscale0);
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    color: var(--grayscale80);
  }
}
.typeTitle {
  padding-left: 6px;
}

.checkbox {
  margin: 0;
  padding: 0;
}

.circle {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: var(--_circle-background-color);
}

.labelInputContainer {
  padding: spacing(1.5);
}

.labelInput {
  input {
    padding-left: spacing(4);
    font-size: var(--font-size-medium);
    background-color: var(--grayscale0);
    border: 2px solid var(--grayscale5);
    border-radius: 72px;

    &:hover {
      background-color: var(--grayscale10);
      border-color: var(--grayscale10);
    }

    &:focus {
      outline: none;
      border-color: var(--primary-color);
      background-color: var(--white);
    }
  }

  svg {
    left: 12px;
  }
}
.labelListWrap {
  ul {
    background: var(--white);
    margin: 0;
    padding: 0;
    min-width: 200px;

    li {
      padding: 6px spacing();

      &:last-of-type {
        padding-bottom: spacing();
      }

      label {
        margin: 0;
        padding: 4px spacing();
      }
    }
  }
  p {
    margin: spacing(0.5) spacing(1.5);
  }
}

.labelsSection {
  min-width: 250px;
}
