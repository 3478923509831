@import '../../styles/variables';
@import '../../styles/mixins';

.button {
  width: 30px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
  display: block;
  background: unset;
  border: unset;

  &:hover {
    background-color: var(--grayscale20);
    border-radius: 50%;
  }

  &:focus {
    @include noOutline;
  }

  $dot: 3px;

  .inner {
    position: relative;
    width: $dot;
    height: 100%;
    margin: auto;
  }

  span,
  span:after,
  span:before {
    content: '';
    position: absolute;
    width: $dot;
    height: $dot;
    background-color: var(--grayscale90);
    border-radius: 50%;
    left: 0;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  span:before {
    top: -7px;
  }
  span:after {
    top: 7px;
  }
}
