@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: spacing(2);
  margin-bottom: spacing(2);
  align-items: center;
}

.actionInfo {
  display: flex;
  align-items: center;

  .avatar {
    margin-right: spacing(2);
  }

  span {
    font-weight: var(--font-weight-medium);
    font-size: 14px;
  }

  p {
    font-size: 14px;
    margin: 4px 0 0 0;
    color: var(--grayscale70);
  }
}
