@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  margin: spacing(4) 0 0;
  display: flex;

  .chatPreview {
    margin: 0;
    height: 645px;
    width: 360px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 45px var(--shadow-color-dark);
    box-sizing: border-box;
    padding-bottom: 5px;

    .headerContent {
      height: 100%;
      max-height: 250px;
      box-sizing: border-box;
    }

    .contentWrapper {
      padding: 15px 30px 30px;
      display: flex;
      justify-content: space-between;
      height: 100%;
      flex-direction: column;
      box-sizing: border-box;
      border-top: 5px solid black;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        .languagePicker {
          box-sizing: border-box;
          width: fit-content;
          padding: 2px 30px 2px 11px;
          border: 1px solid var(--grayscale30);
          border-radius: 5px;
          display: flex;
          align-items: center;

          .languageIcon {
            font-size: 28px;
          }

          p {
            margin: 0 50px 0 10px;
            font-size: 16px;
            line-height: 16px;
          }
        }

        .text {
          padding: 20px 0;
          height: 100%;
          box-sizing: border-box;

          h4 {
            margin: 30px 0 10px;
            box-sizing: border-box;
            max-height: 180px;
            overflow: hidden;

            div > * {
              font-size: 16px;
              line-height: 24px;
              color: #2c2c2c;
              margin: 16px 0;

              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          h2 {
            color: #2c2c2c;
            font-size: 22px;
            line-height: 30px;
            margin: 0 0 15px;
            font-weight: 500;
            box-sizing: border-box;
          }
        }

        .button {
          border-radius: 100px;
          font-weight: normal;
          border: 1px solid var(--grayscale20);
          max-width: 190px;
          max-height: 42px;
          padding: 11px 20px;
          font-size: 14px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
}
