@import 'frontend/styles/functions';

.option {
  border: 0;
  width: 100%;
  background: transparent;
  text-align: left;
  cursor: pointer;
  padding: spacing(1);
}
.updatedSamplesList {
  display: flex;
  flex-direction: column;

  .updatedSample {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;

    label {
      margin: 5px 8px;
      display: block;
      padding: 0;
    }

    > span {
      line-height: 24px;
    }

    .checkbox label {
      padding: 0 0 3px 30px;
    }
  }
}
