.itemList {
  overflow: visible;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;

  .itemTag {
    margin: 0 10px 8px 0;
  }

  .filler {
    flex-grow: 1;
  }

  .ellipsis {
    font-size: 14px;
    font-weight: 900;
    position: relative;
    top: 6px;
  }
}
