@import 'frontend/styles/forms';

.inputWrapper {
  position: relative;
  line-height: normal;
}

.errorMessage {
  padding: 5px 8px 2px 8px;
  font-size: 13px;
  position: absolute;
  color: #fff;
  text-align: center;
  width: auto;
  display: inline-block;
  background-color: var(--red-pomegranate);
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 2px;
  white-space: nowrap;

  &.errorTop {
    bottom: calc(100% + 6px);
  }

  &.errorBottom {
    top: calc(100% + 6px);
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    border: 4px solid transparent;
    pointer-events: none;
  }

  &.errorTop::before {
    top: 100%;
    border-top: 4px solid var(--red-pomegranate);
  }

  &.errorBottom::before {
    bottom: 100%;
    border-bottom: 4px solid var(--red-pomegranate);
  }
}
