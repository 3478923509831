@import '../../styles/variables';

.loader {
  opacity: 0;
  animation-name: show;
  animation-duration: 500ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;

  svg {
    width: 17px;
    height: 17px;
  }

  &Small {
    display: inline;
    margin-left: 4px;
    svg {
      margin-bottom: -1px;
    }
  }

  &Medium {
    display: inline;
    text-align: center;
    svg {
      width: 50px;
      height: 50px;
    }
  }

  &Large {
    margin-top: 200px;
    margin-bottom: 200px;
    text-align: center;
    svg {
      width: 70px;
      height: 70px;
    }
  }
  &NoMargin {
    margin: 0;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
