@import '../../../../../../styles/variables';

.reply {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  .text {
    text-decoration: none;
    color: var(--text-grayscale90);

    &:hover {
      text-decoration: underline;
    }
  }

  &Disabled {
    opacity: 0.5;

    .text {
      text-decoration: line-through;

      &:hover {
        text-decoration: line-through;
      }
    }
  }
}
