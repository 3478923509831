@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/mixins';

// Both navbar and subnavbar
.navbarWrapper {
  position: sticky;
  top: 0;
  z-index: calc(var(--navbar-z-index) + 1);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  // Overscroll color to match navigation color
  &::before {
    position: absolute;
    width: 100%;
    height: 600px;
    background-color: var(--white);
    content: '';
    bottom: 0;
    z-index: -1;
  }
}

.navbar {
  width: 100%;
  height: 67px;
  position: relative;
  z-index: calc(var(--navbar-z-index) + 2);
  display: grid;
  grid-template-columns: 258px auto 258px; // Specified size to have true center feel of the menu items
  align-items: center;
  background: var(--white);
  box-sizing: border-box;
  padding: 0 spacing(3) 0 spacing(2);

  .menu {
    height: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .menuItem {
      cursor: pointer;
      vertical-align: top;
      display: inline-block;
      height: 100%;
      color: var(--text-grayscale90);
      font-size: 12px;
      text-decoration: none;
      line-height: 48px;
      position: relative;

      .avatar {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        object-fit: cover;
        background-color: var(--grayscale5);
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
      }

      .name {
        margin-right: 7px;
      }
    }
  }

  .dropdownSettings {
    background: transparent;
    border-radius: var(--border-radius-medium);
  }

  .infoDropdown > button {
    padding-left: 0;

    span {
      cursor: pointer;
    }
  }
}

.giftIcon {
  color: var(--grayscale70);
}

.infoIcon {
  color: var(--grayscale70);
  border-radius: var(--border-radius-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:hover {
    background-color: var(--grayscale5);
  }
}

.navigation {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  overflow-x: auto;
}

.botName {
  color: var(--text-grayscale90);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;

  @media (min-width: $tablet-wide) and (max-width: $desktop-small), (max-width: $phone) {
    max-width: 100px;
  }
}

.changelog {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  @include noOutline;

  &:hover {
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-medium);
  }

  div[class='Canny_Badge'] {
    top: 7px;
    right: 4px;
    padding: 5px;
    background-color: var(--red-pomegranate);
    border: 0;
  }
}

.userProfileDropdown {
  margin-left: spacing(2);
  .avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
}
