@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.sortSamples {
  margin-top: spacing();
  margin-bottom: spacing(2);
  color: var(--text-grayscale70);
  user-select: none;
  z-index: var(--modal-z-index);
}

.sortButton {
  padding-inline: spacing(1);
  border: none;
  color: var(--text-grayscale70);
  text-decoration: underline;
  background: inherit;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    @include noOutline;
  }

  &:first-child {
    margin-left: spacing(1);
  }

  &Active {
    color: var(--text-grayscale90);
    background-color: var(--grayscale20);
    border-radius: var(--border-radius-large);
    text-decoration: none;
  }
}
