@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.downloadButton {
  cursor: pointer;
  position: relative;
  @include noOutline;
}

.attachmentIconContainer {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  background: var(--grayscale5);
  align-items: center;
  border-radius: 18px;

  svg {
    height: 35px;
  }

  &File {
    height: 30px;
    width: 220px;

    svg {
      height: 18px;
    }
  }
}

.attachmentImageWrapper {
  position: relative;
  margin-bottom: spacing(1);
  overflow: hidden;
  display: flex;
  width: 100px;
  height: 100px;

  &:hover button {
    display: block;
  }

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    border-radius: 18px;
  }

  button {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0.65;
    color: var(--grayscale90);
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    max-height: 100%;
    max-width: 100%;
    border-radius: 18px;
  }
}

// Gradient inspired by https://css-tricks.com/easing-linear-gradients/
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1.3s ease-out;
  opacity: 1;
  max-height: 250px;
  max-width: 75%;
  border-radius: 18px;

  &Finished {
    opacity: 0;
  }

  &FullWidth {
    max-width: 100%;
  }

  div:first-child {
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.738) 9.5%,
      rgba(255, 255, 255, 0.541) 17%,
      rgba(255, 255, 255, 0.382) 23.5%,
      rgba(255, 255, 255, 0.278) 28.3%,
      rgba(255, 255, 255, 0.194) 32.5%,
      rgba(255, 255, 255, 0.126) 36.5%,
      rgba(255, 255, 255, 0.075) 40.1%,
      rgba(255, 255, 255, 0.042) 43.1%,
      rgba(255, 255, 255, 0.021) 45.5%,
      rgba(255, 255, 255, 0.008) 46.3%,
      rgba(255, 255, 255, 0.002) 49.3%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.002) 50.7%,
      rgba(255, 255, 255, 0.008) 53.7%,
      rgba(255, 255, 255, 0.021) 54.5%,
      rgba(255, 255, 255, 0.042) 59.9%,
      rgba(255, 255, 255, 0.075) 59.9%,
      rgba(255, 255, 255, 0.126) 63.5%,
      rgba(255, 255, 255, 0.194) 67.5%,
      rgba(255, 255, 255, 0.278) 71.7%,
      rgba(255, 255, 255, 0.382) 76.5%,
      rgba(255, 255, 255, 0.541) 83%,
      rgba(255, 255, 255, 0.738) 90.5%,
      rgba(255, 255, 255, 1) 100%
    );
    background-size: 200% 100%;
    animation-name: loadingAnimation;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-delay: 0ms;
    animation-timing-function: linear;
    opacity: 0.5;
  }

  div:nth-child(2) {
    height: 100%;
    width: 100%;
    position: absolute;
    background: white;
    opacity: 0.6;
  }

  div:nth-child(3) {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-medium);
    z-index: 1;
  }
}
