@import 'frontend/styles/functions';

.dialoguesSearch {
  margin-bottom: spacing(1);
  & .dialoguesSearchInput {
    margin-top: spacing(1);
  }
}

.dialogueLabel {
  max-width: 480px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
