@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.navigationContainer {
  display: flex;
  flex-direction: column;
  & .botImprovementsRightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .navigationElContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    & .navigationCardCanDrop {
      background-color: var(--grayscale10);
      color: var(--blue-ribbon);
    }
  }
}
