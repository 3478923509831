@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

%shared {
  padding: 3px 10px 4px;
  font-size: var(--font-size-medium);
  position: relative;
  left: -10px;
  width: 100%;
  box-sizing: border-box;
}

.inputField {
  background-color: var(--white);
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color-base);
  @extend %shared;

  &:focus {
    @include noOutline;
    border-color: var(--blue-ribbon);
  }
}

.field {
  display: flex;
  .fieldName {
    margin: auto spacing(1) auto 0;
  }
  button {
    display: flex;
    align-items: center;
    svg {
      margin-right: 3px;
    }
  }
}

.textField {
  @extend %shared;
  border: 1px solid transparent;
  text-align: left;
  background: none;
  padding-right: 1px;
  white-space: nowrap;
  overflow: hidden;
  font-size: small;

  @include noOutline;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &Empty {
    color: var(--blue-ribbon);
    font-weight: var(--font-weight-medium);
  }
}

.verified {
  color: var(--blue-ribbon);
}

.unverified {
  color: var(--red-pomegranate);
}
