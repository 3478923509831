@import 'frontend/styles/functions';

.preview {
  position: absolute;
  left: spacing(2);
  background-color: var(--white);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: spacing();
  top: calc(100% + 8px);
  border-radius: var(--border-radius-large);
  min-width: 300px;
}
