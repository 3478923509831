@import '../../../styles/variables';

.detailed {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 12px;

  strong,
  h4 {
    color: var(--text-grayscale90);
  }
}

.collapsed {
  display: none;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 8px;
  }
}

.responseDetails {
  grid-column: 1 / 7;
  padding: 25px 0 25px 50px;
}

.details {
  grid-column: 7 / 13;
  padding: 25px 0;

  a,
  a:link {
    color: var(--blue-ribbon--light);
  }
}

.headersAndData {
  grid-column: 1 / 13;
  grid-row: 2;
  border-top: 1px solid var(--border-color-base);
}

.headersAndDataSection {
  padding: 25px 50px;
  border-bottom: 1px solid var(--border-color-base);
}
