@import 'frontend/styles/functions';

%errorMessage {
  display: block;
  right: spacing(1);
  top: calc(-100% + 10px);
  left: auto;
}

.input {
  &Dropdown {
    margin: spacing(0.3);
  }

  &Recipients {
    display: flex;
    align-items: flex-end;
    gap: spacing(0.3);
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
    width: 100%;
  }

  &EmailsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  // Since there are no border color changes do not hide the error message
  &ErrorMessage,
  &Wrapper div {
    @extend %errorMessage;
  }

  input {
    background-color: transparent;
    border: none;
    padding: spacing(1.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
}

.toWrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grayscale5);
}
.to {
  padding-left: 3px;
}
.ccWrap {
  display: flex;
}
.recipientLabel {
  padding: spacing(1.5) spacing(1) spacing(1.5) spacing(1.5);
  font-weight: normal;
  color: #757575;
}

.tagsInput {
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  font-size: var(--font-size-medium);
}

.tag {
  display: flex;
  gap: spacing(1);
  background-color: var(--grayscale5);
  padding: spacing(0.8) spacing(1.6);
  border-radius: 20px;
  white-space: nowrap;

  &:hover {
    padding-right: spacing(0.5);
    .closeButton {
      display: flex;
    }
  }
}

.attachments {
  width: 100%;
  display: flex;
  gap: spacing(2);
}

.attachmentWrapper {
  position: relative;

  img {
    height: 43px;
    width: auto;
    border-radius: var(--border-radius-medium);
  }

  &:hover {
    .closeButton {
      display: flex;
    }
  }
}

.closeButton {
  background-color: var(--grayscale80);
  border-radius: 50%;
  padding: spacing(0.3);
  width: 20px;
  height: auto;

  cursor: pointer;
  outline: none;
  display: none;

  &Attachment {
    position: absolute;
    right: -10px;
    top: -10px;
  }

  &EmailTag {
    top: 1px;
    right: 2px;
    height: 16px;
    width: 16px;
  }

  svg {
    width: 100%;
    height: 100%;
    outline: none;

    path {
      stroke-width: 4;
    }
  }
}

.editor {
  flex-direction: column;
  gap: spacing(2);
  padding-right: spacing(0.5);
  outline: none;
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;

  &Expanded {
    border-radius: 0;
  }

  > * {
    background-color: transparent;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }

  :global(.DraftEditor-root) {
    width: 100%;
    padding: 0;
    line-height: 145%;
    font-size: 14px;
  }

  :global(.DraftEditor-editorContainer) {
    max-height: 116px;
    overflow-y: scroll;
  }

  :global(.public-DraftStyleDefault-pre) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    background: rgba(0, 0, 0, 0.05);
    color: #222;
    border-radius: var(--border-radius-small);
    margin: 0;

    pre {
      margin: 0;
    }
  }

  :global(.public-DraftEditorPlaceholder-root) {
    color: #757575;
  }

  :global(.public-DraftEditorPlaceholder-hasFocus) {
    color: #757575;
  }

  &PlaceholderHidden {
    :global(.public-DraftEditorPlaceholder-root) {
      display: none;
    }
  }
}

.iconGroup {
  display: flex;
  align-items: center;
  padding: spacing(0.8);
  gap: spacing(0.7);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  outline: none;
  padding: spacing(0.8) spacing(0.6);
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);

  button {
    display: flex;
    padding: spacing(0.8) spacing(0.6);
  }

  &Active,
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.emojiWrapper {
  position: relative;
}

:global(.emoji-picker-react) {
  position: absolute !important;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.divider {
  border-left: 1px solid var(--grayscale5);
  height: 34px;
}

.optionsWrapper {
  height: 0;
  top: -4px;
  right: -10px;
}

.option {
  min-width: 100px;
  font-weight: var(--font-weight-medium);
  background-color: var(--grayscale5);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.AIButton {
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

.AIIcon {
  .AIButton:not(:disabled) & {
    fill: gold;
  }
}

.undoText {
  white-space: nowrap;
}
