@import 'frontend/styles/functions';

.editableText {
  background-color: transparent;
  color: var(--text-grayscale90);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  border-radius: var(--border-radius-large);
  padding: spacing(0.75) spacing(1.5);
  border: 1px solid var(--grayscale15);
  max-width: 180px;
  line-height: 20px;
  &:active,
  &:focus {
    background-color: var(--white);
  }
  &:not(:active):not(:focus) {
    &:hover {
      color: var(--blue-ribbon--hover);
    }
  }

  &:hover {
    &::placeholder {
      color: var(--blue-ribbon--hover);
    }
    cursor: pointer;
  }
}

.editableTextTooltipBody {
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-medium) !important;
  padding: spacing(0.5) spacing(0.75) spacing(0.625) spacing(0.75) !important;
}

.inputEnter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(0.5);
  & .inputEnterIcon,
  & .inputEnterAction {
    padding: spacing(0.5);
    border-radius: var(--border-radius-small);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.editableError {
  border: 1px solid var(--error-color);
}
