@import 'frontend/styles/functions';

.chatSystemMessage {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
  font-weight: var(--font-weight-normal);
  line-height: 18px;
  position: relative;
  & .chatSystemMessageTimestamp {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: spacing(1.5);
  }
}
