.ghostCircle {
  position: relative;
  width: 60px;
  height: 100px;
}
.ghostLines {
  position: relative;
  width: 100%;
  height: 100px;
}

.ghostContainer {
  position: relative;
  overflow: hidden;
  height: 100px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 0 15px 0;
}
