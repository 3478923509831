@import 'frontend/styles/variables';
@import 'frontend/styles/typography';
@import 'frontend/styles/functions';

.container {
  h3 {
    margin-top: spacing(5);
    font-weight: var(--font-weight-medium);
  }

  .showMore {
    padding: 2px;
    color: var(--blue-ribbon);
    text-decoration: underline;
  }

  .listContainer {
    .panel {
      padding: spacing(1) spacing(2);
      margin-bottom: spacing(2);

      a {
        text-decoration: none;
      }
    }

    .noFallbackText {
      font-size: 14px;
      font-weight: var(--font-weight-medium);
    }

    .messageContainer {
      display: grid;
      grid-template-columns: 24px 1fr auto;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: spacing(2);

      .avatar {
        height: 24px;
        width: 24px;
        object-fit: cover;
        border-radius: 50%;

        img {
          height: 14px;
        }
      }

      .messageText {
        font-size: 14px;
        color: var(--text-grayscale90);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .date {
        font-size: 14px;
        font-weight: var(--font-weight-medium);
        color: var(--text-grayscale70);
      }
    }
  }
}
