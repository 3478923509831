@import 'frontend/styles/functions';

.bubble {
  display: block;
  max-width: 240px;
  width: fit-content;
  min-height: 18px;
  // Note: if you change the padding here, update the message reference ::before
  padding: spacing(1) spacing(1.5);
  font-size: var(--font-size-medium);
  line-height: 18px;
  text-align: start;
  border-radius: var(--border-radius-16);
  word-break: break-word; // extra-long words in the bubble should break on a new line as last resort

  &:last-child:not(.hasImage) {
    position: relative;
  }
  &:first-of-type:not(:only-of-type) {
    border-bottom-left-radius: 0px;
  }
  &:last-of-type:not(:only-of-type) {
    border-top-left-radius: 0px;
  }
  &:not(:first-of-type):not(:last-of-type) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &FromBot {
    color: var(--text-grayscale90);
    background-color: var(--grayscale15);

    &::after {
      left: -2px;
      transform: rotate(-16deg);
      border-right: 17px solid var(--grayscale10);
      border-left: 0px solid transparent;
    }
  }

  &FromUser {
    color: white;
    background-color: var(--blue-ribbon);
    align-self: flex-end;

    &::after {
      right: -2px;
      transform: rotate(16deg);
      border-left: 17px solid var(--blue-ribbon);
      border-right: 0px solid transparent;
    }
  }

  &FromAgent {
    color: var(--white);
    background-color: var(--grayscale70);
  }
}

%sharedButton {
  position: relative;
  max-width: 240px;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 8px 12px;
  line-height: 18px;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 1px 4px 0px #ccc;
  background-color: var(--white);
  border: none;
  color: var(--text-grayscale90);
  text-align: center;
}

.button {
  @extend %sharedButton;
}

.checkboxButton {
  @extend %sharedButton;
  padding: 8px 12px 8px 39px;
}

.checkIcon {
  position: absolute;
  left: 8px;
  top: 6px;
}

.chatBubbleSliderWrapper {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  padding: 12px 12px 16px;
  border-radius: var(--border-radius-medium);
  margin: 6px 0;
  box-shadow: var(--box-shadow-card);

  & input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    &::-webkit-slider-thumb {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      -webkit-appearance: none;
      margin-top: -6px;
      border: 1px solid var(--white);
      background: var(--grayscale30);
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      background: var(--grayscale30);
      border-radius: 25px;
    }
  }

  & .chatBubbleSliderAffixWrapper {
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    margin-bottom: 28px;

    & .chatBubbleSliderValue {
      font-size: 14px;
      padding: 4px;
    }
    & .chatBubbleSliderAffixValue {
      padding: 0 8px;
      display: flex;
      font-size: var(--font-size-medium);
      flex-direction: row;
      align-items: center;
    }
  }
}
