@import 'frontend/styles/variables';

.infoIcon {
  background: var(--blue-ribbon);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-left: 8px;
  padding-top: 1px;
  color: var(--white);
  font-size: 14px;
  box-sizing: border-box;
}
