@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.radio {
  display: flex;
  width: 100%;
  align-items: center;

  &.inline {
    display: inline-flex;
    width: unset;
  }
}

.radioInput {
  opacity: 0;
  position: absolute;
  left: -999999px;

  &[type='radio']:checked + .radioLabel:after {
    border: 5px solid var(--blue-ribbon);
  }

  &:focus + .radioLabel {
    // Custom styles to replic the default ouline
    @include noOutline {
      border: none;
      box-shadow: none;
    }
    border: 1px solid #60a0ff;
    box-shadow: 0px 0px 1px 2px #60a0ff;
    border-radius: 1px;
  }
}

.radioLabel {
  padding-left: 23px;
  cursor: pointer;
  position: relative;
  font-weight: var(--font-weight-medium);

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid var(--grayscale20);
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }
}
