@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .resolve {
    &:first-child {
      margin-right: spacing(1);
    }
    font-weight: var(--font-weight-medium);

    > div,
    &:not(:hover) {
      background-color: var(--grayscale5);
      border-color: var(--grayscale5);
    }
  }

  button {
    font-size: 14px;
    color: var(--text-grayscale90);
  }

  .goToIcon {
    align-self: center;
  }

  .ignore {
    font-weight: var(--font-weight-medium);
    margin: 0;

    &:not(:hover) {
      background-color: var(--grayscale5);
      border-color: var(--grayscale5);
    }
  }

  .icon {
    margin: 0;
  }
}
