@import 'frontend/styles/functions';

.formInsightsWrapper {
  border-radius: 6px;
  border: 1px solid var(--grayscale15);
  min-width: 280px;
  .formInsightsContainer {
    border-radius: var(--border-radius-medium);
    background-color: var(--white);
    padding: 12px;

    & .fileInputPreview {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: spacing(3);
      font-size: var(--font-size-small);
      border: 1px dashed var(--blue-ribbon);
      border-radius: var(--border-radius-medium);
      font-weight: var(--font-weight-medium);
      & .fileUploadText {
        color: var(--blue-ribbon);
        text-decoration: underline;
      }
    }
    & .fileInputPreviewInput {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    & .formInsightsCheckbox {
      & .insightsCheckboxWrapper {
        display: flex;
        flex-direction: row;
        padding: 0;
        padding-bottom: spacing(1);

        & .insightsCheckboxLabel {
          margin-left: spacing(1);
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-normal);
        }
      }
      & .formInsightsInputLabelWrapper {
        padding: spacing(1) 0;
      }
    }

    & .formInsightsTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4px;
      color: var(--text-grayscale90);
      font-weight: var(--font-weight-medium);
      & > svg {
        margin-right: 4px;
      }
    }

    & .formInsightsContent {
      & .formInsightsInputContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        & .formInsightsMaxLength {
          position: absolute;
          bottom: 10px;
          right: 8px;
          font-size: var(--font-size-small);
          color: var(--grayscale40);
        }
        & .formInsightsInput {
          & > input,
          & > textarea {
            border-radius: var(--border-radius-16);
            background-color: var(--grayscale5);
          }
        }

        & .formInsightsInputLabelWrapper {
          display: flex;
          flex-direction: row;
          & .formInsightsInputRequired {
            color: var(--grayscale60);
            margin-left: 4px;
          }
        }
      }

      & .formInsightsHelperText {
        margin-top: 4px;
        color: var(--grayscale70);
        font-size: var(--font-size-small);
      }
    }

    & .formInsightsActions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 16px;
      & .formInsightsBtnCancel {
        padding: 0px 12px;
        min-height: 32px;
        color: var(--text-grayscale90);
        font-weight: var(--font-weight-medium);
      }
      & .formInsightsBtnSubmit {
        margin-left: 16px;
        background-color: var(--grayscale60);
        color: var(--white);
        font-weight: var(--font-weight-medium);
        & svg {
          fill: var(--white);
          color: var(--white);
        }
      }
    }
  }

  & .formSubmissionWrapper {
    margin-top: 16px;
    & .submittedFormText {
      font-weight: 500;
    }
    & .formSubmittedContextWrapper {
      & .formSubmittedContextTitle {
        margin-top: spacing(1);
      }
      & .formSubmittedContextValue {
        font-weight: 500;
        margin-top: 4px;
      }
    }
  }
}
