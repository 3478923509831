@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.agent {
  padding: 5px 10px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 23px auto;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  margin-bottom: 5px;

  @include noOutline;

  &:hover {
    background-color: var(--grayscale0);
  }

  &Assigned {
    font-weight: var(--font-weight-medium);

    .agentImageWrapper:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
    }
  }

  &ImageWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }

  &Image {
    width: 23px;
    height: 23px;
    position: relative;
    object-fit: cover;
  }

  &Name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.agentCheckIcon {
  position: absolute;
  z-index: 1;
  font-size: 23px;
}
