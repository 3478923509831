@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.bots {
  padding: 50px;
}

.botItems {
  margin-bottom: spacing(6);

  > a {
    color: inherit;
    text-decoration: none;

    > div {
      margin-bottom: spacing(2);
    }
  }
}

.createBot {
  margin: 10px 0 50px;
}

.title {
  color: var(--text-grayscale70);
}
