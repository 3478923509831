@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.messageList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  margin-left: 0px;
  padding-left: 0px;
  font-size: var(--font-size-large);
  margin-bottom: 0;
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
