@import 'frontend/styles/functions';

.insightsOverviewWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: var(--box-shadow-card);
  border-radius: var(--border-radius-large);
  background-color: var(--white);
  overflow: hidden;
  max-height: calc(100vh - 82px);
  & .insightsFilter {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & .insightsContainer {
    overflow: auto;
    & .header {
      display: flex;
      flex-direction: column;
      padding: spacing(2);
      gap: spacing(0.5);
      & .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--textgrayscale90);
        font-size: 20px;
        font-weight: var(--font-weight-medium);
      }
      & .titleDescription {
        color: var(--textgrayscale80);
        font-size: var(--font-size-large);
      }
    }

    & .content {
      border: 1px solid var(--grayscale15);
      border-radius: var(--border-radius-large);
      margin: spacing(2.5) spacing(2);
      & .contentHeader {
        border-bottom: 1px solid var(--grayscale15);
        & .contentTitle {
          padding: spacing(1.5) spacing(1.5) 0 spacing(1.5);
          font-size: var(--font-size-large);
          color: var(--textgrayscale90);
          font-weight: var(--font-weight-medium);
          line-height: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: spacing(0.5);
        }
        & .contentDescription {
          padding: 0 spacing(1.5) spacing(1.5) spacing(1.5);
          color: var(--textgrayscale80);
          line-height: 24px;
        }
      }

      & .contentWrapper {
        & .contentContainer {
          padding: spacing(2) spacing(3);
        }
      }
    }
  }
}
