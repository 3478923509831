@import 'frontend/styles/variables';

.panel {
  display: block;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.switchContainer {
  display: flex;
  align-items: center;
}

.sectionTitle {
  font-weight: var(--font-weight-medium);
}
