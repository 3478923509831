.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.avatar {
  margin-top: 100px;
  width: 200px;
}

.text {
  font-size: 25px;
  margin-top: 40px;
}
