@import 'frontend/styles/functions';

.selectedAgent {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-grayscale90);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-weight: var(--font-weight-450);
  font-size: var(--font-size-medium);
  & img {
    width: 14px;
    height: 14px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: spacing(0.5);
  }
}
