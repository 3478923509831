@import 'frontend/styles/functions';

.sortIcon {
  font-size: 16px;
  margin-left: spacing(1);
  position: relative;
  transform: rotate(180deg);

  &.reverse {
    transform: rotate(0deg);
    top: 1px;
  }
}
