@import 'frontend/styles/functions';

.logo {
  height: 58px;
  margin-bottom: spacing(6);
}

.textContainer {
  margin-bottom: spacing(4);

  h3 {
    font-weight: var(--font-weight-medium);
  }
}

.ctaButton {
  display: inline-block;
  margin-bottom: spacing(2);
}
