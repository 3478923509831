@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.wrapper {
  display: flex;
  border-radius: var(--border-radius-medium);
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: unset;
  padding: unset;

  @include noOutline;

  &:hover {
    background-color: var(--grayscale10);
  }
}

:where(.icon) {
  color: var(--grayscale70);
}
