@import 'frontend/styles/functions';

.labelRowWrapper {
  --_ratingScaleSection_rowGap: #{spacing(1)};

  list-style-type: none;
  padding: 0;
  margin-top: spacing(1);

  display: flex;
  flex-direction: column;
  gap: var(--_ratingScaleSection_rowGap);
  counter-reset: item;
}

.labelRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.labelSentiment {
  display: inline-block;
  font-weight: var(--font-weight-medium);
  width: 108px;
}

.labelEditLabels {
  font-weight: var(--font-weight-medium);
}

.selectSentiment {
  width: 100px;
}

.labelInputWrapper {
  position: relative;
  flex: 1;
}

.showLabelButton {
  all: unset;
  outline: revert;

  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  cursor: pointer;

  > svg {
    color: #333333;
    display: block;
  }
}

.labelErrorMessage {
  margin: unset;

  display: flex;
  margin-top: calc(var(--_ratingScaleSection_rowGap) * -1);
  align-items: center;
  color: var(--error-color);
  font-size: var(--font-size-small);
}

.ratePreviewCardValues {
  display: flex;
  padding-top: spacing(1);
  row-gap: spacing(2);
  justify-content: center;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
}

.ratePreviewCardValue {
  display: flex;
  width: 65px;
  flex-direction: column;
  align-items: center;
  gap: spacing(1);
  word-break: break-word;

  img {
    height: 24px;
    aspect-ratio: 1;
  }
}
