@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.statsPanel {
  min-height: unset;
}

.title {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(0.5);
}

.description {
  margin: spacing(0.5) 0 spacing(1) 0;
}

%number {
  font-size: 32px;
  font-weight: var(--font-weight-medium);
}

.duration {
  @extend %number;
  color: var(--blue-ribbon);
}
.amount {
  @extend %number;
}
