@import 'frontend/styles/variables';

.error {
  position: fixed;
  display: flex;
  top: 120px;
  left: 15px;
  width: fit-content;
  max-width: calc(100% - 440px);
  padding: 7px;
  flex-direction: row;
  align-items: flex-start;
  color: var(--white);
  background: var(--red-pomegranate);
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  z-index: 100;

  &Key {
    display: inline-block;
    margin: 0 0 0 5px;
    font-weight: bold;
  }

  &Text {
    display: inline-block;
    margin: 0 5px;
  }

  &InModal {
    position: absolute;
    top: 96px;
    max-width: 450px;
    margin-left: 35px;
  }
}
