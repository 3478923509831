@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.animated {
  position: fixed;
  left: 0px;
  height: 100%;
  z-index: 2;
  border-right: 2px solid var(--grayscale5);
}

.backdrop {
  position: absolute;
  top: -200px;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--shadow-color-dark);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
  width: 100vw;
  height: 200vh;

  @include noOutline;

  &Visible {
    opacity: 1;
    visibility: visible;
    transition: all 300ms linear;
  }
}

@keyframes show {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.show {
  animation: show 0.2s ease-in-out forwards;
}

.hide {
  animation: hide 0.2s ease-in-out forwards;
}
