@import '../../styles/variables';
@import '../../styles/mixins';

.options {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: var(--grayscale0);
  }

  %dotStyles {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--blue-ribbon);
    position: absolute;
  }

  &Button {
    content: '';
    @extend %dotStyles;

    &:before,
    &:after {
      content: '';
      @extend %dotStyles;
    }
    &:before {
      left: -7px;
    }
    &:after {
      left: 7px;
    }

    &Vertical {
      &:before {
        left: 0;
        top: -7px;
      }
      &:after {
        left: 0;
        top: 7px;
      }
    }
  }
}

.overlayContainer {
  padding: 6px 0;
  cursor: pointer;
}

div.overlayItem {
  white-space: nowrap;
  border: none;
  font-size: var(--font-size-medium);
  padding: 6px 12px;
  min-width: 173px;
  background-color: var(--white);
  color: var(--grayscale80);
  text-align: left;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }

  & svg {
    margin-right: 4px;
  }

  &:focus,
  &:active {
    @include noOutline;
    background-color: var(--grayscale10);
  }

  &:hover {
    color: var(--grayscale90);
    background-color: var(--grayscale0);
  }

  &Separator {
    background-color: var(--grayscale20);
    width: 100%;
    height: 1px;
    padding: 0;
    margin: 0;
    border: none;

    &:hover {
      background-color: var(--grayscale20);
    }
  }

  & .iconContainer {
    min-width: 18px;
    display: flex;
  }

  & .titleContainer {
    font-weight: 400;
  }

  &Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
