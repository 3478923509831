@import 'frontend/styles/mixins';

.sortHeader {
  @include noOutline;
  cursor: pointer;
  position: relative;
  &Selected {
    text-decoration: underline;
  }
  &Arrow {
    position: absolute;
    top: -8px;
    font-size: 18px;
    right: -12px;
    &Up {
      font-size: 10px;
      top: 2px;
    }
  }
}
