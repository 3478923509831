@import 'frontend/styles/functions';

.buttonGroupWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .btnGroupFirst {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .btnGroupLast {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & .btnGroupMiddle {
    border-radius: 0 !important;
  }
}
