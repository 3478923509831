@import '../../../../styles/variables';
@import '../../../../styles/functions';

.statContainer {
  width: 119px;
  text-align: center;
  border-left: 1px solid var(--grayscale30);

  .statName {
    font-size: 14px;
  }

  .statContent {
    margin-top: 4px;
    font-size: 26px;
    display: block;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .metaContainer,
  .brandingContainer {
    display: flex;
    align-items: center;
  }

  .dashboard {
    &Logo {
      height: 74px;
      width: 74px;
      margin-right: spacing(2);
      background-color: var(--green-harlequin);
      border-radius: 50%;
    }

    &Name {
      margin-bottom: 0;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-extralarge);
    }
  }
}
