@import 'frontend/styles/functions';

.options {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.optionLabel {
  cursor: pointer;
  user-select: none;
}

.label {
  transition: color 0.2s ease;

  .selected & {
    color: var(--primary-color);
    font-weight: 500;
  }
}

.sliderContainer {
  position: relative;
  height: 25px;
  border: 1px solid var(--grayscale15);
  background-color: var(--grayscale5);
  border-radius: 146px;
}

.dotsContainer {
  position: absolute;
  top: 50%;
  left: spacing(1);
  right: spacing(1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%);
}

.dotWrapper {
  padding-inline: spacing(2);
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--grayscale50);
  transition:
    background-color 0.2s ease,
    scale 0.2s ease;
  z-index: 1;

  &.selectedDot {
    scale: 1.5;
    background-color: var(--primary-color);
  }
}
