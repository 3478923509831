@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  padding: spacing(3);
  .panelSeparator {
    padding: spacing(3) 0;
  }
  .logo {
    height: 58px;
    margin-bottom: spacing(6);
  }
}

.buttonContainer {
  font-weight: var(--font-weight-medium);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > button {
    font-size: var(--font-size-large);
    padding-left: 0;
    color: var(--text-grayscale90);
  }

  & > svg {
    margin-right: spacing(1);
    path {
      stroke: var(--text-grayscale90);
    }
  }
}
