@import 'frontend/styles/functions';

.container {
  display: flex;
  max-width: 600px;
  font-size: 14px;
  border-radius: var(--border-radius-large);
  gap: spacing(1);

  &.info {
    border: 1px solid var(--grayscale15);
    color: var(--text-grayscale70);
  }
  &.warning {
    background-color: var(--grayscale5);
    color: var(--text-grayscale90);
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: var(--blue-ribbon);
    }
  }
}

.title {
  margin: 3px 0 spacing(1) 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);

  &:only-child {
    margin-bottom: 0;
  }
}

.icon {
  display: block;

  .warning & {
    color: var(--red-pomegranate);
  }
}

.text {
  > p {
    margin-bottom: 0;
    margin-top: spacing(1);

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.small {
  padding: spacing(1);
}

.medium {
  padding: spacing(2);
}

.large {
  padding: spacing(3);
}
