@import 'frontend/styles/functions';

.createEntity {
  display: flex;
  padding: spacing(1) spacing(3);
  align-items: center;

  > button {
    height: fit-content;
    display: inline-block;
  }
}
