@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.botImprovementTasksContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  & .botImprovementsLeftColumn {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    & .botImprovementsTotalCount {
      background-color: var(--blue-ribbon);
      color: white;
      border-radius: 50%;
      width: 68px;
      height: 68px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-weight: var(--font-weight-medium);
    }
    & .botImprovementsTotalInfo {
      margin: 0 spacing(2);
      & .botImprovementTasksTitle {
        font-size: var(--font-size-large);
        line-height: 24px;
        font-weight: var(--font-weight-medium);
        color: var(--grayscale90);
        margin-bottom: 4px;
      }
      & .botImprovementTasksDescription {
        font-size: var(--font-size-medium);
        line-height: 20px;
        font-weight: var(--font-weight-normal);
        color: var(--grayscale80);
      }
    }
  }

  & .botImprovementsRightColumn {
    margin-left: spacing(1);
    & .botImprovementsTasksListItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: var(--border-radius-large);
      margin-top: spacing(1);
      padding: 6px;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        background-color: var(--grayscale5);
      }

      & .botImprovementsTasksIconWrapper {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        display: flex;

        &.botImprovementsTasksIconOrange {
          background-color: var(--yellow-sunglow);
        }

        &.botImprovementsTasksIconRed {
          background-color: var(--red-pomegranate);
        }

        &.botImprovementsTasksIconBlue {
          background-color: var(--blue-ribbon);
        }
      }

      & .botImprovementsTasksListItemTitle {
        flex: 1;
        color: var(--grayscale80);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-medium);
        line-height: 16px;
        margin-left: spacing(1);
      }

      & .botImprovementsTasksListItemCount {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-small);
        color: var(--grayscale60);
      }
    }
  }
}
