@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: spacing(1);
}

.contentWrapper {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(1);

  > h3 {
    margin: unset;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
  }
}

.inputWrapper {
  position: relative;
  width: calc(100% - 30px);
  line-height: 28px;

  .deleteIcon {
    position: absolute;
    top: 1px;
    right: -40px;
    padding-top: 2px;

    svg {
      box-sizing: unset;
    }
  }

  input {
    background-color: var(--grayscale5);
    border: 2px solid var(--grayscale5);

    &:hover {
      background-color: var(--grayscale10);
      border-color: var(--grayscale10);
    }

    &:focus {
      outline: none;
      border-color: var(--blue-ribbon);
      background-color: var(--white);
    }
  }
}

.addReply {
  color: var(--text-grayscale70);
  text-decoration: underline;
  user-select: none;

  &:hover {
    color: var(--text-grayscale90);
    cursor: pointer;
  }

  &:focus {
    @include noOutline;
  }
}

.deleteButton {
  all: unset;
  outline: revert;
  cursor: pointer;
  margin-top: 28px;

  > svg {
    transition: color 0.2s;
    color: var(--grayscale70);

    &:hover {
      color: var(--red-pomegranate);
    }
  }
}

.replyInputWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: spacing(1);
}

.slotsWarning {
  font-size: var(--font-size-medium);
  line-height: 22px;
  display: flex;
  flex-direction: row;
  background: var(--white);
  border: 2px solid var(--red-pomegranate);
  border-radius: var(--border-radius-medium);
  padding: spacing(1);
  margin-bottom: spacing(2);
  width: calc(100% - 30px);
}

.slotsWarningIcon {
  margin: 0 spacing(1) 0;
  position: relative;
  top: -1px;
}
