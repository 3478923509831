@import 'frontend/styles/functions';

@keyframes getRed {
  0% {
    outline-color: var(--error-color);
  }
  100% {
    outline-color: var(--primary-color);
  }
}

.button {
  all: unset;
  outline: revert;

  padding: spacing(0.5);
  border-radius: var(--border-radius-medium);
  cursor: pointer;

  &:hover {
    background-color: var(--grayscale80);
  }

  &:active {
    color: var(--primary-color);
  }
}

.icon {
  display: block;
}

.buttonActive {
  background-color: var(--grayscale90);
}

/* Link stuff ⬇️ */

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(frontend/assets/icons/pencil.svg?url);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.linkAddMode {
  display: flex;
  width: 270px;
  gap: spacing(1);
}

.linkInput {
  all: unset;
  outline: none;
  box-sizing: border-box;
  flex: 1;

  padding: 8px;
  border-radius: 6px;
  height: 100%;
  background-color: var(--white);
  color: var(--grayscale90);

  &:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 0;
  }

  &.linkInputError {
    animation: getRed 1s ease-in-out;
  }
}

.linkButton {
  all: unset;
  outline: revert;
  padding: spacing(0.5);
  cursor: pointer;

  svg {
    display: block;
  }
}

.toAddArrow {
  width: 20px;
  height: 16px;
  color: var(--grayscale70);
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-small);
  text-align: center;
}

.toAddText {
  white-space: nowrap;
}

.linkAddInputWrapper {
  flex: 1;

  position: relative;
  display: flex;
}

.toAddTextContainer {
  position: absolute;
  display: flex;
  right: spacing(1);
  top: 50%;
  transform: translateY(-50%);
  color: var(--grayscale60);
  font-size: var(--font-size-small);
  gap: spacing(0.5);
}

/* Link stuff ⬆️ */
