@import 'frontend/styles/variables';

.image {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin: 5px 15px 5px 0px;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 2px solid transparent;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-large);
    margin: 0;
  }

  &Selected {
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border: 2px solid var(--blue-ribbon);
      border-radius: calc(var(--border-radius-large) + 3px);
    }
  }
}
