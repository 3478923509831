@import 'frontend/styles/functions';

.score {
  border-radius: 56px;
  margin-right: spacing(1);
  background-color: var(--blue-ribbon--light);
  color: var(--blue-ribbon--dark);
  font-size: var(--font-size-small);
  padding: spacing(0.375) spacing(0.75) spacing(0.375);
  border: none;

  &:hover {
    cursor: pointer;
  }
}
