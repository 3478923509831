@import 'frontend/styles/variables';

.navButtonWrapper {
  display: flex;
  & .navButton {
    &CanDrop {
      background: var(--blue-ribbon--very-light);
    }
  }
}
