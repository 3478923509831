@import 'frontend/styles/functions';

.topicsSearch {
  margin-bottom: spacing(1);
  & .topicsSearchInput {
    margin-top: spacing(1);
  }
}

.topicLabel {
  max-width: 480px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.languageHeader {
  margin: spacing(2) 0 spacing(1);
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale70);
  font-size: var(--font-size-small);
}
