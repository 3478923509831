@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.timestamp {
  margin: spacing(4) 0;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale70);

  &:first-child {
    margin: spacing(1) 0 spacing(4);
  }

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% + 20px);
    height: 1px;
    background-color: var(--grayscale10);
  }

  span {
    position: relative;
    background-color: var(--white);
    padding: 0 8px;
    z-index: 1;
  }
}

.optionsContainer {
  position: relative;

  &:hover .options {
    display: inline-flex;
  }
}
.externalLinkIcon,
.infoIcon {
  font-size: 18px;
}
.options {
  display: none;
  position: absolute;
  top: -15px;
  right: 15px;
  margin-right: 0;
  border-radius: var(--border-radius-medium);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}
.optionsItem {
  padding: 5px 7px;
  box-sizing: border-box;
  border: 1px solid var(--grayscale10);
  border-left: none;
  background-color: var(--white);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  display: flex;
  font-size: 14px;
  align-items: center;
  color: gray;
  margin: 0;

  &:focus {
    @include noOutline;
  }

  &:hover {
    .externalLinkIcon {
      color: var(--primary-color);
    }
    .infoIcon {
      color: var(--primary-color);
    }
  }

  &:last-child {
    border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  }
  &:first-child {
    border-left: 1px solid var(--grayscale15);
    border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
  }
  &:only-child {
    border-radius: var(--border-radius-small);
  }
}
