@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  padding: 20px;

  > div:not(:first-child) {
    margin-top: spacing(2);
  }
}

.statTitle {
  font-weight: 500;
}

.grid {
  margin-bottom: 0;
  margin-top: spacing(2);
  grid-gap: spacing(2);
}

.gridWrapper > div {
  grid-gap: spacing(4);
}

.statsWrapper {
  .stats {
    margin-bottom: spacing(2);
  }
}
