.dropdownWrapper {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  .languageDropdown {
    width: 100%;
  }

  > button {
    width: 100%;
  }
}
