@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.content {
  padding: 0;
}

.advancedLayout {
  h3 {
    font-weight: var(--font-weight-medium);
  }

  p {
    margin: 0;
    color: var(--grayscale70);
  }

  span {
    color: var(--black);
    font-weight: var(--font-weight-medium);
  }

  .contentTop {
    display: flex;
    align-items: center;
    margin-top: spacing(3);

    img {
      max-width: 100px;
      margin-right: spacing(3);
    }
  }

  .contentBottom {
    display: grid;
    grid-template-columns: 124px 1fr;
    align-items: center;
    margin-top: spacing(2);
  }

  button {
    margin-top: spacing(2);
  }

  .inlineBlock {
    display: inline-block;
  }
}

.toggleWrapper {
  display: flex;
  align-items: baseline;
}
