@import 'frontend/styles/functions';

.wrapper {
  display: flex;
  width: 100%;
  padding-inline: spacing(2);
  align-items: center;

  &:hover {
    background-color: var(--grayscale0);
  }

  & .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    & .checkboxLoader {
      position: absolute;
      left: calc(50% - spacing(0.5));
      top: calc(50% + spacing(0.1));
      transform: translate(-50%, -50%);
    }
  }
}

.checkbox {
  margin-right: spacing(1);
  flex-shrink: 0;
  &.checkboxLoading {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
}
