@import '../../../../styles/variables';

.sampleList {
  margin-top: 0px;
  margin-bottom: 40px;

  &Truncated {
    -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 99%, rgba(0, 0, 0, 0) 100%);
  }
}

.sampleHeader {
  padding-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sampleCount {
    color: var(--blue-ribbon);
    margin-left: 5px;
  }

  .sampleCollapse {
    font-size: 14px;
    cursor: pointer;
  }
}

.sampleItem {
  color: var(--text-grayscale70);
  margin: 16px 0;
  font-size: 14px;
  line-height: 22px;
}
