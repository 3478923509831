@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.list {
  border-radius: var(--border-radius-small);
}
.header {
  border-bottom: 1px solid var(--grayscale5);
  padding: spacing(1);
  margin: 0 spacing(1);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  background-color: var(--white);
  color: var(--text-grayscale70);
}

.item {
  border-bottom: 1px solid var(--grayscale5);
  padding: 10px;
  font-size: 14px;
  cursor: pointer;

  .main:last-child &:last-child {
    border-bottom: none;
  }
}
.footer {
  font-size: 14px;
  padding: spacing(1);
  margin: 0 spacing(1);
}

.navLinkItem {
  padding: spacing(0.75) spacing(0.5) spacing(0.75) spacing(0.75);
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius-large);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
  margin-top: spacing(0.25);
  color: var(--grayscale80);
  border: 1px solid transparent;
  &:first-child {
    margin-top: 0;
  }

  & svg {
    font-size: 24px;
  }
  &:hover {
    background-color: var(--grayscale0);
    border: 1px solid var(--grayscale15);
  }
  &:active,
  &.navLinkItemActive {
    background-color: var(--grayscale10);
    color: var(--blue-ribbon);
    border: 1px solid var(--grayscale15);
  }
}
