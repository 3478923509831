@import '../../../../styles/variables';

.sampleMatch {
  color: transparent;
  display: inline-block;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    position: relative;
    top: 2px;
    left: 7px;
    background: var(--grayscale60);
    display: block;
  }

  &Before {
    opacity: 0;
    &:after {
      background: none;
    }
  }

  &Container {
    position: absolute;
    pointer-events: none;
    white-space: nowrap;
    z-index: 1;
    top: -4px;
    left: -1px;
  }
}
