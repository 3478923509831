@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.title {
  font-size: 24px;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
}

.description {
  margin: spacing() 0;
  max-width: 600px;
}

.feedbackColumns {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: spacing(6);
}

.panel {
  padding: spacing(4);
  transition: box-shadow 300ms ease;
  position: relative;
  max-width: 400px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 6px 0px var(--shadow-color-dark);
  }

  &Title {
    font-size: 20px;
    font-weight: var(--font-weight-medium);
    margin: 0;
  }
}

.arrowIcon {
  position: absolute;
  right: spacing(4);
  bottom: spacing(4);
}
