@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

$arrowWidth: 3px;
$arrowHeight: 5px;
$activeColor: #ebf3ff;
$activeColorHover: #dceaff; // darken(#ebf3ff, 3%);

.libraryPanel {
  flex-shrink: 0;
  position: sticky;
  display: grid !important;
  height: calc(100vh - 105px); // Is needed by Safari to get correct height
  padding: 0 !important;
  margin-left: 16px;
  bottom: 0;
  grid-template-rows: auto auto 1fr;
  user-select: none;
  margin-top: 19px;
  top: calc(67px + 19px);
  z-index: 1;
  max-width: 70vw;

  .warning {
    color: var(--red-pomegranate);
  }

  & .libraryShadow {
    z-index: 1;
    box-shadow: var(--box-shadow-card-bottom);
  }
}

.resize {
  height: 100%;
  width: 2px;
  background-color: transparent;
  border-right: 1px solid transparent;
  cursor: col-resize;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: calc(100% + 2px); // Push the resize icon a bit to the right
    width: 14px;
    height: 52px;
    top: calc(50% - 60px);
  }
  &:after {
    content: '';
    position: absolute;
    left: calc(100% + 8px); // Push the resize icon a bit to the right
    width: 2px;
    height: 32px;
    background-color: var(--grayscale70);
    top: calc(50% - 50px);
    border-radius: 10px;
  }

  &:focus {
    outline: none;
  }

  &Active {
    border-right-color: var(--blue-ribbon--light);

    .resize:after {
      background-color: var(--blue-ribbon--light);
    }
  }
}

.libraryNavigation {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 spacing(1.5) spacing(1.5) spacing(1.5);
  position: relative;
  display: flex;
  flex-direction: column;
}

.arrow {
  &:after {
    content: '';
    position: absolute;
    left: 8px;
    top: 16px;
    width: 0;
    height: 0;
    z-index: 0;
    cursor: pointer;
  }

  &Right:after {
    border-top: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid transparent;
    border-left: $arrowHeight solid var(--grayscale70);
  }

  &Down:after {
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-top: $arrowHeight solid var(--grayscale70);
  }
}

.icon {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  padding-left: spacing(1);
  padding-top: spacing(1);
  padding-bottom: spacing(1);

  &FolderActive {
    background-color: var(--grayscale60);
  }
}

.item {
  @include noOutline;
  display: flex;
  position: relative;
  color: var(--text-grayscale90);
  text-decoration: none;
  line-height: 23px;
  cursor: pointer;

  &:not(&Link) {
    margin: spacing(1) 0;

    > span {
      color: var(--text-grayscale90);
      padding-left: 22px;
      font-weight: var(--font-weight-medium);
      background-color: var(--grayscale0);
      border-radius: var(--border-radius-large);

      &:hover {
        background-color: var(--grayscale10);
      }
    }
  }

  &FolderActive span:first-child {
    background-color: $activeColor;

    &:hover {
      background-color: $activeColorHover;
    }
  }

  &Active {
    background-color: $activeColor;
    border-radius: var(--border-radius-small);
  }

  &Disabled {
    color: var(--text-grayscale70);
    text-decoration: line-through;
  }

  &Dragging {
    color: var(--text-grayscale70);
    background-color: var(--grayscale20) !important;
  }

  &CanDrop {
    color: var(--text-grayscale90);
    background-color: var(--blue-ribbon--very-light);
  }

  &Link {
    &:hover {
      .title {
        color: var(--text-grayscale90);
        text-decoration: underline;
      }
    }
  }

  &HasFollowups {
    .arrow:after {
      left: -6px;
    }
  }
}

.libraryLoader {
  display: inline-flex;
  position: relative;
  margin: 0 7px;
  top: -1px;

  &Dialogue {
    top: 11px;
    position: absolute;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
