@import 'frontend/styles/functions';

.container {
  display: flex;
  flex-direction: column;
  width: 320px;
}

.dropdownHeader {
  display: flex;
  padding: spacing(1.5);
  align-items: center;
  border-bottom: 1px solid var(--grayscale10);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
}

.ruleHeading {
  font-weight: var(--font-weight-medium);
}

.resetButtonStyles {
  all: unset;
  outline: revert;
  cursor: pointer;

  > svg {
    display: block;
  }
}

.infoButton {
  margin-left: spacing(0.5);
  color: var(--grayscale70);

  > svg {
    width: 18px;
    aspect-ratio: 1;
  }
}

.closeButton {
  margin-left: auto;
}

.emptyState {
  padding: spacing(5) spacing(2);
}

.dropdownFooter {
  padding: spacing(1.5);
  display: flex;
  border-top: 1px solid var(--grayscale10);
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.04);
}

.createRuleButton {
  flex-basis: 100%;
}

.ruleList {
  display: flex;
  max-height: 30vh;
  padding: spacing(1);
  flex-direction: column;
  overflow: auto;
}

.selectedRule {
  color: var(--primary-color);
  background-color: var(--grayscale10);
}
