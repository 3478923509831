@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import '../../Build';

.specialDialogueFormWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  & .pageContent {
    height: calc(100vh - 105px);
    padding: spacing(0);
    overflow: hidden;
  }

  & .content {
    display: grid;
    grid-template-columns: auto 2px 314px 2px 10fr auto;
    height: calc(100% - 69px);

    & .tabContentWrapper {
      overflow: auto;
      position: relative;
      padding: spacing(3);
      margin: spacing(0.5) spacing(0.5) spacing(0.5) 0;
    }

    & .previewContainer {
      margin-top: 2px;

      & h3 {
        margin-top: 0;
      }
    }
  }
}
