@import 'frontend/styles/functions';

.header {
  font-weight: var(--font-weight-medium);
}

.panel {
  display: grid;
  grid-template-columns: 63px 1fr;
  padding: spacing(2) spacing(3);
}

.panelDescription {
  color: var(--text-grayscale90);
}

.newAppBtn {
  display: inline-flex;
}

.applicationPanel {
  padding: 0;
}
