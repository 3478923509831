@import 'frontend/styles/functions';

.container {
  display: flex;
  flex-direction: column;
}

header {
  padding: spacing(2);
}

h1 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
}

.quantity {
  color: var(--blue-ribbon);
  margin-left: spacing(1);
}

section.actions {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  fieldset {
    margin: 0;
    padding: 0;
    border: none;
    margin-block: auto;
    display: flex;
    gap: 1em;
  }
}

.workspaceCell {
  display: flex;
  gap: 1em;
  min-height: spacing(8);
  .image {
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    margin-block: auto;
  }
  .title {
    margin-block: auto;
    font-weight: var(--font-weight-medium);
    line-height: 1.5;
    .projectName {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-medium);
      color: var(--grayscale90);
    }
  }
}

.languageCell {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  .languageCode {
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-medium);
    display: inline-block;
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    padding: 3px 9px;
    margin-right: spacing(1);
    white-space: nowrap;
    width: fit-content;
  }
}

.botActivity {
  justify-content: flex-end;
}

.options .divider {
  height: 1px;
  background-color: var(--grayscale15);
  margin: 2px 0;
  padding: 0;
  pointer-events: none;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
