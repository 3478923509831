@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.dialoguePath {
  margin-right: spacing(2);
  overflow-x: auto;
  overscroll-behavior-x: contain;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.folderElement,
.parentElement {
  display: flex;
  align-items: center;
  gap: spacing(0.5);

  > svg {
    font-size: 18px;
  }
}

.folderElement {
  font-weight: var(--font-weight-medium);
}

.parentElement {
  &:hover {
    text-decoration: underline;
  }

  &.hasFolderParentInCollapsed {
    padding-left: 22px; /* indenting under Folders */
  }
}

.breadcrumbs {
  &Container {
    font-size: var(--font-size-large);
    color: var(--text-grayscale90, black);
  }

  &Li {
    font-weight: var(--font-weight-medium);
  }

  &Dropdown {
    &Overlay {
      /* This snippet fixes the incompatibility between `overflow-x:auto` parents and absolute children.
      By overriding the `absolute` dropdown to `fixed`, it let it pops out from the cut overflow. */
      position: fixed;
      top: auto;
      left: auto;

      margin-top: 25px;
      margin-left: -15px;
      overflow: auto;
    }

    &OverlayUl {
      padding-block: spacing(0.5);
      font-size: var(--font-size-medium);
    }

    &OverlayLi {
      font-weight: var(--font-weight-normal);
      padding: spacing(0.5) spacing(1);
    }
  }
}

.input {
  &Wrapper div input {
    display: inline-block;
    padding: 2px;
    margin-left: 2px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: inherit;
    font-weight: inherit;
    background-color: inherit;
    color: inherit;
    cursor: pointer;

    &:hover {
      background-color: var(--grayscale5);
    }

    &:focus {
      @include noOutline;
      background-color: var(--white);
      border: 1px solid var(--grayscale20);
      cursor: text;
    }

    &:read-only {
      background-color: inherit;
      border: unset;
      cursor: initial;
    }
  }

  &WrapperDisabled div input {
    color: var(--grayscale60);
    font-weight: initial;
    text-decoration: line-through;
  }

  &Icon {
    margin-right: spacing(0.5);
  }
}
