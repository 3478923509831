@import '../../../../styles/variables';

.dialogueList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.dialogueHeader {
  margin: 0 0 10px;
  text-align: left;
}

.dialogueItem {
  display: inline-block;
  color: var(--grayscale90);
  margin: 3px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: var(--blue-ribbon);
  }
}
