@import 'frontend/styles/functions';

.fakeButtons {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);
}

.fakeButton {
  background: unset;
  border: 1px solid black;
  border-radius: var(--border-radius-small);
  padding: spacing(0.5) spacing(1.5);
  cursor: pointer;

  &.active {
    background-color: var(--grayscale10);
  }
}
