@import 'frontend/styles/colors';

.wrapper {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 30px;

  &Nested {
    grid-gap: 10px;
  }
}

.doughnutValues {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-gap: 20px;
  font-size: 16px;

  :nth-child(1) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 1);
  }
  :nth-child(2) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 2);
  }
  :nth-child(3) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 3);
  }
  :nth-child(4) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 4);
  }
  :nth-child(5) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 5);
  }
  :nth-child(6) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 6);
  }
  :nth-child(7) .colorBox {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: nth($palette, 7);
  }

  &Option {
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.doughnutHovered {
  .doughnutValues {
    &Option {
      opacity: 0.5;
    }
    &Selected {
      opacity: 1;
    }
  }
}

.doughnut {
  position: relative;
  float: left;

  svg {
    transform: rotate(-90deg);
  }

  circle {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    stroke-linecap: round;
    cursor: pointer;

    &:nth-child(1) {
      stroke: nth($palette, 1);
    }
    &:nth-child(2) {
      stroke: nth($palette, 2);
    }
    &:nth-child(3) {
      stroke: nth($palette, 3);
    }
    &:nth-child(4) {
      stroke: nth($palette, 4);
    }
    &:nth-child(5) {
      stroke: nth($palette, 5);
    }
    &:nth-child(6) {
      stroke: nth($palette, 6);
    }
    &:nth-child(7) {
      stroke: nth($palette, 7);
    }
  }

  &Nested {
    transform: scale(0.35);
  }
}

.image {
  width: auto;
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
}
