@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.header {
  display: flex;

  button {
    cursor: pointer;
    border: none;
    font-size: 14px;
    font-weight: bold;
    @include noOutline;

    &:first-child {
      margin-right: spacing(2);
    }
    &:last-child {
      margin-left: spacing(2);
    }
  }

  a {
    margin-right: spacing(1);
  }
}

.sampleList {
  display: flex;
  flex-direction: column;
  margin: spacing(3) 0 0;
  font-size: 14px;

  span {
    margin: 4px 0;
  }
}
