@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.button {
  color: var(--text-grayscale90);
  border: 1px solid var(--grayscale15);
  background: var(--white);
  font-weight: var(--font-weight-medium);
  padding: spacing(0.625) spacing(2.5625);
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-medium);
  line-height: 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: baseline;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  box-shadow: var(--box-shadow-button);

  &Click {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.18) 100%), var(--white);
    border: 1px solid var(--grayscale30);
    align-self: flex-end;
  }
}
