@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.innerContainer {
  padding: spacing(2);
  position: sticky;
  top: 153px; // Navbar (103px) + PageContent padding (50px)
  height: fit-content;
  button {
    margin-right: 10px;
  }
}

.item {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: spacing(2);
  align-items: center;
  justify-content: flex-start;
  margin-bottom: spacing(1);

  &:last-child {
    margin-bottom: 0;
  }

  &Feedbacks {
    grid-template-columns: auto auto auto auto;
  }
}
