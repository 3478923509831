@import 'frontend/styles/functions';

.container {
  display: flex;
  justify-content: space-between;
}

.action {
  display: flex;
  flex-direction: row;
}
