@import 'frontend/styles/functions';
@import 'frontend/styles/colors';

.nodeWrapper:hover .nodeButtonContainer {
  display: block;
}

.flowDialogueHeadingWrapper {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.deleteItem {
  color: var(--red-pomegranate) !important;
}

.optionDropdown {
  top: unset !important;
  left: unset !important;
  & div {
    white-space: initial;
    font-size: 14px;
  }
}

.nodeInnerContent {
  border-radius: 8px;
  text-align: left;
  cursor: default;
  padding: 3px 12px;
  background-color: var(--white);
  word-break: break-word;
  hyphens: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 240ms ease;
  box-shadow: var(--box-shadow-card);
  & > a {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.currentDialogue {
    background-color: var(--grayscale0);
    border: 2px solid var(--grayscale50);
  }

  .editor {
    color: var(--greyscale80);
    font-size: 12px;
    line-height: 16px;
    border: none;
    resize: none;
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    height: 18px;
  }

  &.disabled {
    & .flowDialogueHeadingWrapper {
      .editor {
        opacity: 0.5;
        text-decoration: line-through;
      }
    }
  }

  &.previewActive {
    outline: 2px solid var(--blue-ribbon);
  }

  .dialogueDot {
    margin-top: 2px;
  }

  &.active {
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.24),
      0px 2px 6px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.24),
      0px 2px 6px rgba(0, 0, 0, 0.25);
    transition: all 240ms ease;
    .optionButton {
      opacity: 1;
    }
  }

  .saveLoader {
    position: absolute;
    top: 7px;
    right: 7px;
  }

  .optionButton {
    &:hover {
      background-color: var(--grayscale0);
      & .nodeOptions {
        .optionDot {
          background-color: var(--blue-ribbon);
        }
      }
    }

    & .nodeOptions {
      display: grid;
      grid-template-rows: 3px 3px 3px;
      grid-gap: 2px;
      align-content: center;
      justify-content: center;
      top: spacing(1);
      width: 28px;
      height: 28px;
      cursor: pointer;

      .optionDot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--grayscale70);
      }
    }

    &.optionButtonActive {
      opacity: 1;
      background-color: var(--grayscale0);
      & .nodeOptions {
        & .optionDot {
          background-color: var(--blue-ribbon);
        }
      }
    }
  }
}

.nodeButtonContainer {
  position: absolute;
  bottom: 0;
  left: spacing(2);
  // display: none;

  .button {
    padding: 6px;
    display: flex;
    background-color: var(--gray-mischka);
    border: none;
    border-radius: var(--border-radius-small);

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
