@import 'frontend/styles/variables';
@import '../../../../styles/mixins';

.wrapper {
  display: grid;
  grid-template-columns: 1;
}

.button {
  padding: 0;
  margin-bottom: 5px;

  span {
    display: block;
    padding: 9px 30px 8px;
    border-radius: 0px;
    color: var(--text-grayscale90);
    margin: 0;
    cursor: pointer;
    background-color: var(--grayscale5);
    border: 1px solid var(--grayscale20);
    border-right: none;
    text-align: center;
  }

  @include noOutline;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &:first-child span {
    border-radius: 5px 0 0 5px;
  }
  &:last-child span {
    border-right: 1px solid var(--grayscale20);
    border-radius: 0 5px 5px 0;
  }

  input:checked ~ span {
    background-color: var(--blue-ribbon);
    color: var(--white);
  }
}

.label {
  color: rgb(105, 105, 105);
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
  display: block;
  letter-spacing: 0px;
}
