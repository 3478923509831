@import 'frontend/styles/functions';

.embeddedAnalyticsFiltersWrapper {
  position: sticky;
  top: 111px;
  display: flex;
  width: 100%;
  z-index: 100;
  margin-top: spacing(1);
  padding: spacing(0.625) spacing(1.25);
}

.helpText {
  text-align: center;
  line-height: 20px;
  color: var(--text-grayscale70);
}
