@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.welcomeHeaderPicker {
  height: 200px;
  width: 100%;

  .welcomePageHeader {
    height: 100%;
    width: 100%;
    border-radius: 6px 6px 0 0;
  }
}

.textContent {
  padding: 25px;

  .welcomeHeaderText,
  .welcomeText {
    display: block;
  }

  .welcomeHeaderText {
    font-weight: var(--font-weight-medium);
  }

  .welcomeText {
    margin: 15px 0 30px;
  }

  .startButton {
    border-radius: 18px;
    display: inline-block;
    font-size: 12px;
    height: 30px;
    width: 130px;
  }
}
