@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  font-size: 14px;

  li {
    margin-bottom: spacing(0.5);
  }
}

.link {
  color: var(--blue-ribbon);
}

.title {
  font-size: 24px;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
}
