@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.lineChart {
  position: relative;

  & > div {
    position: absolute;
  }

  &Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &Description {
    font-size: var(--font-size-medium);
    padding-left: spacing(2);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--blue-ribbon);
    }
  }
}
