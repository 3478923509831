.tooltipContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  & .fieldTooltip {
    margin-left: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
