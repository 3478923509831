@import 'frontend/styles/variables';

.promptWrapper {
  max-width: 450px;
}

.content {
  padding: 0;
}

.sectionTitle {
  font-weight: var(--font-weight-medium);
}
