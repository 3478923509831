@import 'frontend/styles/variables';

.permissions {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;

  > div {
    justify-content: flex-end;
    margin-top: 1px;
  }
}

.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 30px;
  }
}
