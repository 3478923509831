@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.navigationContainer {
  display: flex;
  flex-direction: column;
  & .navigationRightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .navigationCardCanDrop {
    background-color: var(--grayscale10);
    color: var(--blue-ribbon);
  }
  & .navigationCardNoDrop {
    // The drag and drop from dialogues back triggers hover for some reason
    // this is so we remove the hover effect on the other items
    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }
  & .navigationElContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > svg {
      margin-right: spacing(0.5);
    }
  }
}
