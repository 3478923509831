@import 'frontend/styles/functions';

.button {
  &:first-child {
    padding-left: 0;
  }

  svg {
    margin-right: 2px;
    font-size: 20px;
  }
}
