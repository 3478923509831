@import '../../../../styles/variables';

.setupContainer,
.contextContainer,
.selectContainer,
.descriptionContainer {
  text-align: left;
  padding: 0 70px;

  p {
    font-size: 14px;
    color: var(--text-grayscale70);
  }
}

.stepsContainer {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;

  .step {
    height: 3px;
    width: 60px;
    background-color: var(--grayscale20);

    &.activeStep {
      background-color: var(--blue-ribbon);
    }
  }
}

.descriptionContainer {
  p {
    font-size: 14px;
    color: var(--text-grayscale70);
  }
}

.contextContainer {
  .contextList {
    list-style-type: none;

    .contextPoint {
      color: var(--text-grayscale90);
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        margin-right: 10px;
        border-radius: 100px;
        display: inline-block;
        background-color: var(--blue-ribbon);
      }
    }
  }

  .contextDescription {
    margin-left: 18px;
  }
}
