@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

@mixin enlargeImage {
  position: absolute;
  top: 6px;
  left: auto;
  right: 6px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  padding: spacing(0.5) spacing(0.5) spacing(0.25) spacing(0.5);
}

.carouselWrapper {
  display: flex;
  flex-direction: row;
  gap: spacing(0.625);
  &.carouselWrapperButtonClick {
    align-self: flex-end;
  }

  & .imageFullScreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: spacing(5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    & .imagFullScreenContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      max-height: 80%;
      max-width: 80%;

      & > img {
        object-fit: contain;
        width: 100%;
        border-radius: var(--border-radius-small);
      }
      & .imageEnlarge {
        @include enlargeImage;
      }
    }
  }
}

%insetBoxShadow {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--border-radius-small);
    box-shadow: rgb(0 0 0 / 32%) 0px 0px 1px inset;
    pointer-events: none;
  }
}

.imagePreview {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-small);
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 6px;

  @include noOutline;

  img {
    border-radius: 0px;
    max-width: 100%;
    width: auto;
    max-height: 350px;
    min-height: 100px;
    display: block;
    position: relative;
    object-fit: cover;
  }

  &Carousel {
    cursor: pointer;
  }

  &.imageSmall {
    width: 124px;
  }
  &.imageMedium {
    width: 178px;
  }
  &.imageLarge {
    width: 250px;
  }

  @extend %insetBoxShadow;
}

.imageWrapper {
  position: relative;
  display: block;
  min-height: 100px;
  height: auto;
  & .imageEnlarge {
    @include enlargeImage;
  }
}

.imageCaptions {
  > *:last-child {
    margin-bottom: 8px;
  }
}

.title {
  font-weight: 500;
  margin: 6px spacing(1) 0;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  margin: 2px spacing(1) spacing(1);
  font-size: 12px;
  color: var(--grayscale70);
  white-space: pre-line;
}

.imageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding-inline: spacing(2.5);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: var(--font-weight-medium);
  gap: spacing(1);

  &Label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.imageButtonClicked {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), var(--white);
    border: 1px solid var(--grayscale30);
    align-self: flex-end;
  }

  svg {
    flex-shrink: 0;
    font-size: 18px;
  }
}

.imageCardPlaceholder {
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 6px;
  overflow: hidden;
  position: relative;
  width: calc(100% + 6px); // Hide inset shadow on the right
  display: flex;
  flex-direction: column;

  @extend %insetBoxShadow;
}

.imagePlaceholder {
  background-color: #f8fafc;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  flex: 1;
}

.imagePlaceholderNumber {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: var(--white);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%placeholderText {
  display: block;
  height: 12px;
  border-radius: 10px;
}

.placeholderTitle {
  @extend %placeholderText;
  width: 40%;
  margin: 11px spacing(1) 0;
  background-color: var(--grayscale30);
}

.placeholderDescription {
  @extend %placeholderText;
  width: 70%;
  margin: 9px spacing(1) spacing(1);
  background-color: var(--grayscale15);
}
