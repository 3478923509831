@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.branding {
  display: flex;
  z-index: 20;
  align-items: center;
  position: fixed;
  left: 40px;
  top: 40px;

  @media screen and (max-width: $phone) {
    left: spacing(2);
  }

  &Link {
    text-decoration: none;
    color: var(--blue-ribbon);
    margin-left: spacing(2);
  }

  &Name {
    font-family: 'KindlySans', sans-serif;
    font-size: 40px;
    line-height: 40px;
  }

  .blobContainer {
    width: 45px;
    height: 40px;
  }
}

.title {
  font-size: 26px;
  margin-bottom: spacing(3);
}

.description {
  font-size: 16px;
  margin-top: 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.seconds {
  font-size: 26px;
}

.collaboratorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginError {
  color: var(--red-pomegranate);
  margin-bottom: var(--gutter-small);
}

.loginSubmitForgot {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: var(--text-grayscale70);
    font-size: 16px;

    &:hover {
      color: var(--text-grayscale90);
    }
  }
}

.fullPage {
  height: 100vh;
  background: var(--white);

  @media screen and (max-width: $phone) {
    padding-top: 120px;
    display: block;
  }
}

.link {
  color: var(--blue-ribbon);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.termsOfUse {
  color: var(--grayscale70);
  margin-top: 40px;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

.ssoLogin {
  margin-top: spacing(4);
  text-align: center;
}

.privacy {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: spacing(1);

  &Checkbox.privacyCheckbox {
    padding: 0;
    border-radius: var(--border-radius-small);
  }

  &Text {
    display: inline;
    cursor: pointer;

    a {
      color: revert;
      text-decoration: revert;
    }
  }

  &Error {
    color: var(--red-pomegranate);
  }
}
