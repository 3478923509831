@import 'frontend/styles/functions';

.messageLines,
.pluginReplies {
  display: flex;
  flex-direction: column;
}

.messageLines {
  padding-block: spacing(3);
  overflow-y: auto;
  overflow-x: hidden;
}

.fileUploadText {
  color: var(--blue-ribbon);
  text-decoration: underline;
}

.messageFromUser {
  margin-left: auto;
}

.previewButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.previewButtonsHorizontal {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.messageIntent {
  background-color: var(--grayscale70);
}

.pluginReplies:before {
  content: '';
  margin: spacing(1) auto;
  width: 1px;
  height: 30px;
  background-color: var(--grayscale30);
}

.emptyState {
  text-align: center;
  padding: spacing(4) spacing(2);

  &IconWrapper {
    display: inline-flex;
    width: 56px;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    background-color: #ccdbff;
    border-radius: var(--border-radius-large);

    svg {
      font-size: 40px;
      color: #0045e5;
    }
  }

  &Title {
    font-weight: var(--font-weight-medium);
    margin-top: spacing(2);
  }

  &Description {
    margin-top: spacing(1);
  }

  &Button {
    display: flex;
    height: 32px;
    padding: spacing(1);
    align-items: center;
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);
    border: 1px solid var(--grayscale15);
    border-radius: var(--border-radius-medium);

    &:hover,
    &:active,
    &[aria-expanded='true'] {
      background-color: var(--grayscale10);
    }

    &:active,
    &[aria-expanded='true'] {
      color: var(--primary-color);
    }

    > svg {
      font-size: 18px;
    }
  }
}

.formPreviewWrapper {
  padding: 8px;
  background-color: var(--grayscale10);
  border-radius: 8px;
  .formPreviewContainer {
    border-radius: var(--border-radius-medium);
    background-color: var(--white);
    padding: 12px;

    & .formPreviewTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4px;
      color: var(--text-grayscale90);
      font-weight: var(--font-weight-medium);
      & > svg {
        margin-right: 4px;
      }
    }

    & .formPreviewContent {
      & .fileInputPreview {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: spacing(3);
        font-size: var(--font-size-small);
        border: 1px dashed var(--blue-ribbon);
        border-radius: var(--border-radius-medium);
        font-weight: var(--font-weight-medium);
      }
      & .fileInputPreviewInput {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      & .formPreviewInputContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        & .formPreviewCheckbox {
          & .formPreviewInputLabelWrapper {
            padding: spacing(1) 0;
          }
          & .previewCheckboxWrapper {
            display: flex;
            flex-direction: row;
            padding: 0;
            padding-bottom: spacing(1);
            & .previewCheckboxLabel {
              margin-left: spacing(1);
              font-size: var(--font-size-medium);
              font-weight: var(--font-weight-normal);
            }
          }
        }
        & .sliderAffixWrapper {
          margin-top: spacing(1);
          display: flex;
          flex-direction: row;
          & div:nth-child(2) {
            padding: 0 12px;
          }
        }
        & .formPreviewMaxLength {
          position: absolute;
          bottom: 10px;
          right: 8px;
          font-size: var(--font-size-small);
          color: var(--grayscale40);
        }
        & .formPreviewInput {
          & > input,
          & > textarea {
            border-radius: var(--border-radius-16);
            background-color: var(--grayscale5);
          }
        }

        & .formPreviewInputLabelWrapper {
          display: flex;
          flex-direction: row;
          & .formPreviewInputRequired {
            color: var(--grayscale60);
            margin-left: 4px;
          }
        }
      }

      & .formPreviewHelperText {
        margin-top: 4px;
        color: var(--grayscale70);
        font-size: var(--font-size-small);
      }
    }

    & .formPreviewActions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 16px;
      & .formPreviewBtnCancel {
        padding: 0px 12px;
        min-height: 32px;
        color: var(--text-grayscale90);
        font-weight: var(--font-weight-medium);
      }
      & .formPreviewBtnSubmit {
        margin-left: 16px;
        background-color: var(--grayscale60);
        color: var(--white);
        font-weight: var(--font-weight-medium);
        & svg {
          fill: var(--white);
          color: var(--white);
        }
      }
    }
  }
}
