@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.accordion {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: spacing(0.725);
  user-select: none;
  @include noOutline;

  &:focus {
    // https://stackoverflow.com/a/73301667
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }

  h4 {
    color: var(--grayscale70);
  }

  svg {
    color: var(--grayscale70);
  }
}

.children {
  margin-top: spacing(2);
  flex-direction: column;
  &:last-of-type {
    flex: 1;
  }
}
