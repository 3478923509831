@import 'frontend/styles/functions';

.fieldActionRow {
  display: flex;
  flex-direction: row;
  & svg {
    font-size: 20px;
    margin-right: 16px;
  }
}

.fieldAccordion {
  margin-top: spacing(3);
  & > div:last-child {
    margin-top: 14.5px;
  }
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
}

.fieldDivider {
  height: 1px;
  background-color: var(--grayscale15);
  margin: 16px 0;
}

.inputDescription {
  color: var(--text-grayscale70);
  margin-bottom: spacing(1);
}
