@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.itemContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: spacing(1) 0;

  &:first-child {
    border-top: 1px solid var(--grayscale5);
  }

  :last-child {
    text-align: end;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  span {
    text-decoration: line-through;
  }
}
