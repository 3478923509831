@import 'frontend/styles/functions';

.ghostLines {
  position: relative;
  width: 100%;
  height: 32px;
}

.ghostContainer {
  position: relative;
  overflow: hidden;
  height: 16px;
  width: 80%;
  border-radius: 8px;
  margin-bottom: 16px;
}

.ghostStripe {
  height: 16px;

  &:before {
    width: 90%;
  }
}
