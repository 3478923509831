@import 'frontend/styles/functions';

.redDot::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--red-pomegranate);
  border-radius: 50%;
}

:is(.previousRuleButton.redDot, .nextRuleButton.redDot)::before {
  left: 2px;
  top: 30%;
  transform: translateY(-50%);
}

.ruleIconWrapper {
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  max-width: 550px;
  margin-bottom: spacing(3);
}

.rule {
  display: flex;
  gap: spacing(1);
  align-items: center;
  flex: 1;
  min-width: 0;
  cursor: default;
  &:hover,
  &:focus-within {
    .dropdown {
      display: flex;
    }
  }
  .ruleNameWrapper {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-inline: spacing(1);
  }

  &Name.redDot::before {
    transform: translate(-100%, -25%);
  }
}

.dropdown {
  margin-left: auto;
  display: none;
  align-items: center;
  justify-content: center;
}
