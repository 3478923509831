@import 'frontend/styles/functions';

.activeDropdownWrapper {
  padding-bottom: spacing(1);
  &.activeDropdownWithFooter {
    padding-bottom: 0;
  }
  & .filterOverlayWrapper {
    width: 100%;
    max-width: 480px;
    min-width: 230px;
    & .filterDropdownFooter {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--white);
      padding: spacing(1);
      display: flex;
      flex-direction: row;
      & .filterDropdownNext {
        flex: 1;
      }
    }

    & > * {
      padding: 0 spacing(1);
      margin-bottom: spacing(0.25);
    }

    &.filterOverlayWrapperWithFooter {
      & > * {
        margin-bottom: 0;
      }
    }
  }
}

.filterDropdown {
  display: flex;
  flex-direction: column;
  & .filterDropdownHeader {
    position: sticky;
    background-color: var(--white);
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    align-items: center;
    padding: spacing(1.5) spacing(1.5) spacing(0.75) spacing(1.5);
    text-indent: spacing(0.5);
    margin-bottom: spacing(1);
    &.filterDropdownHeaderScrolled {
      z-index: 1;
      box-shadow: var(--box-shadow-card-bottom);
    }
    & .filterDropdownHeaderLeftContent {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & .filterDropdownHeaderTitle {
      flex: 1;
      display: flex;
      flex-direction: row;
      &.filterDropdownHeaderTitleStep {
        justify-content: center;
      }
    }
  }
}
