@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonWrapper {
  display: flex;
  position: relative;
  user-select: none;
  border-radius: 30px;
  z-index: 1;

  &:hover {
    & .options {
      transform: scaleX(1);
      transition: transform 0.1s linear;
    }
    & .button {
      min-width: 100px;
    }
  }
}

.button {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: spacing(0.75) spacing(0.75) spacing(0.75) spacing(2);
  border-radius: 24px;
  white-space: nowrap;
  line-height: 20px;
  text-align: center;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 8px 48px 0px rgba(0, 0, 0, 0.06);

  &Dragging {
    opacity: 0.3;
  }

  &:hover .options {
    transform: scaleX(1);
    transition: transform 0.1s linear;
  }

  &:focus {
    outline: none;
  }

  &Icon {
    width: 17px;
    position: relative;
    margin: 0px 5px 0 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      height: 17px;
    }
  }

  &Label {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &IconContactDetails svg {
    position: relative;
    top: 2px;
    fill: none;

    g {
      path:first-child,
      circle {
        stroke: var(--black);
        fill: none;
      }
    }
  }
}

.options {
  display: flex;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: none;
  transform: scaleX(0);
  transition: transform 0.1s linear;
  background: var(--white);
  border-radius: 30px;

  overflow: hidden;

  &Buttons {
    display: flex;
    max-width: 80%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &Item {
      opacity: 0.35;

      &:hover {
        opacity: 1;
      }
    }
  }
}
