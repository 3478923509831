.checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  appearance: none;
  background-color: var(--white);
  border: 1px solid var(--grayscale50);
  border-radius: var(--border-radius-small);
  cursor: pointer;

  &:hover:not(:disabled, :checked) {
    background-color: var(--grayscale5);
  }

  &:disabled {
    border-color: var(--grayscale30);
    cursor: not-allowed;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    height: 8px;
    width: 4px;
    rotate: 45deg;
    translate: -50% -60%;
    scale: 0;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transition: scale 0.2s;
  }

  &:checked {
    background-color: var(--blue-ribbon);
    border-color: var(--blue-ribbon);

    &:disabled {
      background-color: var(--grayscale30);
      border-color: var(--grayscale30);
    }

    &::after {
      scale: 1;
    }
  }
}
