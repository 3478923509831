@import 'frontend/styles/functions';

.title {
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);

  &Wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: spacing(2);
  }
}

.images {
  margin-top: spacing(3);
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.accordionsWrapper {
  margin-top: spacing(3);

  > div {
    padding-block: spacing(1.5);
    border-top: 1px solid var(--border-color-base);

    &:last-child {
      border-bottom: 1px solid var(--border-color-base);
    }
  }
}
