@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.greetingsList {
  position: relative;
}

.formErrors * {
  color: var(--white);
}

.buildPanel {
  padding: 0;
}
