@import '../../styles/variables';

.forgotPassword {
  min-width: 360px;
}

.btnContainer {
  a {
    color: var(--white);
    float: right;
  }
}

.submitButton {
  margin-left: auto;
  display: block;
}
.loginButton {
  margin-right: auto;
}
