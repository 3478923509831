@import 'frontend/styles/functions';

.connectTable > div {
  grid-gap: spacing(1);
  padding-bottom: spacing(3);
}

.urlInput {
  width: 100%;
}

.integerInput {
  max-width: 300px;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
