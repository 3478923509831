@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/features/Inbox/inbox-styles';

.wrapper {
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: spacing(1);
  padding: spacing(1.5) spacing(2) spacing(1);
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  color: var(--text-grayscale90);
  text-decoration: none;
  cursor: pointer;
  margin: spacing(2) spacing(1) spacing(2) spacing(0.5);
  border-radius: var(--border-radius-large);

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: calc(100% - 9px);
    left: 0px;
    border-radius: var(--border-radius-large);
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    transition: all ease-in-out 100ms;
  }

  &:hover:before {
    left: -4px;
    background-color: var(--grayscale30);
    transition: all ease-in-out 100ms;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &WithLabelsOrAction {
    grid-template-rows: 1fr auto auto auto;
  }
}

.active {
  &:before {
    left: -4px;
    background-color: var(--blue-ribbon);
    transition: all ease-in-out 100ms;
  }

  &:hover:before {
    background-color: #003d94;
  }
}

.textContainer {
  overflow: hidden;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .title {
    margin: spacing(0.5) 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);
  }

  .message {
    font-size: 14px;
    max-width: 400px;
    line-height: 20px;
    margin: 0px;
    color: var(--text-grayscale70);
  }
}

.metaContainer {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: spacing(2);
  color: var(--text-grayscale70);
  overflow: hidden;
  font-size: 12px;

  .time {
    font-size: 12px;
    color: var(--text-grayscale70);
    white-space: nowrap;
    font-weight: var(--font-weight-medium);
  }

  .agentAvatar {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: spacing(0.5);

    img {
      width: 16px;
      height: 16px;
    }
  }

  .avatar {
    margin-right: 0px;
    position: relative;
    width: 24px;
    height: 24px;

    img {
      width: 12px;
      height: 12px;
    }
  }
}

.labelsContainer {
  display: flex;
  flex: 1;
  border: none;
  background: none;
  padding: 0;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: spacing(1);
  cursor: pointer;

  .chatStatus {
    color: var(--text-grayscale90);
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    padding: spacing(0.5) spacing(1);
    background: var(--grayscale5);
    border-radius: var(--border-radius-small);
    white-space: nowrap;
  }

  .tooltipWrapper {
    display: inline;

    .hiddenLabels {
      color: var(--grayscale70);
      font-size: 12px;
      font-weight: var(--font-weight-medium);
      padding: spacing(0.5) spacing(1);
      background: var(--grayscale5);
      border-radius: var(--border-radius-small);
    }
  }
}
