@import 'frontend/styles/functions';

.sectionGrid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: auto;
  gap: spacing(2);
}

.enableStep {
  justify-self: end;
}

.sentimentButtonsDescription {
  font-size: var(--font-size-small);
  color: var(--grayscale70);
}

.sentimentImages {
  display: flex;
  margin-top: spacing(1);
  gap: spacing(1);
}

.sentimentImageWrapper {
  display: flex;
  width: 32px;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-medium);
  user-select: none;

  img {
    width: 60%;
    aspect-ratio: 1;
  }

  &[data-selected='false'] {
    opacity: 0.4;
    box-shadow: 0 0 0 1px var(--grayscale15);
  }

  &[data-selected='true'] {
    box-shadow: 0 0 0 1px var(--primary-color);
    background-color: var(--grayscale10);
  }
}

.useSentimentButtonsLabel {
  margin-block: spacing(1) 0;
  font-size: var(--font-size-small);
  color: var(--grayscale70);
}

.fakeButtonsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: spacing(1);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: attr(data-buttons-number) '/5';
    font-size: var(--font-size-small);
    color: var(--grayscale90);
  }
}

.addButton {
  all: unset;
  outline: revert;

  display: flex;
  gap: spacing(0.5);
  align-items: center;
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  cursor: pointer;

  svg {
    font-size: 16px;
  }
}

.previewCard {
  align-self: center;
}

.previewCardButtons {
  display: flex;
  gap: spacing(1);
  justify-content: center;
  flex-wrap: wrap;
}

.previewCardButton {
  padding: spacing(1) spacing(2);
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--secondaryButtonTextColor-1729529624);
  background-color: var(--secondaryButtonBackgroundColor-1729529624);
  box-shadow: var(--box-shadow-card);
}
