@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.newSample {
  font-size: 14px;

  &:hover,
  &:focus-within {
    background-color: var(--grayscale10);
  }

  &Error {
    border-color: var(--red-pomegranate) !important; // overwrites Composer style
  }

  > div:first-child {
    padding: 10px;
  }

  :global(.public-DraftEditorPlaceholder-root) {
    width: auto;
  }
}

.errorMessage {
  top: spacing(-4);
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: var(--font-weight-medium);
  font-size: 14px;
}

.wordCountWrapper {
  display: flex;
  align-items: center;
  gap: spacing(0.5);
}

.iconWarning {
  font-size: 20px;
}

.wordCount {
  font-size: var(--font-size-small);
  color: var(--_word-counter-color);
}
