@import 'frontend/styles/functions';

.feedbackWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-level1);
  & .feedbackHeaderContainer {
    display: flex;
    flex-direction: column;
    padding: spacing(2) spacing(2.5);
    gap: spacing(2);
    & .feedbackHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacing(1);
      color: var(--text-grayscale90);
      & .feedbackRating {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-large);
        line-height: 24px;
      }
    }

    & .feedbackText {
      font-size: var(--font-size-normal);
      color: var(--text-grayscale70);
      font-style: italic;
      padding-left: spacing(0.25);
      padding-top: spacing(0.5);
    }

    & .feedbackButtons {
      display: flex;
      flex-direction: row;
      gap: spacing(1);
      flex-wrap: wrap;
      & .feedbackButton {
        padding: spacing(0.75) spacing(1.5);
        border-radius: var(--border-radius-medium);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-medium);
        color: var(--text-grayscale90);
        border: 1px solid var(--grayscale15);
        box-shadow: var(--box-shadow-button);
      }
    }
  }

  & .feedbackFooterContainer {
    border-top: 1px solid var(--grayscale15);
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-small);
    line-height: 18px;
    padding: spacing(1) spacing(2.5);
    gap: spacing(3);
    & .feedbackTriggered {
      display: flex;
      flex-direction: row;
      padding: spacing(1);
      gap: spacing(0.25);
      & .feedbackTriggeredTitle {
        font-weight: var(--font-weight-450);
        color: var(--text-grayscale70);
      }

      & .feedbackTriggeredText {
        font-weight: var(--font-weight-medium);
        color: var(--text-grayscale90);
      }
    }
  }
}
