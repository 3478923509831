@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;

  &:hover .editIcon {
    visibility: visible;
  }

  &:focus .editIcon {
    visibility: visible;
    pointer-events: auto;
  }
}

.editIcon {
  font-size: 17px;
  margin-left: 5px;
  align-self: center;
  opacity: 0.8;
  visibility: hidden;
}

.label {
  min-width: 100%;
  font-size: 14px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    border-left: 5px solid rgba(51, 51, 51, 0.3);
    transition: border-left 100ms ease-in-out;
  }

  &:focus {
    outline: none;
    border-left: 5px solid rgba(51, 51, 51, 0.3);
  }

  &Editable {
    min-width: calc(100% - 22px);
  }

  &Selected {
    border-left: 5px solid rgba(51, 51, 51, 0.3);
    transition: border-left 100ms ease-in-out;
  }
}
.checkIcon {
  z-index: 2;
  color: var(--grayscale70);
}
