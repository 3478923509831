@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.inputContainer {
  width: 50%;
  margin: spacing(4) 0;
  @media (max-width: $desktop-small) {
    width: 100%;
  }
  & > .fieldDescription {
    max-width: none;
  }
}

.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > label:last-child {
    margin-left: spacing(1);
  }
}

.fieldDescription {
  max-width: 50%;
  color: var(--grayscale80);
  font-size: var(--font-size-small);
  @media (max-width: $desktop-small) {
    max-width: 100%;
  }
  & p {
    font-size: var(--font-size-small);
  }
}

.editGreeting {
  label {
    font-weight: var(--font-weight-medium);
    margin-bottom: 0;
  }

  .slider {
    margin: spacing(2) 0;
  }
}

.chatbubbleSettingsWrapper {
  position: relative;
  padding: 0 spacing(3);
}

.buildPanelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & .buildPanelTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    & > svg {
      font-size: 22px;
    }
  }

  & .formSubmitBtn {
    margin-left: spacing(1);
  }
}
