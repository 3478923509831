@import 'frontend/styles/functions';

.panelMenuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigationElContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(0.5);
  overflow: hidden;
  justify-content: start;
  min-width: 162px;
  &.minimized {
    min-width: 0;
    justify-content: center;
  }
  &.kindlyManager {
    color: var(--pink-heliotrope);
  }
  &.navigationContainerMinimize {
    &:hover {
      color: var(--primary);
    }
    & > svg {
      font-size: 24px;
    }
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    color: var(--text-grayscale80);
  }
}
