@import '../../../../styles/variables';

.gauge {
  position: relative;
  display: inline-block;

  .value {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  circle {
    stroke-dasharray: 219;
    stroke-dashoffset: 219;

    &.gaugeBackground {
      stroke: var(--grayscale5);
    }
    &.gaugeSection {
      stroke: var(--blue-ribbon);
      stroke-dashoffset: 437;
    }
  }
}
