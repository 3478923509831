@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.instructionLink {
  color: var(--blue-ribbon);
  text-decoration: underline;
  cursor: pointer;
}
.instructions {
  section {
    margin-bottom: 30px;
  }
}
.saveBtn {
  margin-top: 10px;
  margin-bottom: 30px;
}

.s3settings {
  margin-top: 30px;
}

.container {
  padding-block: spacing(2);
}
.status {
  margin-left: 10px;
}
.failMessage {
  font-weight: 600;
}

.toggleEnabled {
  display: flex;
  align-items: center;
  margin-bottom: spacing(2);

  label {
    margin-left: spacing(1);
  }
}
