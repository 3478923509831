@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.showDetails {
  display: flex;
  padding: 0;
  align-items: center;
  font-weight: var(--font-weight-medium);
  font-size: inherit;
  background: none;
  border: none;
  cursor: pointer;

  @include noOutline;
}

.triangle {
  margin-left: spacing(0.5);

  &.rotateTriangle {
    transform: rotate(180deg);
  }
}

.radioField {
  display: none;
}

.customRoleButton {
  border-radius: var(--border-radius-small);
  background-color: var(--grayscale70);
  color: var(--white);
  padding: 10px 15px 10px;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  margin-bottom: 0;

  &Hidden {
    display: none;
  }
}
