@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/features/Inbox/inbox-styles';

.comments {
  height: 100%;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &Inner {
    overflow-y: scroll;
    padding: 0 spacing(2) spacing(2);
    margin: 0 spacing(0.5) spacing(0.5) 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &NewLink {
    text-decoration: underline;
    font-size: 16px;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
