@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.paginationWrapper {
  margin: 10px;
  text-align: center;
}

.organizationsFilter {
  max-width: 240px;
  margin-bottom: spacing(1);
}
.header {
  margin-bottom: spacing(2);
}
.search {
  display: inline-block;
  input {
    max-width: 330px;
  }
}
.filters {
  margin-bottom: spacing(2);
}
