@import 'frontend/styles/functions';

.statsPanel {
  padding: spacing(2);
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: auto;
    box-shadow: var(--box-shadow);
  }

  &NoPadding {
    padding: 0;
    justify-content: space-between;
  }
}

.statsContainer {
  padding: spacing(2);

  &NoPadding {
    padding: 0;
  }
}
