@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.paginationWrapper {
  margin-top: spacing(2);
}

.searchInput {
  max-width: 300px;
  margin: 0;
  margin-left: auto;
}

.actionsHead {
  margin-left: auto;
}

.filterWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: spacing(2);
  margin-bottom: spacing(2);

  &Webhook {
    grid-template-columns: auto;
  }
}

.buttonsFilterButton {
  background: var(--grayscale30);
  padding: spacing(1) spacing(2);
  border-radius: var(--border-radius-small);
  cursor: pointer;

  svg {
    position: relative;
    top: 1px;
    margin-right: spacing(0.5);
  }
}
