@import 'frontend/styles/functions';

.newFiltersWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  & .checkboxLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    & .iconTooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: spacing(1);
  & .filtersListFilter {
    padding: spacing(1);
    display: flex;
    flex-direction: column;
    background-color: var(--grayscale10);
    border-radius: var(--border-radius-large);
    gap: spacing(1);
    & .filtersListFilters {
      font-size: var(--font-size-small);
      color: var(--text-grayscale70);
      font-weight: var(--font-weight-normal);
      border-radius: var(--border-radius-large);
      padding: spacing(0.5);
      background-color: var(--grayscale30);
      word-break: keep-all;
    }
    & .filtersListFilterHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: var(--font-size-small);
      color: var(--text-grayscale70);
      font-weight: var(--font-weight-normal);
    }
  }
}

.filtersModalBody {
  max-width: 70%;
  & .filtersTable {
    max-height: 65vh;
    overflow-y: auto;
  }
}

.filtersSearch {
  margin-bottom: spacing(2);
  max-width: 320px;
}

.filterHeaderTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(0.5);
  .filtersTooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-small);
  }
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: spacing(1.5);
  & .applyFilter {
    font-size: var(--font-size-small);
    color: var(--text-grayscale70);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      color: var(--text-grayscale90);
    }
  }
}
