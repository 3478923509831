@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.tableContainer {
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow-card);

  &Nested {
    box-shadow: none;
  }
}

.table {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;

  & .tableFooter {
    display: flex;
    flex-direction: row;
    padding: spacing(1) spacing(2);
    grid-column: 1/4;
    border-top: 1px solid var(--grayscale30);
  }

  &.rightAlignLastColumn {
    .cell:last-child,
    .cellHeader:last-child {
      margin-left: auto;
    }
  }

  .row {
    display: contents;

    &Break {
      height: 1px;
      width: 100%;
      background-color: var(--grayscale30);
    }

    .cell {
      padding: spacing(1) spacing(2);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      min-height: spacing(6);
      word-break: break-all;

      &:last-child {
        padding-right: spacing(3);
      }

      &:first-child {
        padding-left: spacing(3);
      }

      &Header {
        background: none;
        margin: 0;
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-medium);
        padding-top: spacing(2);
        padding-bottom: spacing(2);
      }
    }

    &DarkensOnRowHover:hover > .cell {
      background-color: var(--grayscale5);
    }
  }
}

.pagination {
  &Wrapper {
    padding: spacing(1) spacing(2);
    border-top: 1px solid var(--grayscale5);
    display: grid;
    grid-auto-flow: column;
    grid-gap: spacing(2);
  }

  &Summary {
    text-align: right;
    align-self: center;
    margin-right: spacing(1);
  }
}

.ghostLoaderWrapper {
  padding: 0 spacing(3);
}
