.animated {
  position: fixed;
  left: 0px;
  height: 100%;
  z-index: 2;
  border-right: 2px solid #f1f1f1;
}

@keyframes show {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes hide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.show {
  animation: show 0.2s cubic-bezier(0.2, 0.2, 0.2, 0.9) forwards;
}

.hide {
  animation: hide 0.2s ease forwards;
}
