@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.pluginContainer {
  margin-bottom: 10px;

  .pluginInner {
    border: 1px solid var(--grayscale20);

    .pluginFooter {
      display: flex;
      justify-content: space-between;

      .pluginDescription {
        margin-top: 4px;
      }
    }
  }

  p {
    color: var(--text-grayscale70);
  }
}
.deleteIcon {
  position: relative;
  top: 4px;
  width: spacing(6);
}
