@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.formErrors * {
  color: var(--white);
}

.chatbubbleSettingsWrapper {
  position: relative;
  padding: 0 spacing(3);
}

.inputContainer {
  width: 50%;
  margin: spacing(4) 0;
  @media (max-width: $desktop-small) {
    width: 100%;
  }
}

.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > label:last-child {
    margin-left: spacing(1);
  }
}

.buildPanelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & .buildPanelTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    & > svg {
      font-size: 22px;
    }
  }

  & .formSubmitBtn {
    margin-left: spacing(1);
  }
}

.fieldDescription {
  color: var(--grayscale80);
  font-size: var(--font-size-small);
  @media (max-width: $desktop-small) {
    max-width: 100%;
  }
  & p {
    margin: 0;
    font-size: var(--font-size-small);
  }
}
