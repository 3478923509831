@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: spacing(0.5) spacing(0.5) spacing(0.5) 0;
  padding: spacing(3) spacing(1) spacing(2) spacing(2);
  overflow: auto;

  h3 {
    line-height: 17px;
    margin: 0 0 10px;
  }
}

.pluginReplies,
.messageLines {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.pluginReplies {
  .repliesSeperator {
    padding: 15px 0;

    &:before {
      content: '';
      width: 1px;
      height: 30px;
      margin-left: 15px;
      display: block;
      background-color: var(--grayscale20);
    }
  }
}

.previewBubble {
  &HasButtons {
    padding-bottom: 40px !important;
  }
}

.buttonWrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  grid-gap: 6px;
  position: relative;
  top: -30px;
  margin-bottom: -20px;
  margin-left: 15px;
}

.button {
  text-transform: none;
  min-width: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
