@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.clusterItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: spacing(2) spacing(1) 0 0;

  &Wrapper {
    @include noOutline;
    display: grid;
    grid-template-columns: spacing(6) auto;
    padding: 0;
    border-bottom: 1px solid var(--grayscale30);

    &:hover {
      cursor: pointer;
      background-color: var(--grayscale5);
    }

    &:last-child {
      border-bottom: none;
    }

    &Count {
      word-break: keep-all;
    }

    .index {
      font-size: var(--font-size-h4);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Selected {
      background: var(--grayscale5);

      .clusterItemCount {
        color: var(--blue-ribbon);
      }

      .token {
        border-color: var(--grayscale30);
      }
    }
  }

  .token {
    border: 1px solid var(--grayscale30);
    border-radius: var(--border-radius-small);
    padding: 4px;
    word-break: keep-all;
    margin: 0 spacing(2) spacing(2) 0;
  }
}
