@import '../../../styles/functions';

.container {
  position: relative;
}

.panelHeader {
  padding-top: spacing(1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLinkTab {
  a {
    margin-right: spacing(1);
  }
}

.separator {
  padding: spacing(3) 0;
}
