@import 'frontend/styles/variables';

.languagePicker {
  flex: 1;
  position: relative;

  & .languagePickerSelectorWrapper {
    &::before {
      display: none;
    }
  }

  & .languageIcon {
    left: 8px;
    top: 50%;
    transform: translateY(-42%);
    position: absolute;
    z-index: 1;
    pointer-events: none;
    user-select: none;
    & > svg {
      & path {
        stroke: var(--grayscale80);
      }
    }
  }

  & .selectIcon {
    right: 8px;
    top: 50%;
    transform: translateY(-46%);
    position: absolute;
    z-index: 1;
    pointer-events: none;
    user-select: none;
  }

  select {
    font-size: 14px;
    border-color: var(--grayscale5);
    color: var(--grayscale80);
    border-radius: var(--border-radius-large);
    font-weight: var(--font-weight-medium);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 32px;
  }
}
