@import 'frontend/styles/functions';

.icon {
  &Wrapper {
    width: spacing(2);
    height: spacing(1);

    &Info {
      display: flex;
      width: 20px;
      height: 20px;
    }
  }

  &:after {
    left: 5px;
    top: 0;
  }
}
