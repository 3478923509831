@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import '../../Build';

.pageContent {
  height: calc(100vh - 105px);
  padding: spacing(0);
  overflow: hidden;

  .pageBar {
    position: relative;
    top: 0 !important;
    padding: 0;
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 280px 2px fit-content(550px);
  height: calc(100% - 69px);
}

.content {
  overflow: auto;
  position: relative;
  padding: spacing(3);
  margin: spacing(0.5) spacing(0.5) spacing(0.5) 0;
}

.previewContainer {
  margin-top: 2px;

  h3 {
    margin-top: 0;
  }
}
