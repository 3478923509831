@import 'frontend/styles/functions';

.labelTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-grayscale90);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-weight: var(--font-weight-450);
  font-size: var(--font-size-medium);

  & .labelTitleColorsWrapper {
    margin-right: spacing(0.5);
    height: 100%;

    & .labelTitleColor {
      padding: 6px;
      border-radius: 6px;
      position: absolute;
      left: spacing(1);
      top: 50%;

      & .labelMarker {
        width: 6px;
        height: 6px;

        &.circle {
          border-radius: 50%;
        }

        &.square {
          border-radius: 0;
        }
      }
    }
  }
}
