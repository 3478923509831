@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.accordion {
  padding: 25px 10px;
  border-top: 1px solid var(--border-color-base);

  &:last-child {
    border-bottom: 1px solid var(--border-color-base);
  }

  &Header {
    display: grid;
    grid-template-columns: 30px 1fr auto;
    align-items: center;
    cursor: pointer;
    user-select: none;
    @include noOutline;

    &:hover {
      color: var(--blue-ribbon);
    }
  }

  &Title {
    position: relative;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
  }

  &Content {
    display: none;
    margin: 20px 0 10px 31px;
    &Open {
      display: block;
    }

    .radio {
      padding: 5px 0;
      font-weight: 500;
    }
  }
}

.back {
  padding-left: 0;
  svg {
    font-size: 24px;
    margin-right: 0;
  }
}

.filterWrapper {
  height: 100%;
  background-color: var(--white);
  width: auto;
  min-width: 431px;
  position: relative;
  z-index: 2;

  &::before {
    position: absolute;
    content: '';
    bottom: 100%;
    width: 100%;
    height: 600px;
    background-color: var(--white);
  }

  @media (max-width: $tablet-wide) {
    min-width: 290px;
  }
}

.header {
  padding: 50px spacing(3) 20px spacing(3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-weight: 500;

  @media screen and (max-width: $tablet-wide) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content {
  overflow: auto;
  padding: 15px spacing(3);
  height: calc(100vh - 193px);
  margin-right: spacing(0.5);
  scrollbar-gutter: stable;

  @media screen and (max-width: $tablet-wide) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
