@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.selectLanguageContainer {
  margin-bottom: spacing(2);
  position: absolute;
  top: spacing(2);
  right: spacing(2);
}

.toggleSwitchWrapper {
  margin-block: spacing(3);
}

.previewTitle {
  padding: spacing(1) 0;
  font-weight: var(--font-weight-medium);
}
