@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

$analytics-dot-size: 10px;
$analytics-column-space: spacing(2);

:export {
  /* stylelint-disable */
  primaryColor: var(--primary-color);
  secondaryColor: var(--text-grayscale70);
  supportiveColor: var(--supportive-color);
  successColor: var(--success-color);
  /* stylelint-enable */
}

%dot {
  width: $analytics-dot-size;
  height: $analytics-dot-size;
  border-radius: 50%;
}

%primaryDot {
  @extend %dot;
  background-color: var(--primary-color);
}
%secondaryDot {
  @extend %dot;
  background-color: var(--text-grayscale70);
}

.datepickerHeaderGrid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin-bottom: $analytics-column-space;
  grid-gap: 20px;
  align-items: center;

  &WithLanguageCodes {
    grid-template-columns: 2fr 2fr 2fr;
  }

  @media (max-width: $tablet-wide) {
    grid-template-columns: 100%;
  }
}

.hoverBox {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 20px var(--shadow-color);
  }
}

.inboxBridgeBox {
  padding: spacing(4);
}

.statsPanel {
  max-width: 250px;
}

.modal {
  max-width: 1400px;
}
