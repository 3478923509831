@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.notificationsContainer {
  border-radius: var(--border-radius-large);
}

.notificationsList {
  text-align: left;
}

.notificationItem,
.markAsRead {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.notificationItem {
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--grayscale5);
  padding: spacing(1);
  margin: 0 spacing(1);
  justify-content: space-between;
  position: relative;

  &Read {
    opacity: 0.4;
  }
  &Unread:before {
    content: '';
    position: absolute;
    top: 15px;
    left: -1px;
    width: 4px;
    height: 4px;
    background: var(--red-pomegranate);
    border-radius: 50%;
  }
}

.timestamp {
  font-size: 12px;
  font-weight: var(--font-weight-normal);
}

.markAsRead {
  padding: 0;
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  font-family: var(--font-family);
  border-bottom: 0;
  margin-left: auto;
}

.notificationsBell {
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--grayscale5);
  }

  &Active .badge {
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 1;
    padding: 5px;
    border-radius: 50%;
    background-color: var(--red-pomegranate);
  }
}
.bellIcon {
  position: relative;
  top: 2px;
  color: var(--grayscale70);
}

@keyframes ring-animation {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

.bellAnimation {
  display: inline-block;
}

.bellAnimation.ring {
  animation: ring-animation 0.5s ease-in-out forwards;
}
