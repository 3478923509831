@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.globalIconModalWrapper {
  max-width: 433px;
}

.globalIconModalImageWrapper {
  display: flex;
  flex-direction: column;

  & .globalIconModalImageDescription {
    color: var(--grayscale70);
    font-size: var(--font-size-small);
    margin-top: spacing(1);
  }

  & .globalIconModalImageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: spacing(3);
    & .globalIconModalBtn {
      margin-left: spacing(2);
    }
    & .globalIconModalImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.globalIconBtnAction {
  margin-top: spacing(1);
}

.selectWithSearch {
  height: 36px;
}
