@import 'frontend/styles/functions';

.pagination {
  padding: spacing(1) spacing(2);
}

.infoButton {
  background-color: var(--grayscale5);
  margin-left: spacing();

  &:hover {
    background-color: var(--grayscale5);
  }
}
