@import '../../../../styles/variables';
@import '../../../../styles/functions';

.listContainer {
  margin-bottom: spacing(6);

  > a {
    color: inherit;
    text-decoration: none;

    > div {
      margin-bottom: spacing(2);
    }
  }
}
