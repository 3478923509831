@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrap {
  margin-top: spacing(1);
}

.editor {
  outline: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  resize: none;
  width: 100%;
  font-size: var(--font-size-medium);
  line-height: 1.55;

  border-radius: 2px;
  padding: spacing(1);
  border: 2px solid var(--grayscale20);

  &:focus {
    border-color: var(--blue-ribbon);
  }
}

.info {
  padding-top: spacing();
  color: var(--grayscale70);
}

.preview {
  min-height: spacing(12);
  border-radius: 2px;
  padding: spacing(1);
  border: 2px solid var(--grayscale20);

  p {
    font-size: var(--font-size-medium);
    margin: 0;
    line-height: 1.55;
  }
}
