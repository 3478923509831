@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  --labels-section_list-padding-inline: #{spacing(1.5)};
}

.inputContainer {
  padding: spacing(0.5);
}

.emptyState {
  padding-block: spacing(2);
}

.input {
  input {
    padding-left: spacing(4);
    font-size: var(--font-size-medium);
    background-color: var(--grayscale5);
    border: 2px solid var(--grayscale5);
    border-radius: var(--border-radius-large);
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--grayscale10);
      border-color: var(--grayscale10);
    }

    &:focus {
      outline: none;
      border-color: var(--primary-color);
      background-color: var(--white);
    }
  }

  svg {
    left: 12px;
  }
}

.infoBox {
  margin-top: spacing(3);
}

.editLabelButton {
  margin-left: auto;
  color: var(--grayscale70);
  flex-shrink: 0;

  svg {
    color: var(--grayscale70);
  }
}
