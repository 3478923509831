@import 'frontend/styles/variables';

.statusIcon {
  display: flex;
  align-items: center;

  span {
    padding-left: 5px;
  }
}

.paginationWrapper {
  margin: spacing(2) spacing(1);
  text-align: center;
}
