@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.rowPanel {
  padding: spacing(2);
  border-bottom: 1px solid var(--grayscale5);
}

.content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 245px;
  grid-gap: spacing(1);
}
