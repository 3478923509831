@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.listWrapper {
  display: flex;
  max-height: calc(100vh - 100px);
  padding: spacing(0.5) 0;
  flex-direction: column;
  background-color: var(--white);
}

.headerWrapper {
  margin: 0;
  padding: spacing(1) spacing(2);
}

.listHeader {
  margin: 0;
  padding: 0;
  top: 0;
  position: sticky;
  border-bottom: none;

  .title {
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-normal);
  }

  path {
    stroke: var(--text-grayscale90);
  }
}

.listItemsWrapper {
  overflow: auto;
}

.infoMenuItem {
  padding: 0;
  display: block;
  cursor: pointer;

  a,
  span {
    padding: spacing(1) spacing(2);

    .iconLink {
      display: block;
      float: left;
      width: 0.7em;
      height: 0.7em;
      margin-right: spacing(0.5);
    }
  }

  .workspaceAvatar {
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius-large);
    object-fit: cover;
  }

  &.listSubHeader {
    pointer-events: none;
    border-bottom: 1px solid var(--grayscale15);

    .title {
      color: var(--grayscale70);
      font-size: var(--font-size-small);
    }
  }
}

.infoMenuItem,
.userMenuItem {
  font-size: 14px;
  text-decoration: none;
  text-align: left;
  margin: 2px 0;
  border-bottom: unset;

  &:focus {
    @include noOutline;
  }

  &:hover {
    background-color: var(--grayscale0);
  }
  &:active a,
  &:active {
    color: var(--blue-ribbon);
    background-color: var(--grayscale10);
  }
  a,
  span {
    color: var(--text-grayscale90);
    display: flex;
    text-decoration: inherit;

    &:hover {
      text-decoration: none;
    }
  }
  &:not(.kindlyAdminManager) {
    span {
      color: inherit;
    }
  }

  &:global(.listWorkspaceItem) span {
    align-items: center;
    gap: spacing(2);
  }

  .title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.menuFooter {
  text-align: center;
  color: var(--grayscale50);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 0px 10px;
  padding: spacing(1) spacing(1.5);
  text-align: left;

  .avatar {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    object-fit: cover;
  }

  .name {
    grid-column: 2;
    grid-row: 1;
    font-size: var(--font-size-large);
    line-height: 150%;
    margin-bottom: 2px;
  }

  .link {
    grid-column: 2;
    grid-row: 2;
    font-size: var(--font-size-small);
    line-height: 150%;
    font-weight: var(--font-weight-normal);
  }
}

.divider {
  height: 1px;
  width: 100%;
  background: 1px solid var(--grayscale15);
  margin: 2px 0;
}
