@import 'frontend/styles/functions';

.container {
  min-width: 160px;
  margin-block: spacing(0.5);
  color: var(--grayscale80);
}

.button {
  all: unset;

  display: flex;
  width: 100%;
  padding: spacing(0.5) spacing(1.5);
  gap: spacing(0.5);
  align-items: center;

  &:enabled {
    color: var(--grayscale80);
    cursor: pointer;

    &:hover {
      background-color: var(--grayscale0);
      color: var(--grayscale90);
    }

    &:active {
      color: var(--blue-ribbon);
    }
  }

  &:disabled {
    color: var(--grayscale60);
  }

  &:focus-visible {
    outline: revert;
  }
}

.icon {
  font-size: 16px;
}
