@import 'frontend/styles/functions';

.activeFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-bottom: none;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  flex: initial;
  & .activeIconWrapper {
    display: flex;
    align-items: center;
    flex: initial;
    & svg {
      margin: spacing(0.5) spacing(0.75);
    }
  }

  & .buttonWrapper {
    display: flex;
    align-items: center;
    & svg {
      margin: spacing(0.5) spacing(0.75);
    }
    & .horizontalDropdownButton {
      border: none !important;
      border-left: 1px solid var(--grayscale15) !important;
      border-radius: 0;
      height: 26px;
      padding: 0 spacing(1.5);
      &.horizontalDropdownButtonPinned {
        border-top-right-radius: var(--border-radius-medium);
        border-bottom-right-radius: var(--border-radius-medium);
      }
    }

    & .filterRemoveButton {
      padding: 0;
      border-left: 1px solid var(--grayscale15);
      border-radius: 0;
      height: 26px;
    }
  }
}

.trigger {
  padding: spacing(0.5) spacing(1);
}

.filtersDropdownOverlay {
  overflow: auto;
  width: max-content;
  max-width: 480px;
  & > * {
    padding: 0 spacing(2);
    margin-bottom: spacing(0.25);

    &:first-child {
      margin-top: spacing(1);
    }

    &:last-child {
      margin-bottom: spacing(1);
    }
  }
}
