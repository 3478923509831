@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.container {
  max-width: 220px;
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    aspect-ratio: 1;
  }
  &.isCarousel img {
    object-fit: cover;
    cursor: pointer;
  }

  &:focus {
    @include noOutline;
  }
}

.belowSection {
  background-color: white;

  .imageMetaWrapper {
    display: flex;
    flex-direction: column;
    padding: spacing(1);
  }

  .imageTitle {
    font-weight: var(--font-weight-medium);
  }

  .imageDescription {
    font-size: var(--font-size-small);
    color: var(--text-grayscale70);
  }

  .imageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(0.5) spacing(2.5);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    font-weight: var(--font-weight-medium);
    gap: spacing(1);

    &Label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      flex-shrink: 0;
      font-size: 18px;
    }
  }
}
