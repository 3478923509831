@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.editorContainer {
  width: 280px;
  margin: 30px auto 0;

  .chatContainer {
    width: 100%;
    height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    margin-bottom: spacing(2);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);

    * {
      box-sizing: border-box;
    }
  }

  .bubbleContainer {
    height: 45px;
    width: 45px;
    cursor: pointer;
    border-radius: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &Picker {
      float: right;
    }

    &Avatar {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: calc(50% - 3px);
      opacity: 1;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.2s ease;
    }
  }
}
