@import 'frontend/styles/functions';

.chatMessageClickable {
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  position: relative;
  gap: spacing(1);

  & .attachments {
    display: flex;
    &.attachmentsFromUser {
      align-self: flex-end;
    }
  }
  & .chatMessageHoverActionsWrapper {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: spacing(2);
    display: none;
    & .hoverActionButton {
      padding: spacing(0.25) spacing(0.5);
    }
  }
  &:hover {
    & .chatMessageHoverActionsWrapper {
      display: block;
    }
  }

  & .chatBubbleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.fromUser {
      align-self: flex-end;
    }
  }

  & .chatButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    max-width: 450px;
    &:not(.chatButtonWrapperHorizontal) > div {
      align-self: stretch;
      text-align: center;
    }
    &.chatButtonWrapperHorizontal {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
