@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.rowContainer {
  padding: spacing(2);
  display: grid;
  grid-template-columns: 1fr 1fr 103px;
  border-bottom: 1px solid var(--grayscale5);
  align-items: center;
}
.sampleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
