@import 'frontend/styles/functions';

.previewCard {
  width: 360px;
  padding: spacing(4) spacing(2);
  text-align: center;
  border-radius: 12px;
  color: var(--panelTextColor-1729529624);
  background-color: var(--panelBackgroundColor-1729529624);
  box-shadow: var(--box-shadow-card);
}

.previewCardHeading {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  margin-block: 0 spacing(3);
}
