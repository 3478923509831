@import 'frontend/styles/functions';

.modal {
  max-width: 800px;
}

.listHeader {
  display: grid;
  grid-template-columns: 1fr 90px;
  padding: 16px;

  span {
    font-weight: 500;

    &:last-child {
      text-align: right;
    }
  }
}

.dialoguesList {
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: 0px 1px 3px 0px var(--shadow-color-dark);

  .listElement {
    padding: 11px spacing(2);
    display: grid;
    grid-template-columns: 1fr 140px;

    &:not(:last-child) {
      border-bottom: 2px solid var(--grayscale5);
    }

    a {
      font-size: 14px;
      color: var(--blue-ribbon);
      text-decoration: none;
    }

    span:last-child {
      text-align: right;
    }
  }
}
