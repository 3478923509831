@import 'frontend/styles/functions';

.rightSidePanel {
  grid-area: panel;

  display: flex;
  width: clamp(280px, 20vw, 500px);
  flex-direction: column;
  font-size: var(--font-size-medium);
}

.header {
  display: flex;
  flex: 0 0 70px;
  justify-content: space-between;
  align-items: center;
  padding: spacing(2.2);
  font-size: var(--font-size-large);
  vertical-align: middle;
  border-left: 2px var(--grayscale5) solid;
  border-bottom: 2px var(--grayscale5) solid;
}

.heading {
  display: inline-flex;
  margin-block: auto;
  justify-content: center;
  align-items: center;
  gap: spacing(0.5);
  font-size: inherit;
  font-weight: var(--font-weight-medium);
  color: var(--grayscale90);
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-left: 2px var(--grayscale5) solid;
  overflow: auto;
}

.closeButton {
  padding: 0;
  color: var(--grayscale90);
  background-color: transparent;
  border: 1px var(--grayscale15) solid;
  border-radius: var(--border-radius-medium);
  cursor: pointer;

  svg {
    vertical-align: bottom;

    &:active {
      color: var(--blue-ribbon);
    }
  }

  &:hover {
    background-color: var(--grayscale10);
  }
}
