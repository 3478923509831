@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.header {
  font-weight: 500;
}

.description {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;

  a {
    width: fit-content;

    button {
      padding: 20px 0;
    }
  }
}

.table {
  tr:hover {
    background-color: var(--grayscale20);

    .optionsButton {
      opacity: 1;
    }
  }

  thead tr:hover {
    background-color: var(--white);
  }

  td {
    font-size: 14px;
    color: var(--text-grayscale90);
  }
}

.internalConsumer {
  font-style: italic;
}
.botApiKeysPanel {
  padding: 0;
  & .botApiKeysHeaderWrapper {
    padding: spacing(3);
  }
}
