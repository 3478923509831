@import 'frontend/styles/functions';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formInputs {
  display: flex;
  justify-content: space-evenly;
  gap: spacing(1);

  > * {
    min-width: 100px;
    flex: 1;
  }

  .prefix {
    flex: 0;
    margin-inline: 0;
    min-width: auto;
  }
}

.formTypeHeadContainer {
  display: flex;
  color: var(--grayscale80);
  align-items: center;
  gap: spacing(0.5);
  margin-bottom: spacing(1);
}

.trashIcon {
  margin-left: auto;
}

.inputWrapper input {
  width: 100%;
  border-radius: var(--border-radius-small);
}

.radioContainer {
  display: flex;
  gap: spacing(1);
  justify-content: space-evenly;
  flex-wrap: wrap;
}
