@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.settingsPanel {
  padding: 20px 15px;

  p {
    color: var(--text-grayscale70);
  }
}

.checkboxDescription {
  color: var(--text-grayscale70);
  margin-top: 0;
}

.warning {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-medium);
}
.warningIcon {
  position: relative;
  left: -2px;
  margin-right: spacing(0.5);
}
