@import 'frontend/styles/functions';

.checkboxOption {
  padding: 0 spacing(1.5) 0 spacing(2);
}

.groupTitle {
  margin-top: spacing(1);
  padding: spacing(0.5) spacing(1.5);
  color: var(--grayscale70);
}

.checkboxLabel {
  line-height: 20px;
  padding-top: spacing(0.5);
  padding-bottom: spacing(0.5);
}
