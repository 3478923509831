@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.connectorForm {
  max-width: $tablet;
  margin: auto;
  display: block;
  font-size: 13px;

  .formHeader {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: spacing(1);
    align-items: baseline;

    .headerButton {
      font-weight: var(--font-weight-medium);
    }
  }

  .formBody {
    top: calc(100% + 10px);
    padding: var(--gutter-medium) 40px var(--gutter-medium) var(--gutter-medium);
    background-color: #fff;
    box-shadow: 0 2px 7px 0 var(--shadow-color);
    color: var(--text-grayscale90);
    border-radius: var(--border-radius-small);
  }

  p {
    color: var(--text-grayscale70);
    font-size: 13px;
  }
}

.logoContainer {
  padding: spacing(2) 0;
  display: flex;
  align-items: center;

  .portrait {
    height: 60px;
    width: 60px;
    margin-right: spacing(3);
  }

  .upload {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 10px;
      font-weight: var(--font-weight-medium);
    }
  }
}

.editButton {
  font-weight: var(--font-weight-medium);
  font-size: 13px;
}
