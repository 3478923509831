@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: spacing(1);
  justify-content: center;
  align-items: start;

  @media (max-width: $phone) {
    grid-template-columns: 1fr;
  }
}
