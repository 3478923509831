@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonPanel {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-gap: 10px;

  &Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.identicalButtonsError {
  background: var(--red-pomegranate);
  color: var(--white);
  position: absolute;
  padding: 5px;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

div.contactDetails {
  label {
    padding: spacing(1) 0;
  }

  &Section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    div:first-child {
      width: 100%;
    }
  }

  &Type {
    display: flex;

    > div {
      white-space: nowrap;
      margin-left: spacing(2);

      &:first-child {
        margin-left: spacing(3);
      }
    }

    label {
      padding: 0;
      margin-bottom: 7px;
    }
  }
}
