@import 'frontend/styles/functions';

.messageTag {
  border-radius: 50px;
  margin-right: spacing(1);
  border: 1px solid var(--grayscale70);
  color: var(--grayscale70);
  font-size: var(--font-size-small);
  padding: spacing(0.25) spacing(1);
  white-space: nowrap;
  font-weight: var(--font-weight-normal);
}
