@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.label {
  font-weight: var(--font-weight-medium);
  margin: spacing(1) 0;
}

.horizontalLine {
  margin: spacing(4) 0;
}

.profileSettings {
  h3 {
    margin-bottom: spacing(2.5);
  }

  h4 {
    margin: spacing(2) 0 spacing(1);
  }

  .alerterDescription {
    font-weight: var(--font-weight-normal);
  }
}

.sectionWidthLimited {
  max-width: 640px;
  width: 100%;

  > div {
    max-width: 450px;
  }
}

.avatarField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .avatar {
    height: 100px;
    min-height: 100px;
    width: 100px;
    margin-right: spacing(2);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.notificationsByOrgItem {
  margin-bottom: spacing(2);

  h4 {
    margin-bottom: 0;
  }
}
