@import '../../../../styles/variables';
@import '../../../../styles/functions';

.container {
  position: relative;

  .skillContainer {
    display: flex;
    justify-content: space-between;

    .skillDescription {
      position: relative;
      padding-left: spacing(2) + 6px;

      &:before {
        content: '';
        left: 0;
        width: 6px;
        height: 100%;
        position: absolute;
        margin-right: spacing(2);
        border-radius: var(--border-radius-small);
        background-color: var(--blue-ribbon);
      }

      p {
        margin: 0;
        color: var(--black);

        &:first-child {
          font-weight: var(--font-weight-medium);
        }
      }
    }

    .skillStats {
      display: flex;
      align-items: center;
      .statContainer {
        width: auto;
        padding: 0 spacing(3);
        text-align: center;
        border-left: 1px solid var(--grayscale30);

        p,
        h3 {
          margin: 0;
        }
      }
    }
  }
}
