@import 'frontend/styles/functions';

.searchWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.results {
  padding-left: spacing(1);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.closeSearch {
  position: absolute;
  top: 10px;
  right: 5px;

  > svg {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
}

.emptyState {
  height: 100%;
  justify-content: center;
}

.emptyResult {
  padding: spacing(0.5) spacing(2) spacing(0.5) 2px;
  font-size: 14px;
  color: var(--grayscale70);
}

.resultsHeader {
  padding: spacing() spacing(2) spacing() 0;
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale70);
  display: flex;
  align-items: center;
}
.resultsHeaderNudge {
  padding-top: spacing(2);
}
.resultsHeaderIcon {
  font-size: 18px;
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.header {
  margin-top: spacing(2);
  .text {
    margin: 0;
  }
}

.body {
  margin: auto;
  max-width: 194px;
  margin-top: spacing(2);
  margin-bottom: spacing(2);

  .text {
    margin: 0;
    text-align: center;
  }
}

.loader {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
