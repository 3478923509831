@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

@mixin hoverState($bgColor: false, $color: false) {
  &:hover:not(:disabled) {
    transition: all 150ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition-property: background-color, color, border-color;

    @if ($bgColor) {
      background-color: $bgColor;
      border-color: $bgColor;
    }
    @if ($color) {
      & svg * {
        color: $color;
        stroke: $color;
      }
      color: $color;
      a {
        color: $color;
      }
    }
  }
}
@mixin activeState($bgColor: false, $color: false, $borderColor: false) {
  &:active:not(:disabled) {
    transition: all 50ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition-property: background-color, color, border-color;
    outline: none;

    @if ($bgColor) {
      background-color: $bgColor;
      border-color: $bgColor;
    }
    @if ($color) {
      color: $color;
    }
    @if ($borderColor) {
      border-color: $borderColor;
    }
  }
}

@mixin loadingIndicator($bgColor: var(--blue-ribbon), $dotColor: var(--white)) {
  .loadingIndicator {
    background-color: $bgColor;

    span {
      background-color: $dotColor;
    }
  }
}

@mixin linkAsChildren($color: var(--text-grayscale70)) {
  a {
    text-decoration: none;
    color: $color;
  }
}

.btn {
  border-radius: var(--border-radius-medium);
  transition: all 150ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition-property: background-color, color, border-color;
  border: none;
  user-select: none;
  font-family: var(--font-family);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  text-decoration: none;
  padding: spacing(1) spacing(2);
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    opacity: 0.7;
    width: 100%;
    height: 100%;
  }

  &HasIcon {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding-left: spacing(1);
    padding-right: spacing(1);
    vertical-align: bottom;
  }

  &HasIcon.btnHasText:where(:not(.btnFlat)) svg {
    margin-right: spacing(0.5);
    flex-shrink: 0;
  }

  a {
    text-decoration: none;
  }

  &:focus {
    @include noOutline;
  }

  // Sizes
  &Small {
    padding: 6px 12px 5px;
    font-size: 12px;
    line-height: 12px;

    &:not(.btnFlat) {
      min-width: 50px;
    }

    svg {
      top: 2px;
      margin-right: 3px;
    }
  }

  // Colors
  &Primary:not(.btnFlat):not(.btnDisabled) {
    background-color: var(--blue-ribbon);
    border: 1px solid var(--primary-color);
    color: var(--white);
    outline-offset: 4px; // for accessibility, blue on blue is undistinguishable
    @include hoverState(var(--blue-ribbon--hover));
    @include activeState(var(--blue-ribbon--active));
    @include loadingIndicator();
    @include linkAsChildren(var(--white));
  }

  &Secondary:not(.btnFlat):not(.btnDisabled) {
    background-color: var(--grayscale5);
    border: 1px solid var(--grayscale5);
    color: var(--text-grayscale90);

    @include hoverState(var(--grayscale10));
    @include activeState(var(--grayscale20));
    @include loadingIndicator(var(--grayscale20), var(--grayscale90));
    @include linkAsChildren(var(--text-grayscale90));
  }

  &Gray:not(.btnFlat):not(.btnDisabled) {
    background-color: var(--grayscale60);
    border: 1px solid var(--grayscale60);
    color: var(--white);

    & svg {
      color: var(--white);
      stroke: var(--white);
    }

    @include loadingIndicator();
  }

  &KindlyAdmin:not(.btnFlat):not(.btnDisabled) {
    color: var(--pink-heliotrope);
    @include hoverState(var(--pink-heliotrope--hover));
    @include activeState(var(--pink-heliotrope--active));
    @include loadingIndicator(var(--pink-heliotrope));
    @include linkAsChildren(var(--white));
  }

  &Warning:not(.btnFlat):not(.btnDisabled) {
    background-color: var(--red-pomegranate);
    border: 1px solid var(--red-pomegranate);
    color: var(--white);
    @include hoverState(var(--red-pomegranate--hover));
    @include activeState(var(--red-pomegranate--active));
    @include loadingIndicator(var(--red-pomegranate));
    @include linkAsChildren(var(--white));
  }

  &Idle:not(.btnFlat):not(.btnDisabled) {
    background: var(--primary-idle);
    border: 1px solid var(--primary-idle);
    color: var(--white);
    outline-offset: 4px; // for accessibility, blue on blue is undistinguishable
    @include hoverState(var(--blue-ribbon--hover));
    @include activeState(var(--blue-ribbon--active));
    @include loadingIndicator();
    @include linkAsChildren(var(--white));
  }

  &White {
    svg * {
      color: var(--grayscale90);
      stroke: var(--grayscale90);
    }
    &:not(.btnFlat):not(.btnDisabled) {
      color: var(--grayscale90);
      background-color: var(--white);
      border: 1px solid var(--grayscale15);
      border-radius: var(--border-radius-medium);
      &.btnActive {
        color: var(--blue-ribbon);
        svg * {
          color: var(--blue-ribbon);
          stroke: var(--blue-ribbon);
        }
      }
      @include hoverState(var(--grayscale10), var(--blue-ribbon));
    }
  }

  &Transparent {
    svg * {
      color: var(--grayscale90);
      stroke: var(--grayscale90);
    }
    &:not(.btnFlat):not(.btnDisabled) {
      color: var(--grayscale90);
      background-color: transparent;
      &.btnActive {
        color: var(--blue-ribbon);
        svg * {
          color: var(--blue-ribbon);
          stroke: var(--blue-ribbon);
        }
      }
      @include hoverState(var(--grayscale10));
    }
  }

  &Disabled:not(.btnFlat) {
    background-color: var(--white);
    border: 1px solid var(--grayscale30);
    color: var(--grayscale30);
    pointer-events: none;
  }

  &Flat {
    border: none;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }

    &.btnDisabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.btnWarning {
      &:hover svg * {
        color: var(--red-pomegranate);
        fill: var(--red-pomegranate);
      }
    }
    &.btnPrimary {
      &:hover svg * {
        color: var(--primary-color);
        stroke: var(--primary-color);
      }
    }
  }
  &Circle {
    padding-left: 0;
    padding-right: 0;
    min-width: 32px !important;
    max-width: 32px;
    border-radius: 50px;
    justify-content: center;

    .loadingIndicator {
      border-radius: 50px;
    }

    svg {
      margin: 0;
    }

    &:hover:not(:disabled) {
      svg {
        opacity: 1;
        path {
          stroke: var(--primary-color);
        }
      }
    }
  }
  &FullWidth {
    width: 100%;
  }
}

.loadingIndicator {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: center;
  border-radius: var(--border-radius-small);
  transition: all cubic-bezier(0.61, 0.1, 0.42, 0.9) 150ms;

  &Flat {
    span {
      background: var(--grayscale90);
    }
  }

  span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    opacity: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    // Safari 10.1+
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .btnSmall & {
          top: 12px;
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
