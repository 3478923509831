@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.emptyPanel {
  h1 {
    font-size: 24px;
    font-weight: var(--font-weight-medium);
    margin-bottom: spacing(2);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: spacing(6);
}

.nudgeIllustration {
  max-height: 130px;
  margin-left: spacing(2);
}

.cardContainer {
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 1fr 1fr;

  @media (max-width: $desktop-small) {
    grid-template-columns: 1fr;
  }
}
