@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.comment {
  display: grid;
  grid-template-columns: 32px auto;
  grid-template-rows: auto auto;
  grid-gap: 6px 30px;

  .avatar {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
  .name {
    margin: 0px;
    font-size: 12px;
    color: var(--text-grayscale70);
    grid-column: 2;
    grid-row: 1;
  }
  .time {
    margin-left: 15px;
  }
  .note {
    margin: 0;
    font-size: 14px;
    color: var(--text-grayscale90);
    grid-column: 2;
    grid-row: 2;
    line-height: 150%;
  }
}
