@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonContainer {
  margin-top: spacing(4);

  .saveButton {
    min-width: 72px;
  }

  button:last-child {
    margin-left: spacing(2);
  }
}
