@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.grid {
  position: fixed;
  top: 30px;
  bottom: 0;
  left: 0px;
  right: 0px;
  padding: spacing(3) spacing(2) spacing(3) spacing(2);
  display: grid;
  grid-template-columns: 1fr 6.5fr;
  grid-template-rows: auto;
  grid-gap: spacing(2);
  height: calc(100vh - 104px);
  z-index: 10;
  justify-content: center;

  @media screen and (max-width: $phone) {
    width: 400%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px 0px;
    grid-gap: 0;

    &.mobileScreen {
      left: 0%;
      transition: left 300ms linear;

      &Views {
        left: 0%;
        transition: left 300ms linear;
      }
      &List {
        left: -100%;
        transition: left 300ms linear;
      }
      &Conversation {
        left: -200%;
        transition: left 300ms linear;
      }
      &Meta {
        left: -300%;
        transition: left 300ms linear;
      }
    }
  }
}
