@import 'frontend/styles/functions';
.timeframesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(2);
  flex-wrap: wrap;

  & .timeframeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    & .deleteTimeframeIcon {
      margin-left: spacing(0.5);
    }
  }

  & .addBtn {
    padding: spacing(0.375);
    min-width: unset;
    display: flex;
    & svg {
      margin: 0;
    }
  }
}

.dateTimeInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  & input[type='time']::-webkit-calendar-picker-indicator,
  & input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.nameCell,
.actionsCell {
  align-items: flex-start !important;
  padding-top: spacing(1.5) !important;
  color: var(--text-grayscale70);
}

.dateCell {
  padding-left: spacing(1) !important;
}

.nameInput {
  border: none;
}

.statusCell,
.dateCell,
.nameCellSeasonal {
  align-items: flex-start !important;
}

.actionsCell {
  justify-content: flex-end !important;
}

.customHeader {
  padding: spacing(1) spacing(2);
  background-color: var(--grayscale0);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--text-grayscale70);
}

.tableTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: spacing(3) spacing(2) spacing(2) spacing(2);
  grid-column: 1/4;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--grayscale30);
  &.tableTitleSeasonal {
    grid-column: 1/6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > div {
      display: flex;
      align-items: center;
    }
  }
}

.weekdaysTable {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.seasonalTable {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.editingRow {
  & > div {
    background-color: var(--grayscale0);
  }
}

.row {
  &:hover {
    & > div {
      background-color: var(--grayscale0) !important;
    }
  }
}

.datePlaceholder {
  color: var(--text-grayscale90);
  font-size: var(--font-size-medium);
  &:hover {
    cursor: pointer;
    color: var(--blue-ribbon--hover);
  }
}

.seasonalFooter {
  display: flex;
  flex-direction: column;
  grid-column: 1/6;
  & .seasonalFooterText {
    color: var(--text-grayscale70);
    margin-bottom: spacing(1);
  }

  & .addSeasonalBtn {
    margin: spacing(1) 0;
    align-self: flex-start;
  }
}
