.container {
  background-color: none;
  max-width: 1000px;
  .section {
    text-align: left;
    padding: 0 50px;
    margin-bottom: 50px;

    h1 {
      margin: 0px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
    a {
      color: blue;
      font-weight: bold;
      text-decoration: underline;
    }

    .seperator {
      height: 15px;
    }

    .dotContainer {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .dot {
        margin-right: 7px;
        height: 10px;
        width: 10px;
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}
