@import 'frontend/styles/functions';

.wrapper {
  display: flex;
  justify-content: center;

  div {
    max-width: 628px;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin: spacing(2) 0 spacing(4);
}
