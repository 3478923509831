@import 'frontend/styles/functions';

.createdDialogue {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grayscale70);
  text-decoration: underline;

  .icon {
    min-width: 8px;
    min-height: 8px;
    border-radius: 100px;
    margin-right: spacing(1);
  }

  .title {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    word-wrap: break-word;
  }
}
