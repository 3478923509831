@import 'frontend/styles/variables';

@mixin dot {
  content: '';
  position: absolute;
  left: 10px;
  top: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 1;
}

.ticketIcon {
  position: relative;

  &Open:after {
    @include dot;
    background-color: var(--blue-ribbon);
  }

  &Pending:after {
    @include dot;
    background-color: var(--pink-heliotrope);
  }

  &Solved:after {
    @include dot;
    background-color: var(--black);
  }
}
