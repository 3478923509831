@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  padding: 20px;

  > div:not(:first-child) {
    margin-top: spacing(2);
  }
}

.statTitle {
  font-weight: 500;
}

.grid {
  margin-bottom: 0;
  margin-top: spacing(2);
  grid-gap: spacing(2);
}

.gridWrapper > div {
  grid-gap: spacing(4);
}

.statsWrapper {
  .stats:nth-child(even) {
    margin-bottom: spacing(2);
  }
}

.reportMetric {
  min-height: unset;

  * {
    text-align: center;
  }
}

.currentMonth {
  font-weight: bold;
}

.timeInputs {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    width: 65px;
  }

  &From,
  &To {
    position: relative;

    &:after {
      position: absolute;
      top: -16px;
      left: 0;
      color: var(--text-grayscale70);
      font-size: 12px;
    }
  }

  &From:after {
    content: 'From';
  }

  &To:after {
    content: 'To';
  }

  &Wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: $phone) {
      justify-content: flex-start;
      padding-top: 18px;
    }
  }

  &Separator {
    margin: 0 10px;
  }

  &Colon {
    margin: 0 2px 0 3px;
  }
}

.botAvatarWrap {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.botAvatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: spacing(1);
}

.botReportGrid {
  display: grid;
  grid-template-columns: 160px repeat(3, 1fr);
  column-gap: spacing(6);
  row-gap: spacing(4);

  &:last-child {
    row-gap: 0;
  }
}

.subTitle {
  text-align: right;
  font-size: 13px;
  color: var(--grayscale70);
  margin-bottom: spacing(1);
  padding-right: 1px;
}

h2.trafficSummary {
  display: flex;
}
