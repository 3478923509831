@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.roleTitle {
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  .options {
    text-align: right;
    display: flex;
    top: -2px;
    z-index: 1;
    align-items: center;
    margin-left: spacing(1);

    button {
      width: spacing(1);
      height: spacing(3);
      padding: 0;
      top: 0;
      right: 6px;
      position: relative;
      cursor: pointer;

      &:hover .optionsButton {
        @include threeDots(var(--blue-ribbon), 'vertical');
      }

      .optionsButton {
        @include threeDots(var(--text-grayscale90), 'vertical');
      }
    }

    &Item {
      min-width: 80px;
    }
  }

  &Input {
    @include noOutline;

    input {
      @include noOutline;
      font-family: var(--font-family);
      border: none;
      font-size: 16px;
    }
  }
}
