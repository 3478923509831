@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

@keyframes wrapperFadeIn {
  from {
    background-color: rgb(0, 0, 0, 0);
  }
  to {
    background-color: rgb(0, 0, 0, 0.3);
  }
}

.wrapper {
  --_modal-padding: #{spacing(2)};

  display: grid;
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 0px spacing(4);
  top: 0px;
  left: 0px;
  align-items: center;
  overflow-y: auto;
  z-index: var(--modal-z-index);
  animation: wrapperFadeIn 250ms forwards;
}

.body {
  position: relative;
  display: block;
  width: 100%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  padding: 0px;
  margin: spacing(6) auto;
  background-color: var(--white);
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.21);
  border-radius: var(--border-radius-large);
}

.header {
  padding: var(--_modal-padding);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;

  .warningMode & {
    background: var(--red-pomegranate);
    color: var(--white);
  }

  h1,
  h2,
  h3 {
    margin-bottom: spacing();
    font-weight: var(--font-weight-medium);
  }

  p {
    margin: 0;
  }

  .avatar {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
  }

  &TitleWrapper {
    display: flex;
    gap: spacing(1);
    align-items: center;
  }
}

.content {
  padding: 0 var(--_modal-padding) var(--_modal-padding);
  font-size: var(--font-size-medium);
  line-height: 1.5;

  .warningMode & {
    padding-top: var(--_modal-padding);
  }

  p {
    font-size: var(--font-size-medium);
  }
}

.footerBase {
  padding: spacing(1.5) var(--_modal-padding);
}
.footer {
  display: flex;
  gap: spacing(2);
  align-items: center;
  color: var(--text-grayscale70);
  border-top: var(--grayscale10) 1px solid;

  &Buttons {
    display: flex;
    margin-left: auto;
    gap: spacing(1);
  }

  &Shortcuts {
    font-size: var(--font-size-small);

    .warningMode &Enter {
      color: var(--red-pomegranate);
    }
  }

  &Key {
    padding: spacing(0.5);
    margin-right: 2px;
    font-size: 10px;
    font-weight: var(--font-weight-medium);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--grayscale10);
  }

  .warningMode & {
    border: 1px solid var(--red-pomegranate);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animatedBoyWrapper {
  transform: translateY(-50px);
  opacity: 0;
}

.fadeIn {
  animation: fadeIn 0.1s forwards;
}
