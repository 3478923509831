@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.dialogue {
  padding-left: spacing(2);
  position: relative;
  color: var(--text-grayscale70);
  text-decoration: underline;

  &:hover {
    color: var(--text-grayscale90);
  }

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0;
    top: 6px;
    background-color: var(--dialogue-after-bg-color, var(--yellow-sunglow)); /* Default color */
  }

  &Disabled {
    color: var(--text-grayscale70);
    text-decoration: line-through;
  }
}

.rule {
  display: flex;
  align-items: center;
  height: 38px;
  min-width: 180px;
  max-width: 320px;
  padding: 8px var(--spacing-system-spacing-l, 16px) 8px 0px;
  gap: spacing(1);
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-size-medium);
  line-height: 20px; /* 142.857% */
  font-style: normal;
}

.imageList {
  display: flex;
  align-items: flex-start;
  gap: spacing(1);
}

.image {
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-level2);
  object-fit: cover;
}

.overflowImageCount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: spacing(1);
  border-radius: var(--border-radius-small);
  background: var(--grayscale5);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-450);
  line-height: 20px; /* 142.857% */
  font-style: normal;
}

.statContent {
  color: var(--grayscale80);

  font-size: var(--font-size-medium);
  font-style: normal;
  font-weight: var(--font-weight-450);
  line-height: 20px;
}
