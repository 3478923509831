@import '../../styles/variables';

.pageContent {
  padding: 50px;
  height: auto;
  position: relative;
  max-width: $desktop;
  margin: auto;
  box-sizing: border-box;
  width: 100%;

  &WithFormValidation {
    padding-top: 80px;
  }

  @media (max-width: $tablet-wide) {
    padding: 65px 30px;
  }
  @media (max-width: $tablet) {
    padding: 65px 15px;
  }
}
