@import 'frontend/styles/functions';

.headerSection {
  padding: unset;
  margin-bottom: spacing(6);
}

.maxWidth550 {
  max-width: 550px;
}

.smallText {
  font-size: 12px;
  display: block;
  margin-top: spacing(1);
  color: var(--text-grayscale70);
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  max-width: 550px;
}

.blockContainingSelect {
  width: max-content;
}

.sectionTitle {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
}

.checkboxes {
  display: flex;
  margin-top: spacing(1);
  gap: spacing(2);
}

.label {
  all: unset;
  display: flex;
  align-items: center;
  gap: spacing(1);
  cursor: pointer;
}
