@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.generalLayout {
  .formErrors * {
    color: var(--white);
  }

  label {
    padding: 0;
    margin-bottom: 5px;
  }

  .nlpSettings {
    padding: 0 20px;

    h3 {
      margin-top: 50px;
    }
  }

  .avatarContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    display: grid;
    grid-gap: spacing(3);
    grid-template-columns: 100px 1fr;

    .portrait,
    .upload {
      flex: 1;
    }

    .portrait {
      min-height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      flex: 1;
    }

    .upload {
      justify-content: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 10px;
      }
    }
  }

  .switchContainer {
    display: flex;
    align-items: center;
  }

  .selectContainer {
    width: 100%;
    max-width: 260px;
    margin: 10px 0;
  }

  .label {
    font-weight: bold;
    span {
      font-weight: initial;
    }
  }

  .thresholdWarning {
    color: var(--text-grayscale90);
    font-weight: 100;
  }
}

.selectLanguageContainer {
  max-width: 260px;
}

.publishingContainer > div:first-child {
  margin-bottom: spacing(1);
}

.sectionTitle {
  font-weight: var(--font-weight-medium);
}

.content {
  padding: 0;
}

.inputContainer {
  width: 50%;

  @media (max-width: $desktop-small) {
    width: 100%;
  }
}

.timezoneLabel {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
  margin-bottom: spacing(0.5);
}
