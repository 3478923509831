@import 'frontend/styles/functions';

.heading {
  font-weight: var(--font-weight-medium);
}

.maxWidth {
  max-width: 400px;
}

.sectionSplit {
  display: flex;
  gap: spacing(2);

  & > :first-child {
    flex: 1.5;
  }
  & > :last-child {
    flex: 1;
  }
}

.leftSideSection {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}

.rightSideSection {
  display: flex;
  gap: 72px;
  align-items: end;
  flex-direction: column;
}
