@import 'frontend/styles/functions';

.container {
  display: flex;
  justify-content: space-between;
  padding: spacing(0.5) spacing(1.5);
}

.colorButton {
  width: 15px;
  aspect-ratio: 1;
  padding: 0;
  border-radius: 50%;

  &:hover {
    opacity: 0.6;
  }
}
