@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.link {
  position: relative;
}

.projectName {
  display: block;
  margin-bottom: spacing();
}

.createBot {
  margin: 10px 0 50px;
}
