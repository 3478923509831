@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/functions';

@mixin inputControl {
  @include noOutline;
  font-size: 14px;
  border: 2px solid var(--grayscale5);
  background-color: var(--grayscale5);
  border-radius: var(--border-radius-large);
  box-sizing: border-box;
  color: var(--text-grayscale90);

  &:hover {
    background-color: var(--grayscale10);
    border-color: var(--grayscale10);
  }

  &:focus {
    border-color: var(--blue-ribbon);
    background-color: var(--white);
  }
}

.wrapper {
  display: flex;

  .select {
    @include inputControl;
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
    min-width: spacing(11);
    padding-left: 11px;
    cursor: pointer;

    &Wrapper {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        z-index: 1;

        border-top: 7px solid var(--text-grayscale90);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
  }

  .input {
    @include inputControl;
    margin-left: spacing(1);
    padding: 6px 11px;
    width: calc(100% - 8px);
    line-height: 20px;

    &:focus + .errorMessage {
      display: inline-block;
    }

    &Error {
      border-color: var(--red-pomegranate);

      &:hover {
        border-color: var(--red-pomegranate);
      }

      &:focus {
        border-color: var(--red-pomegranate);
      }
    }
  }
}

.errorMessage {
  display: none;

  &Wrapper {
    flex-grow: 1;
  }
}
