@import 'frontend/styles/functions';

.editLabelWrapper {
  display: flex;
  width: 100%;
  padding-inline: var(--labels-section_list-padding-inline);
  align-items: center;
}

.editLabelText {
  margin-inline: spacing(1);
  font-size: var(--font-size-small);
  color: var(--grayscale60);
  user-select: none;
  flex-shrink: 0;
}
