@import 'frontend/styles/functions';

.generatedList {
  display: flex;
  flex-direction: column;
  gap: spacing(1);

  &Item {
    display: flex;
    padding: 0;
    gap: spacing(0.5);
    cursor: pointer;
  }
}
