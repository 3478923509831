@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.brokenLinksFormWrapper {
  min-width: 500px;
  flex: 1;
  @media (max-width: $desktop-small) {
    min-width: 280px;
  }
  & .brokenLinksFromContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    & .brokenLinksInput {
      flex: 1;

      & > input {
        background-color: var(--grayscale5);
        &:focus,
        &:active {
          background-color: transparent;
        }
      }

      &.brokenLinksInputActive {
        & > input {
          background-color: transparent;
        }
      }
    }

    & > button {
      margin-left: spacing(1);
      padding: 1px;
      height: auto;
      min-width: 0px !important;
      background-color: transparent !important;
      &:hover {
        background-color: var(--grayscale0) !important;
      }
      & > svg {
        margin: 0;
      }
    }
  }
}
