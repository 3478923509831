@import 'frontend/styles/functions';

.container {
  display: grid;
  padding-inline: spacing(3);
  gap: spacing(2);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.previewCard {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}

.triggerType {
  grid-row: 1 / 3;
  grid-column: 2 / 3;

  display: flex;
  gap: spacing(1);
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.formTitle {
  display: flex;
  gap: spacing(1);
  align-items: start;
  font-weight: var(--font-weight-medium);
}

.badge {
  display: flex;
  min-width: max-content;
  padding: 2px 6px;
  align-items: center;
  color: #0a4d00;
  background-color: #d3ffcc;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-small);

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    aspect-ratio: 1;
    margin-inline-end: spacing(1);
    border-radius: 50%;
    background-color: #1ee500;
  }
}

.triggerButton {
  display: flex;
  min-width: max-content;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grayscale30);
  border-radius: 50px;
  background-color: #f5f5f5;
  color: var(--black);
  font-size: var(--font-size-small);

  > svg {
    font-size: 16px;
  }
}

.icons {
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  display: flex;
  justify-content: end;
  align-items: start;

  path {
    stroke: var(--grayscale60);
  }
}

.publishForm {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  place-self: end;

  width: max-content;
}

.externalFeedbackContainer {
  display: flex;
  gap: spacing(1);
  flex-direction: column;
}

.externalFeedbackButton {
  margin-inline: auto;
  padding: spacing(1) spacing(1.5);
  border-radius: var(--border-radius-medium);
  color: var(--primaryButtonTextColor-1729529624);
  background-color: var(--primaryButtonBackgroundColor-1729529624);
}
