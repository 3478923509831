@import 'frontend/styles/functions';

.urlInput {
  display: flex;
  align-items: center;
  gap: spacing(1);

  > :nth-child(1) {
    flex: 1;
  }
}
