@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.listItem {
  padding: 8px 10px 8px 30px;
  border-radius: var(--border-radius-small);
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include noOutline;

  &:hover {
    background-color: var(--grayscale0);
  }
}
.checkedIcon {
  position: absolute;
  right: 0;
}
