@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.itemTag {
  margin-right: 5px;
  margin-left: 0;
  padding: 3px 6px;
  background: var(--grayscale60);
  color: var(--white);
  border-radius: 3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  display: inline-block;
  line-height: normal;
  cursor: inherit;

  &:hover {
    .itemTagRemove {
      opacity: 1;
    }
  }

  &Remove {
    opacity: 0;
    border: none;
    background: none;
    position: absolute;
    top: -6px;
    right: -11px;
    cursor: pointer;
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  &Clickable {
    cursor: pointer;
  }

  &Outline {
    background-color: var(--white);
    border: 1px solid var(--grayscale60);
    color: var(--text-grayscale70);
    padding: 3px 4px;
  }

  input {
    background: none;
    border: none;
    color: var(--white);
    font-size: 13px;
    font-family: IBMPlex, sans-serif;
    letter-spacing: 0.5px;

    &:focus {
      @include noOutline;
    }
  }

  &WithHue {
    color: var(--grayscale90);

    input {
      color: var(--grayscale90);
    }
  }
}
