.button {
  &Dragging {
    opacity: 0.3;
  }

  &StrikeThrough {
    text-decoration: line-through;
  }

  &:hover .options {
    transform: scaleX(1);
    transition: transform 0.1s linear;
  }
}

.options {
  display: flex;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: none;
  transform: scaleX(0);
  transition: transform 0.1s linear;
  background: var(--white);
  border-radius: 30px;
  overflow: hidden;

  &Buttons {
    display: flex;
    width: 100px;
    max-width: 80%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &Item {
      opacity: 0.35;

      &:hover {
        opacity: 1;
      }
    }
  }
}
