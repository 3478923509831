@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.modal {
  max-width: $tablet;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding-top: 0px;
}

.footer {
  display: grid;
  grid-template-columns: calc(50% + 52px) 1fr; // Subract 52px to make the pagination in center of the modal
  grid-gap: 0;
}

.paginationContainer {
  display: grid;
  grid-template-columns: repeat(3, spacing(3));
  grid-gap: spacing(2);
  align-items: center;
  justify-self: flex-end;
}
.pagination {
  background-color: var(--grayscale30);
  width: spacing(3);
  height: 4px;
  border-radius: 2px;

  &Active {
    background-color: var(--blue-ribbon);
  }
}

.actions {
  justify-self: flex-end;
}
