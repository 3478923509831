@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.section {
  margin: 24px;
}

.title {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin: 0 0 16px 0;
}

.description {
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 16px 0;
}

.link {
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 16px 0;
  color: #0069ff;
  cursor: pointer;
}

.label {
  display: block;
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.formGroup {
  display: grid;
  grid-gap: 16px;
  align-items: center;
  grid-template-columns: 1fr 44px;

  input {
    outline: none;
    border: none;
    padding: 14px 16px;
    border-radius: 22px;
    font-size: 14px;
    background: var(--grayscale5);
    width: 100%;
  }
}

.formButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 100px;
  background: #0069ff;

  svg path {
    fill: #fff;
  }
}

.nudgeButton {
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 22px;
  padding: 11px 24px;
  margin: 0 0 8px 0;
  color: #ffffff;
  background-color: #0069ff;
  border-color: #0058d6;
}

@keyframes fadeInNudge {
  0% {
    opacity: 0;
    transform: translateX(15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.nudgeContainer {
  position: absolute;
  background: #fff;
  background-color: #fff;

  top: 16px;
  right: 0px;
  z-index: 16;
  border-radius: 14px;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.33);

  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-name: fadeInNudge;
}

.borderElement {
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 14px;

  &:before {
    content: '';

    background: #0069ff;
    width: 50px;
    height: calc(100% - 8px);
    position: absolute;
    z-index: -1;
    top: 4px;
    left: -4px;
    border-radius: 25px;
  }
}

.dismissContainer {
  top: 8px;
  right: 8px;
  z-index: 15;
  display: flex;
  padding: 0;
  cursor: pointer;
  border-radius: 100px;
  position: absolute;

  &:hover {
    opacity: 0.8;
  }

  svg path {
    stroke: #0069ff;
  }
}

.layoutContainer {
  position: relative;
  padding: 0px;
  width: 375px;
  max-height: calc(100vh - 150px);
}
.sendIcon {
  font-size: 18px;
}
