@import 'frontend/styles/functions';

.container {
  display: flex;
  width: 100%;
  gap: spacing(1);
  justify-content: space-between;
}

.inputContainer {
  flex-grow: 1;
  .inputWrapper {
    width: 100%;
  }

  .input {
    width: 100%;
    min-width: 100px;
  }
}

.buttonsContainer {
  display: flex;
  gap: spacing(1);
}
