@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/features/Inbox/inbox-styles';

.wrapper {
  padding: spacing(2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--white);
  height: $inboxHeight;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-large);
  overflow-y: auto;
  grid-row: 2;

  h4 {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    svg {
      margin-right: spacing(1);
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 spacing(2);

  .dropDownWrapper {
    margin-left: auto;
  }

  span.avatarImage {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    img {
      width: 12px;
      height: 12px;
    }
  }

  img:first-child {
    width: 24px;
    height: 24px;
  }

  h4 {
    margin: 0 0 0 spacing(1);
  }
}

.userName {
  margin-top: 25px;
  margin-bottom: 20px;
}

.metaInfo {
  color: var(--text-grayscale90);
  word-break: break-all;
  display: flex;
  flex-direction: column;

  span {
    color: var(--text-grayscale70);
    margin: 0;
    font-weight: var(--font-weight-medium);

    &:not(:first-child) {
      margin-top: spacing(0.5);
    }
  }

  a {
    color: var(--text-grayscale90);

    &:hover {
      color: var(--blue-ribbon);
    }
  }
}

.actions {
  position: relative;
  font-weight: var(--font-weight-medium);

  .ticketHandoverButton {
    font-weight: var(--font-weight-medium);
    text-align: left;
    padding: 8px 11px;

    svg {
      font-size: 20px;
    }
  }
}

.options {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 5px;

  &Wrapper {
    height: 0;
    top: -4px;
    right: -10px;
  }

  &:hover {
    background-color: var(--grayscale20);
    border-radius: 50%;
  }

  $dot: 3px;

  span,
  span:after,
  span:before {
    content: '';
    position: absolute;
    width: $dot;
    height: $dot;
    background-color: var(--grayscale90);
    border-radius: 50%;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  span:before {
    top: -7px;
  }
  span:after {
    top: 7px;
  }
}

.optionsItem {
  min-width: 100px;
  &:hover {
    background-color: var(--grayscale5);
  }
}

.handoverSpan {
  display: block;
  color: var(--text-grayscale70);
  margin-bottom: spacing(1);
  font-weight: 500;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);
  margin-bottom: spacing(2);
}
