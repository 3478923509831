@import '../../styles/variables';

.container {
  position: relative;
  font-size: 14px;
}

.errorMessage {
  padding: 5px 8px 2px 8px;
  font-size: 13px;
  position: absolute;
  color: #fff;
  text-align: center;
  width: auto;
  top: calc(-70%);
  background-color: var(--red-pomegranate);
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  z-index: 10;
  left: 5px;
  border-radius: 2px;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -4px;
    left: 15px;
    border-top: 4px solid var(--red-pomegranate);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    pointer-events: none;
  }
}
