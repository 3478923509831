@import 'frontend/styles/functions';

.filterOverlayWrapper {
  padding: spacing(1);
  width: max-content;
  max-width: 480px;
}

.filtersPanel {
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: spacing(0.75);
}

@mixin filterButton {
  padding: spacing(0.5) spacing(1);
  margin: 0;
  min-width: auto !important;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.trigger {
  @include filterButton;
}

.clearFiltersBtn {
  @include filterButton;
  color: var(--text-grayscale70);
  letter-spacing: 0.5px;
  font-weight: 400;
}

.dropdown {
  overflow-y: auto;
}
