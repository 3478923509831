@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.label {
  width: 100%;
  margin-right: 0;
  border-radius: var(--border-radius-small);
  max-width: fit-content;
  padding: spacing(0.125) spacing(0.75) spacing(0.125) 0;
  position: relative;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);

  & .labelContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    & .dot {
      display: block;
      width: 6px;
      height: 6px;
      margin: 0 spacing(0.625);
      border-radius: 50%;
    }

    & .square {
      width: 6px;
      height: 6px;
      margin-inline: spacing(0.625);
      border-radius: 0;
    }

    & .labelName {
      position: relative;
      z-index: 1;
      white-space: nowrap;
      font-weight: var(--font-weight-450);
    }
  }

  & .labelChildren {
    padding-left: spacing(0.75);
  }

  .tooltip {
    display: none;
    white-space: nowrap;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}
