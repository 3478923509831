@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.listContainer {
  margin-bottom: spacing(6);

  > div {
    margin-bottom: spacing(2);
    justify-content: space-between;
  }
}
