@import 'frontend/styles/functions';

.dropdown {
  &Wrapper {
    width: 100%;
    margin-bottom: spacing(1);
  }

  &Trigger {
    display: flex;
    width: 100%;
    padding: spacing(0.5) 11px;
    justify-content: space-between;
    align-items: center;
    color: var(--text-grayscale90);
    border: 1px solid var(--grayscale15);
    border-radius: var(--border-radius-medium);
    overflow: hidden;
    white-space: nowrap;
  }

  &Overlay {
    border-radius: var(--border-radius-large);
    box-shadow: 0 0 8px var(--shadow-color);
  }
}

.iconLeftAndTextWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacing(0.5);
  overflow: hidden;

  svg {
    font-size: 20px;
    flex-shrink: 0;
  }
}

.buttonText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconChevron {
  flex-shrink: 0;

  .dropdownTrigger[aria-expanded='true'] & {
    transform: rotate(180deg);
  }
}
