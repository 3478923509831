@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.pagination {
  padding: 5px;
  display: inline-flex;
  align-items: center;

  .pagesContainer {
    display: grid;
    grid-auto-flow: column;
    grid-gap: spacing(1);
  }

  .pageButton {
    @include noOutline;
    padding: spacing(1) 12px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: var(--text-grayscale70);
    background: none;
    border: none;
    border-radius: var(--border-radius-small);

    &:hover,
    &.active {
      background-color: rgba(0, 0, 0, 0.1);
      color: var(--black);
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.arrow {
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;

  @include noOutline;

  &:hover {
    background-color: var(--grayscale5);
    border-radius: var(--border-radius-small);
  }
}

%nexPrev {
  display: flex;
  align-items: center;
  padding: 5px spacing(1);
}

.prev {
  @extend %nexPrev;
}
.next {
  @extend %nexPrev;

  svg {
    margin-left: 0;
    transform: rotate(180deg);
  }
}

%firstLast {
  display: flex;
  padding: 5px spacing(1);
  height: 100%;
  align-items: center;
}

.first {
  @extend %firstLast;

  svg:first-child {
    position: absolute;
    left: 4px;
  }
  svg:last-child {
    position: relative;
    left: 2px;
  }
}

.last {
  @extend %firstLast;

  svg {
    transform: rotate(180deg);
    &:first-child {
      position: absolute;
      left: 4px;
    }
    &:last-child {
      position: relative;
      left: 2px;
    }
  }
}
