.container {
  display: flex;
  margin-left: auto;

  .button {
    color: inherit;
    border: none;
    font: inherit;
    align-items: center;
    margin: auto;
    background-color: transparent;
    display: flex;
    cursor: pointer;

    svg {
      color: var(--grayscale80);
      transition: color 200ms ease;
      &:hover {
        color: var(--red-pomegranate);
      }
    }
  }
}
