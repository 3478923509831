@import 'frontend/styles/functions';
.container {
  --_border-radius: var(--border-radius-medium);
  --_button-padding: #{spacing(0.5)} #{spacing(2.5)};

  display: flex;
  gap: spacing(1);
}

.inputWrapper {
  position: relative;
}

.hiddenSpan {
  position: absolute;
  padding: var(--_button-padding);
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
}

.fakeButton {
  padding: var(--_button-padding);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: var(--_border-radius);
  font-weight: var(--font-weight-medium);
  box-sizing: border-box;
  font-size: var(--font-size-medium);
}

.iconsWrapper {
  position: absolute;
  display: flex;
  inset: 1px;
  justify-content: space-evenly;
  border-radius: var(--_border-radius);
  background-color: white;
  transform: scaleX(0);
  box-sizing: border-box;

  .inputWrapper:hover & {
    transform: scaleX(1);
    transition: transform 0.1s linear;
  }
}

.iconButton {
  all: unset;
  outline: revert;
  cursor: pointer;

  svg {
    font-size: 20px;
    vertical-align: middle;
  }
}

.iconTrash {
  color: var(--red-pomegranate);
}

.editButtonsWrapper {
  display: flex;
  gap: spacing(1);

  > button {
    all: unset;
    cursor: pointer;
    color: var(--primary-color);
    aspect-ratio: 1;
    font-weight: var(--font-weight-medium);
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--grayscale15);

    &:hover {
      background-color: var(--grayscale0);
    }

    > svg {
      display: block;
      margin: auto;
      color: var(--grayscale70);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
