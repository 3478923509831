@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';
@import 'frontend/features/Inbox/inbox-styles';

.viewSelectorContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  padding: spacing(2);
  background: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $phone) {
    margin-bottom: spacing(2);
    padding-bottom: spacing(2);
    border-bottom: 2px solid var(--grayscale5);
  }
}

.filterButton {
  padding: spacing(1);
  display: flex;
  justify-content: flex-start;
}

.animated {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  border-right: 2px solid #f1f1f1;
}

.viewSelectorWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(0.25);
}
