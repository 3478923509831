@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.accordionHeader {
  @include noOutline;
  display: flex;
  cursor: pointer;
  justify-content: space-between;

  & .accordionNavLeftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > svg {
      margin-right: spacing(0.5);
    }
  }

  & .headerRight {
    padding-right: 15px;

    &Container {
      display: flex;
      align-items: center;
      & > svg {
        font-size: 24px;
      }
    }
  }

  .title {
    font-size: 14px;
    margin: spacing(1) 0;
    font-weight: var(--font-weight-medium);
  }

  &FullLength h3 {
    width: 100%;
  }
}

.accordionBody {
  --_accordion-transition: 0.3s linear;

  display: grid;
  grid-template-rows: 0fr;
  margin: 0px 0px spacing(0.25) 0px;
  @media (prefers-reduced-motion: no-preference) {
    transition: grid-template-rows var(--_accordion-transition);
  }

  & .accordionInner {
    overflow: hidden;
    opacity: 0;
    transition: opacity var(--_accordion-transition);
  }

  &Open {
    grid-template-rows: 1fr;
    opacity: 1;
    margin: spacing(0.25) 0px;
    & .accordionInner {
      opacity: 1;
    }
  }
}
