@import '../../styles/typography';
.content {
  p,
  ul,
  li {
    line-height: 1.7;
    margin-top: 0;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 50vh;
  }
}
.footerWrap {
  text-align: right;
}
