@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  display: flex;
}

.icon {
  display: flex;
  margin-right: spacing(3);

  svg {
    width: 45px;
  }

  &Buttons {
    margin-right: spacing(2);

    svg {
      width: 53px;
    }
  }
}

.content {
  font-size: var(--font-size-h4);
  line-height: 25px;

  h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-medium);
  }

  a {
    text-decoration: underline;
    color: var(--blue-ribbon);

    &:hover {
      opacity: 0.7;
    }
  }
}
