@import 'frontend/styles/functions';

.fieldInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & .hidePlaceholderContent {
    width: 36px;
  }

  & .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    &:not(:first-child) {
      margin-left: spacing(2);
    }
  }
  &.fieldInputRow {
    flex-direction: row;
  }
}

.addMoreBtn {
  margin-top: spacing(2);
  align-self: flex-start;
}
