@import '../../../../styles/variables';
@import 'frontend/styles/functions';

.editingWrapper {
  position: relative;
  width: auto;
  padding: 10px;
  margin: 0 spacing(1) spacing(1) 18px;
  background-color: var(--text-grayscale90);
  color: var(--white);
  border-radius: var(--border-radius-small);

  svg {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: bottom;
  }
}

.userItem {
  padding-left: 4px;
}
