@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

@keyframes showRemoveTag {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tag {
  padding: 3px 6px;
  line-height: 16px;
  margin: 0;
  border-radius: var(--border-radius-small);
  color: var(--grayscale90);
  position: relative;
  width: fit-content;
  font-size: inherit;
  white-space: nowrap;
  height: fit-content;

  &Small {
    padding: 1px;
  }

  &Large {
    padding: 6px 1px;
  }
}

.removeTag {
  position: absolute;
  top: -5px;
  right: -10px;
  cursor: pointer;
  background: none;
  border: none;
  animation-name: showRemoveTag;
  animation-duration: 500ms;

  @include noOutline;
}

.tooltip {
  font-size: var(--font-size-medium);
}
