@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.dialogueFormWrapper {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.pageHeader {
  grid-area: header;

  display: flex;
  padding: spacing(2);
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--grayscale5);
}

.buildContent {
  margin-top: spacing(3);
}

.sectionTitle {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadingContainer {
  display: flex;
  margin-inline: 18px spacing(1);
  align-items: center;
  justify-content: center;
}

.verticalSeparator {
  background: var(--grayscale5);
  width: 100%;
  height: 100%;
}
