@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.message {
  display: grid;
  grid-template-columns: spacing(6) auto spacing(13);
  padding: spacing(2) 0 spacing(1);
  margin: 0;
  text-decoration: none;
  cursor: pointer;

  &Index {
    font-size: 14px;
    color: var(--text-grayscale70);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &Text {
    word-break: keep-all;
    line-height: 24px;

    &Wrapper {
      color: var(--text-grayscale90);
      display: flex;
      margin-right: spacing(1);
    }
  }

  &Timestamp {
    color: var(--text-grayscale70);
    word-break: keep-all;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .duplicateBadge {
    @include noOutline;
    color: var(--white);
    background-color: var(--blue-ribbon);
    display: inline-flex;
    border-radius: 14px;
    height: 16px;
    min-width: 16px;
    text-align: center;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    bottom: 1px;
    padding: 0 3px;

    &:hover {
      background-color: var(--blue-ribbon--hover);
    }
  }

  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1px;

    &Body {
      position: absolute;
      top: 30px;
      left: 20px;
    }
  }

  &:hover {
    .messageText {
      text-decoration: underline;
    }
  }
}
