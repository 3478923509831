@import 'frontend/styles/functions';

.container {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: spacing(4);
  align-items: center;
}

.title {
  font-weight: var(--font-weight-medium);
}

.button {
  margin-top: spacing(2);
}
