@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  margin: 0 spacing(-2);
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 spacing(2) spacing(2) spacing(2);
  padding: 0;
  padding-left: spacing(2);

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--blue-ribbon);
    left: 0;
  }

  input {
    max-width: 300px;
  }
}

.listItem {
  position: relative;
  display: grid;
  grid-template-columns: 30px 120px auto;
  grid-gap: 10px;
  padding: 14px 30px 12px;
  user-select: none;
  cursor: pointer;
  color: var(--text-grayscale90);
  text-decoration: none;

  &Disabled {
    pointer-events: none;
  }

  &:nth-child(odd) {
    background-color: var(--grayscale10);
  }

  &:nth-child(even) {
    background-color: var(--white);
  }

  &:hover {
    background-color: var(--grayscale0);
    color: var(--grayscale90);
  }

  &:active {
    color: var(--blue-ribbon);
  }

  &.notFound {
    user-select: initial;
    cursor: default;

    .listItemName {
      color: var(--grayscale60);
      text-decoration: line-through;
      text-decoration-color: var(--grayscale90);
      word-break: break-word;
    }
  }

  .dialogueIcon {
    position: static;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.amount {
  position: relative;
  text-align: center;

  .tooltip {
    left: calc(50% - 15px);
    top: calc(100% + 5px);
  }

  &:hover .tooltip {
    display: table;
    animation-name: showTooltip;
    animation-duration: 500ms;
  }
}

@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
