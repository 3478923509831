@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.panel {
  padding: spacing(2);
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  grid-gap: spacing(2);

  &FullWidth {
    display: flex;
    justify-content: center;
  }
}

.downloadButtonWrapper {
  display: flex;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: flex-start;
}

.table {
  padding-bottom: spacing(1);
  white-space: nowrap;
}

.label {
  display: flex;
  padding: spacing(1) spacing(1) spacing(1) spacing(2);
  font-weight: var(--font-weight-medium);
  height: min-content;
  margin-top: 2px;

  span {
    border: 1px solid var(--grayscale30);
    white-space: nowrap;
    padding: spacing(0.75) spacing(1);
    border-radius: 20px;
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Detail {
    padding: 0;
  }
}

.percentageBar {
  display: flex;
  flex-direction: column;
  margin-right: spacing(1);

  .counts {
    display: flex;
    white-space: nowrap;
    margin: spacing(0.5) 0;

    :first-child {
      margin-right: spacing(1);
    }
  }

  .bar {
    display: flex;
    width: 100%;

    & * {
      height: 4px;
    }

    :first-child {
      background: var(--blue-ribbon);
      border-radius: 10px 0 0 10px;
    }

    :last-child {
      background: var(--grayscale30);
      border-radius: 0 10px 10px 0;
    }
  }
}

.triggeredCell {
  width: 100%;
  min-width: 100px;
}

.value {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
