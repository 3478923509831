.buttonTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-grayscale90);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-weight: var(--font-weight-450);
  font-size: var(--font-size-medium);
}
