@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

button.navigationButton {
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  color: var(--text-grayscale90);
  white-space: nowrap;
  @include noOutline;

  &Active,
  &:hover {
    background: var(--grayscale5);
    text-decoration: none;
  }

  &Mobile {
    display: none;
  }

  @media screen and (max-width: $phone) {
    display: block;
  }
}
