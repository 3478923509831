@import 'frontend/styles/functions';

.button {
  position: relative;
  width: 50px;
  height: 50px;
  padding: spacing(1);
  line-height: 1;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  vertical-align: middle;
  color: var(--grayscale80);
  background: none;
  outline: none;
  border: none;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  display: block;

  &:hover,
  &Active {
    background-color: var(--grayscale10);
    box-shadow: 0 0 0 1px var(--grayscale15);
  }

  &:active,
  &Active {
    color: var(--blue-ribbon);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
}

.badge {
  position: absolute;
  top: -10%;
  right: -10%;
  width: 20px;
  aspect-ratio: 1;
  line-height: 20px;
  font-size: var(--font-size-small);
  color: var(--grayscale80);
  background-color: var(--grayscale5);
  border-radius: 50%;

  .button:hover &,
  .button:active &,
  &Active {
    color: white;
    background-color: var(--grayscale80);
  }
}
