@import 'frontend/styles/functions';

.container {
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale80);
}

.item {
  display: flex;
  padding: spacing(1);
  gap: spacing(0.5);
  align-items: center;
  border-radius: var(--border-radius-large);

  &.selected.selected {
    color: var(--primary-color);
  }
}

.accordionHeader {
  height: 24px;
  padding: spacing(1);
  box-sizing: content-box;
}

.accordionIcon {
  width: 24px;
  height: 24px;
}

.accordionItemIcon {
  height: 20px;
  width: 2px;
  margin: 2px 11px;
  background-color: var(--grayscale20);

  .accordionItem.selected & {
    background-color: var(--primary-color);
  }
}
