@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.categorySourceWrapper {
  margin-bottom: spacing(1.25);

  & .checkboxOption {
    padding-left: spacing(1);
  }

  & .checkboxSubOption {
    padding: 0 spacing(4);
  }
}

.platformTestExplanation {
  margin-top: spacing(0);
  color: var(--text-grayscale70);
  display: inline-block;
  min-width: calc(100% - #{spacing(1) * 2});
  width: 0;
  font-size: var(--font-size-small);
}
