@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.downloadButton {
  cursor: pointer;
  position: relative;
  @include noOutline;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: spacing(1) spacing(1.5);

  & .attachmentFileInfo {
    display: flex;
    flex-direction: column;
    margin-left: spacing(1);
    & .attachmentFileInfoTitle {
      max-width: 240px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      word-break: break-all;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-450);
      color: var(--text-grayscale90);
    }
    & .attachmentFileInfoSize {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
      color: var(--text-grayscale70);
    }
  }
}

.chatGroupButton {
  background: var(--grayscale30);

  &Click {
    background: var(--grayscale10);
  }
}

.attachmentIconContainer {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  background: var(--grayscale5);
  align-items: center;
  border-radius: 18px;

  svg {
    height: 35px;
  }

  &File {
    height: 30px;
    width: 220px;

    svg {
      height: 18px;
    }
  }
}

.attachmentImageWrapper {
  position: relative;
  margin-bottom: spacing(1);
  overflow: hidden;
  display: flex;
  height: 100px;

  &:hover button {
    display: block;
  }

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    border-radius: 18px;
  }

  button {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0.65;
    color: var(--grayscale90);
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    max-height: 100%;
    max-width: 100%;
    border-radius: 18px;
  }
}
