.previewEventMessage {
  color: var(--grayscale70);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
}

.icon {
  height: var(--font-size-medium);
}
