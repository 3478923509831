@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.itemList {
  padding: spacing(2) 0 0;
}

.activityAccordion {
  padding: 0 spacing(1);

  div h3 {
    font-size: var(--font-size-medium);
  }

  div {
    margin: 0;

    i {
      margin-top: 14px;
    }
  }
}

.activityPanel {
  &:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  }
}
