@import 'frontend/styles/variables';

.textDiff {
  &Added {
    color: var(--green-harlequin);
  }

  &Removed {
    color: var(--red-pomegranate);
    text-decoration: line-through;
  }
}
