@import 'frontend/styles/variables';

.addTagFeedback {
  position: relative;
  margin-bottom: var(--gutter-small);

  &:after {
    position: absolute;
    content: '';
    top: 12px;
    right: 11px;
    width: 15px;
    height: 15px;
    /* stylelint-disable-next-line */
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDE0IDEzIj4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDY5RkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjUiIGQ9Ik0yIDYuNUw1LjYxMyAxMSAxMiAyIi8+PC9zdmc+)
      0 0 no-repeat;
    opacity: 0;
    transition: opacity 0.7s ease-out;
    z-index: 1;
  }
  &Show:after {
    opacity: 1;
    transition: opacity 0s linear;
  }
}
