.fileComponentWrapper {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  gap: 8px;
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid var(--grayscale15);
  cursor: pointer;

  &:hover {
    background-color: var(--grayscale20);
  }

  & .fileComponentIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
  }

  & .fileComponentContent {
    display: flex;
    flex-direction: column;
    flex: 1;

    & .fileComponentFileName {
      font-size: var(--font-size-medium);
      color: var(--text-grayscale80);
      font-weight: 450;
      word-break: break-all;
    }

    & .fileComponentFileSize {
      font-size: var(--font-size-small);
      color: var(--text-grayscale70);
    }
  }
}
