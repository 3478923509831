@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.dropdown {
  position: relative;
  display: inline-block;
  height: auto;

  &Trigger {
    background: unset;
    border: unset;
    font: inherit;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include noOutline;
  }

  &Disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  opacity: 0;
}
