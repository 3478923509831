@import 'frontend/styles/functions';

.dropdownButton.dropdownButton {
  padding-inline: 5px;
  border-radius: var(--border-radius-medium);

  &:hover,
  &[aria-expanded='true'] {
    background-color: var(--grayscale0);
  }
}

.overlay {
  max-width: 25vw;
  padding-block: spacing(1);
  overflow: auto;
  border-radius: var(--border-radius-large);
}

.ul {
  padding: unset;
  list-style-type: none;
}
