@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.boxContainer {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-gap: spacing(1);
  margin-bottom: spacing(2);
}

.selectionBox {
  @include noOutline;
  cursor: pointer;
  border: 1px solid var(--grayscale30);
  border-radius: var(--border-radius-large);
  padding: spacing(2);
  margin: 0;

  &Active {
    background-color: var(--blue-ribbon);
    border-color: var(--blue-ribbon);

    .roleTitle {
      text-decoration: underline;
      color: white;
    }
    .roleDescription {
      color: white;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    margin-bottom: spacing(1);
    color: var(--text-grayscale90);
  }
  p {
    color: var(--text-grayscale90);
    font-size: 14px;
    margin: 0;
  }
}

.emptyState {
  margin-bottom: spacing(2);
  line-height: 24px;
}
