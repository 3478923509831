@import '../../../../styles/variables';
@import '../../styles';

.calendarWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown {
  border-radius: var(--border-radius-large);
  top: calc(100% + 10px);
  box-shadow: 0 0 8px 1px var(--shadow-color);
}

.calendarButton {
  padding-left: 0;
  padding-right: 0;
  min-width: 43px !important;
  width: 43px;
}

.dateWrapper {
  display: inline-block;
  overflow: auto;
  margin-left: 20px;
}

.primaryDates,
.secondaryDates {
  display: inline-block;
  padding-left: 20px;
  position: relative;
  float: left;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
  }
}
.primaryDates {
  margin-right: 20px;
  &:after {
    @extend %primaryDot;
  }
}
.secondaryDates:after {
  @extend %secondaryDot;
}
