@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.checkbox {
  display: flex;
  width: 100%;
  align-items: center;

  &Hidden {
    display: none;
  }

  &.checkboxRow {
    cursor: pointer;
    &:hover {
      background-color: var(--grayscale0);
    }
  }
}

.checkInput {
  display: none;

  &[type='checkbox']:checked + .checkLabel:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDE0IDEzIj4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDY5RkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjUiIGQ9Ik0yIDYuNUw1LjYxMyAxMSAxMiAyIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
  }

  &[type='checkbox']:disabled + .checkLabel:after {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus + .checkLabel {
    // Custom styles to replicate the default outline
    @include noOutline {
      border: none;
      box-shadow: none;
    }
    border: 2px solid #60a0ff;
    box-shadow: 0px 0px 1px 2px #60a0ff;
    border-radius: 2px;
  }
}

.checkLabel {
  min-width: 18px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  font-weight: var(--font-weight-medium);

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid var(--grayscale20);
    border-radius: var(--border-radius-small);
    left: 0;
    top: calc(50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  &.inputRowClick {
    pointer-events: none;
    user-select: none;
    line-height: 20px;
    padding-top: spacing(0.5);
    padding-bottom: spacing(0.5);
  }

  .checkInput:disabled + & {
    cursor: not-allowed;
    color: var(--grayscale40);
  }

  &HasLabel {
    padding-left: 35px;
  }
}
