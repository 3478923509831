@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

div.container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;

  .action {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-medium);

    * {
      margin-right: spacing(0.5);
    }
  }

  .avatar {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: spacing(2);

    img {
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
