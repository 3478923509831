@import 'frontend/styles/variables';

.content {
  color: var(--text-grayscale90);

  a {
    color: var(--text-grayscale90);

    &:hover {
      text-decoration: none;
    }
  }
}
