@import 'frontend/styles/functions';

.horizontalDropdownButton {
  border-radius: 0 !important;
  height: 26px;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
  border: none !important;
  border-left: 1px solid var(--grayscale15) !important;
}

.includeExcludeDropdownMenuWrapper {
  min-width: 80px;
  padding: spacing(0.625);
}

.includeExcludeOption {
  font-size: 13px;
  &.activeIncludeExcludeOption {
    background-color: var(--blue-ribbon) !important;
    border-radius: var(--border-radius-small);
    color: var(--white) !important;
  }
}
