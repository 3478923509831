@import '../../../../styles/variables';
@import '../../../../styles/functions';

a {
  color: inherit;
  text-decoration: none;
}

.content {
  display: grid;
  grid-template-columns: auto 40px 1fr auto auto auto;
  grid-gap: spacing(2);
  align-items: center;
  position: relative;

  .avatar {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .botName,
  .percentage {
    color: var(--black);
    margin: 0;
    font-weight: var(--font-weight-medium);
  }

  .statHeader,
  .percentage,
  .orgName {
    font-size: 12px;
  }

  .statHeader,
  .orgName {
    margin: 0;
  }

  .languages {
    div {
      background-color: var(--grayscale5);
      border-radius: var(--border-radius-medium);
      display: inline-block;
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      padding: 3px 9px;

      &:not(:last-child) {
        margin-right: spacing();
      }
    }
  }

  .statContainer {
    text-align: center;
    margin-right: spacing();
    padding-left: spacing(3);
    border-left: 1px solid var(--grayscale30);

    .statContent {
      font-size: 18px;
      color: var(--black);
      display: block;
      margin-top: 4px;
    }
  }
}
