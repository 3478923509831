@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  margin-bottom: spacing(6);
}

.alert {
  margin-bottom: spacing(2);
  position: relative;
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 28px auto min-content;
  align-items: center;
  padding: spacing(2);

  &:hover {
    .dismiss {
      opacity: 1;
    }
  }

  &Title {
    font-weight: var(--font-weight-medium);
    margin: 0;
  }

  &Message {
    margin: 5px 0 0 0;
  }

  &Clickable {
    cursor: pointer;
    transition: box-shadow 300ms ease;

    &:hover {
      box-shadow: 0px 2px 6px 0px var(--shadow-color-dark);
    }
  }
}

.icon {
  height: 24px;
  grid-column: 1;

  &.ERROR path {
    color: var(--red-pomegranate);
  }
  &.WARNING path {
    color: var(--yellow-sunglow);
  }
  &.INFO path {
    color: var(--blue-ribbon);
  }
}

.dismiss {
  font-size: var(--font-size-medium);
  color: var(--blue-ribbon);
  transition: opacity 300ms ease;
  opacity: 0;
}
