@-webkit-keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.ghostStripe {
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #eeeeee;
}
.ghostStripe:before {
  content: '';
  display: block;
  width: 200px;
  height: 100%;
  background: #eeeeee;
  background: linear-gradient(
    to right,
    rgba(238, 238, 238, 1) 0%,
    rgba(229, 229, 229, 1) 33%,
    rgba(224, 224, 224, 1) 52%,
    rgba(229, 229, 229, 1) 73%,
    rgba(238, 238, 238, 1) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation-name: loading;
  animation-name: loading;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkist-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
