@import 'frontend/styles/variables';
@import 'frontend/styles/typography';
@import 'frontend/styles/functions';

.container {
  background-color: var(--white);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: spacing(5) 0 spacing(5) 0;
  padding: spacing(2);
  display: grid;
  grid-template-columns: max-content max-content auto;
  @media screen and (max-width: $tablet) {
    grid-template-columns: max-content auto;
    grid-template-rows: auto auto;
    grid-row-gap: spacing(2);
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccdbff;
    width: 56px;
    height: 56px;
    box-shadow: var(--box-shadow-card);
    border-radius: var(--border-radius-large);
    margin: 4px spacing(2) 0 0;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .textWrapper {
    max-width: 480px;
    h4 {
      margin: 0;
    }
    p {
      margin: 4px 0 0 0;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-self: end;
    margin-right: spacing(2);
    @media screen and (max-width: $tablet) {
      grid-column: 2;
      grid-row: 2;
      justify-self: auto;
    }
  }
}
