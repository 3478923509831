@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.chatStatus {
  padding-top: spacing(0.5);
  font-weight: var(--font-weight-medium);
  color: var(--grayscale80);
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.badge {
    padding: spacing(0.5) spacing(1);
    background: var(--grayscale5);
    border-radius: var(--border-radius-small);
    width: fit-content;
  }

  .dot {
    border-radius: 50%;
    background-color: var(--red-pomegranate);
    width: 8px;
    height: 8px;
    align-self: center;
    margin-left: auto;
  }

  .icon {
    position: relative;
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: spacing(0.5);
  }
}
