@import '../../styles/variables';
@import '../../styles/mixins';

.label {
  height: 22px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;

  // toggle doesn't have a default label, so we use + syntax temporary to target labels
  & + label {
    font-weight: var(--font-weight-medium);
  }
}

div.switchWrapper {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  & + label {
    font-weight: var(--font-weight-medium);
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
  left: -999999px;

  &:focus + .label {
    // Custom styles to replic the default ouline
    border: 1px solid #60a0ff;
    box-shadow: 0px 0px 1px 2px #60a0ff;
    border-radius: 1px;
    @include noOutline {
      border: none;
      box-shadow: none;
    }
  }
}

.status {
  position: relative;
  padding-left: 8px;
  color: var(--text-grayscale90);

  &:not(&Disabled):hover {
    color: var(--text-grayscale90);
    cursor: pointer;
  }

  &Disabled {
    color: var(--text-grayscale70);
    cursor: not-allowed;
  }

  .switchWrapper:global(.kindlyAdminManager) & {
    color: inherit;
  }
}

.switch {
  height: 18px;
  width: 36px;
  outline: none;
  display: inline-block;
  border-radius: 100px;
  background-color: var(--blue-ribbon);
  position: relative;
  cursor: pointer;

  & + label {
    font-weight: var(--font-weight-medium);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateX(-100%);
  }

  &.switchOn {
    background-color: var(--blue-ribbon);
  }

  &On {
    &:after {
      left: calc(100% - 2px);
      transition: left 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .switchWrapper:global(.kindlyAdminManager) & {
      background-color: currentColor;
    }
  }

  &Off {
    background-color: var(--grayscale20);

    &:after {
      left: 16px;
      transition: left 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
  }
}
