@import 'frontend/styles/functions';

.container {
  display: flex;
  flex-direction: column;
  margin-top: spacing(1);
  gap: spacing(0.5);
  color: var(--text-grayscale90);
  border-top: 1px solid;
  border-image: linear-gradient(to right, var(--grayscale70), transparent, transparent) 1;

  .title {
    padding-top: spacing(0.5);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
  }

  .referenceList {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(1);
    font-size: var(--font-size-small);
  }

  .reference {
    all: unset;
    outline: revert;
    outline-offset: 1px;

    display: inline-flex;
    padding: 1px 3px;
    align-items: center;
    line-height: 1.4;
    border: 1px solid currentColor;
    border-radius: var(--border-radius-small);
    transition: box-shadow 0.1s;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      box-shadow: 0 0 0 1px currentColor;
    }

    svg {
      margin-left: spacing(0.5);
      width: 12px;
      aspect-ratio: 1;
    }
  }

  .referenceText {
    margin-left: 3px;
  }

  .sup {
    position: relative;
    font-size: 0.7em;
    bottom: 0.33em;
  }
}
