@import 'frontend/styles/functions';

.container {
  border: 0;
  padding: 0;

  display: flex;
  gap: spacing(1);
  flex-wrap: wrap;
}

.label {
  display: flex;
  padding-block: 4px;
  min-width: 75px;
  gap: spacing(0.5);
  align-items: center;
  justify-content: center;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--grayscale0);
  }

  &.selected {
    background-color: var(--grayscale10);
    color: var(--primary-color);
  }

  &:has(:focus-visible):focus-within {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
}

.checkIcon {
  width: 16px;
  height: 16px;
}
