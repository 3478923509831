@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.listContainer {
  background-color: #ffffff;
  border-radius: 9px;
  overflow: hidden;
  margin: 0 spacing(-2);
  flex-grow: 1;
}

.listElement,
.listElementHeader {
  position: relative;
  display: grid;
  grid-template-columns: auto 60px;
  grid-gap: 10px;
  padding: 14px 30px 12px;
  color: var(--text-grayscale90);
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grayscale5);
  }

  span:last-child {
    text-align: right;
  }
}

.listElementHeader {
  font-weight: 500;
}
