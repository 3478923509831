@import '../../../../styles/variables';

.sampleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .sampleCount {
    color: var(--blue-ribbon);
    margin-left: 5px;
  }

  .dropdownButton {
    z-index: 1;
  }

  .samplesActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > button {
      margin-right: 24px;
    }
  }
}

.expandedSamplesCount {
  margin-left: 5px;

  &Warning {
    color: var(--yellow-sunglow);
  }
  &Error {
    color: var(--red-pomegranate);
  }
}
