@import '../../styles/variables';
@import '../../styles/mixins';

.time {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color-base);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);

  @include noOutline;
}
