@import 'frontend/styles/functions';

.formButtonsContainer {
  button {
    margin-left: spacing(2);
  }
}

.scraperInfo {
  font-size: var(--font-size-large);
  color: var(--text-grayscale90);
  margin-bottom: spacing(3);
  & .scraperImgAndLanguage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > img {
      width: 58px;
      height: 58px;
    }
  }
  & p {
    font-size: var(--font-size-large);
    color: var(--text-grayscale90);
  }
}

.scraperTitle {
  margin-top: spacing(3);
  font-weight: var(--font-weight-medium);
}

.scraperReadMore {
  & > a {
    text-decoration: underline;
  }
}

.warning {
  width: 100%;
  margin-block: spacing(4);
}
