@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(4);
}

.introduction {
  max-width: 500px;
  font-size: 14px;
  color: var(--text-grayscale90);
}

.results {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 8fr;
  grid-column-gap: 0;
  word-break: break-all;
  font-size: 14px;
  font-weight: normal;

  &Header {
    background-color: var(--grayscale20);
    border-radius: spacing(1) spacing(1) 0px 0px;

    h3 {
      font-weight: var(--font-weight-medium);
      margin: 10px spacing(3) 11px;
      font-size: 14px;
    }
  }
}

.configuration {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  span {
    margin: spacing(1) spacing(2);
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    label {
      padding: 0;
    }

    div,
    button {
      margin: 0 spacing(3) 0 0;
    }
  }
}

select.selectLanguage {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.noClusters {
  font-size: 18px;
  padding-top: 30px;
}
