@import 'frontend/styles/functions';

.titleWrapper {
  display: flex;
  margin-bottom: spacing(2);
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin-bottom: unset;
  font-size: inherit;
  font-weight: inherit;
}

.infoButton {
  margin-left: spacing(1);
}
