@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.pluginLogo {
  max-width: 58px;
  max-height: 58px;
}

.pluginTitle {
  font-weight: 500;
  font-size: var(--font-size-large);
  color: var(--grayscale90);
  line-height: 21px;
}

.pluginDescription {
  font-weight: 400;
  font-size: var(--font-size-large);
  color: var(--grayscale90);
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.connectTable > div {
  gap: spacing(2);
  padding-bottom: spacing(3);
}

.headerComponentWrapper {
  padding: spacing(3);
}

.connectListPanel {
  padding: 0;
}
