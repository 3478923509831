@import 'frontend/styles/functions';

.breadcrumbs {
  all: unset;

  display: flex;
  align-items: center;
  list-style-type: none;
}

.li {
  min-width: max-content;
}

.separator {
  font-size: 20px;
  margin-inline: spacing(1.5);
  user-select: none;
}
