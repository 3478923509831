@import 'frontend/styles/variables';

.link {
  text-decoration: underline;
  display: inline-block;

  p {
    font-size: 17px;
    color: var(--text-grayscale70);
    margin: 0;

    &:hover {
      color: var(--blue-ribbon);
    }
  }
  &Small {
    p {
      font-size: var(--font-size-medium);
    }
  }
}
