@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

// Use this height to set the height of an element that needs inner scroll and full page height
$inboxHeight: calc(100vh - 201px);
$inboxMobileHeight: calc(100vh - 244px);

.formWrapper {
  margin: 0 spacing(2) spacing(2);
  border: 2px solid var(--grayscale5);
  border-radius: var(--border-radius-large);
}

.textWrapper {
  border-bottom: 1px solid var(--grayscale5);
}

.sendForm {
  display: flex;
  align-items: center;
  padding: spacing(1.5);

  div textarea {
    max-height: 116px;
  }

  .input {
    width: 90%;
    display: flex;
    align-items: center;

    textarea {
      color: var(--text-grayscale90);
      border: none;
      position: relative;
      line-height: 145%;
      font-size: 14px;
      background: transparent;
      padding: 0;
    }
  }
}

.commentForm {
  padding: spacing(0.8);
  align-items: center;

  &:hover {
    background-color: var(--grayscale0);
  }
}

.submit {
  background: var(--blue-ribbon);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-left: auto;
  cursor: pointer;
  color: var(--white);
  font-weight: 500;

  svg {
    height: auto;
    fill: var(--white);
    transform: rotate(-45deg) scale(1.7) translateX(1px);

    path {
      color: var(--blue-ribbon);
    }
  }

  &Notes {
    background-color: var(--orange-color);

    svg {
      path {
        color: var(--orange-color);
      }
    }
  }

  &Disabled {
    pointer-events: none;
    background-color: var(--grayscale5);
    color: var(--text-grayscale90);

    svg {
      fill: var(--text-grayscale90);

      path {
        color: var(--grayscale5);
      }
    }
  }

  &Expanded {
    width: auto;
    border-radius: 20px;
    gap: spacing(1);
    white-space: pre;
    text-decoration: none !important;
    padding: 0 spacing(1.5);

    @media (max-width: $desktop-small) {
      border-radius: 50%;
      padding: spacing(1);
    }
  }

  &Text {
    display: none;
  }

  &TextExpanded {
    display: block;

    @media (max-width: $desktop-small) {
      display: none;
    }
  }
}

.emptyInbox {
  grid-column: 2 / 5;
  grid-row: 1 / 3;

  &Splash {
    color: var(--text-grayscale90);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 24px;
      max-width: 500px;
      margin-top: 0;
      text-align: center;
    }
  }

  &List {
    color: var(--text-grayscale70);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;

    p {
      font-size: 14px;
      margin: spacing(0.5) 0 0;
      max-width: 180px;
      text-align: center;
    }
  }

  &Image {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
    position: relative;
  }

  .talkBubble {
    width: 100px;
    height: 100px;
    position: absolute;
    right: -72px;
    top: -40px;
  }
}

.navigationButtons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: spacing(2);
  background: var(--white);

  @media screen and (max-width: $phone) {
    border-bottom: 2px solid var(--grayscale5);
    justify-content: space-between;
    margin-bottom: spacing(2);
  }

  &MobileOnly {
    display: none;

    @media screen and (max-width: $phone) {
      display: flex;
    }
  }
}
