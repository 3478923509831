@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonList {
  padding: 0;
  margin-bottom: 0;

  li {
    list-style: decimal;
    margin: 0 spacing(2);

    a {
      cursor: pointer;

      &:hover {
        color: var(--blue-ribbon);
      }
    }
  }

  &Language {
    font-weight: var(--font-weight-medium);
    margin: spacing(1) 0;
  }
}
