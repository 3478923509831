@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: spacing(2);
}

.infoButton {
  margin-left: spacing(1);
}
