@import './variables';
@import './functions';

$gutter-base: 30px; // Set gutter base size used for margin & paddings

$sizes: (
  'sm': 0.25,
  'md': 0.75,
  'lg': 1.25,
  'xl': 1.5,
);
$directions: (
  't': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right',
);

:global {
  // Zero margin
  .m-n {
    margin: 0;
  }
  .m-t-n {
    margin-top: 0;
  }
  .m-b-n {
    margin-bottom: 0;
  }

  // Margins
  .m {
    margin: $gutter-base;
  }
  @each $size, $multiplier in $sizes {
    .m-#{$size} {
      margin: $gutter-base * $multiplier;
    }
  }
  .m-t {
    margin-top: $gutter-base;
  }
  .m-r {
    margin-right: $gutter-base;
  }
  .m-b {
    margin-bottom: $gutter-base;
  }
  .m-l {
    margin-left: $gutter-base;
  }
  @each $short, $long in $directions {
    .m-#{$short}-xxl {
      margin-#{$long}: $gutter-base * 3.3;
    }

    @each $size, $multiplier in $sizes {
      .m-#{$short}-#{$size} {
        margin-#{$long}: $gutter-base * $multiplier;
      }
    }
  }

  @for $i from 0 through 7 {
    // Grid 8 margin
    .m-t-#{$i} {
      margin-top: spacing($i);
    }
    .m-r-#{$i} {
      margin-right: spacing($i);
    }
    .m-b-#{$i} {
      margin-bottom: spacing($i);
    }
    .m-l-#{$i} {
      margin-left: spacing($i);
    }
    .m-in-#{$i} {
      margin-inline: spacing($i);
    }
    .m-bl-#{$i} {
      margin-block: spacing($i);
    }

    // Grid 8 padding
    .p-t-#{$i} {
      padding-top: spacing($i);
    }
    .p-r-#{$i} {
      padding-right: spacing($i);
    }
    .p-b-#{$i} {
      padding-bottom: spacing($i);
    }
    .p-l-#{$i} {
      padding-left: spacing($i);
    }
    .p-in-#{$i} {
      padding-inline: spacing($i);
    }
    .p-bl-#{$i} {
      padding-block: spacing($i);
    }
  }

  // Paddings
  .p-n {
    padding: 0;
  }
  .p {
    padding: $gutter-base;
  }
  @each $size, $multiplier in $sizes {
    .p-#{$size} {
      padding: $gutter-base * $multiplier;
    }
  }
  @each $short, $long in $directions {
    .p-#{$short}-n {
      padding-#{$long}: 0;
    }
    .p-#{$short} {
      padding-#{$long}: $gutter-base;
    }

    @each $size, $multiplier in $sizes {
      .p-#{$short}-#{$size} {
        padding-#{$long}: $gutter-base * $multiplier;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .link {
    color: var(--text-grayscale70);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--blue-ribbon);
    }
  }

  .font-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .text-color {
    color: var(--text-grayscale90);
  }

  .text-color-light {
    color: var(--text-grayscale70);
  }

  .vertical-middle {
    vertical-align: middle;
  }

  /* Specificity is bumped since this is an override. */
  .kindlyAdminManager.kindlyAdminManager.kindlyAdminManager,
  .kindlyAdminManager.kindlyAdminManager.kindlyAdminManager a {
    color: var(--pink-heliotrope);
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }

  // Flex
  .flex {
    display: flex;
  }

  .f-s {
    display: flex;
    justify-content: flex-start;
  }
  .f-c {
    display: flex;
    justify-content: center;
  }
  .f-e {
    display: flex;
    justify-content: flex-end;
  }
  .f-sb {
    display: flex;
    justify-content: space-between;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .all-unset {
    all: unset;
  }
}
