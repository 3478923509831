@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.entityRow:hover {
  background-color: var(--grayscale20);

  .optionsButton {
    opacity: 1;
  }
}

span.name {
  margin-top: spacing(1);
  display: inline-block;
}

.itemCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .expandButton {
    padding: 0;
    top: -2px;
    font-size: 13px;
  }

  .itemList {
    margin: 0 10px -8px 0;
  }
}
