@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/functions';

.container {
  position: relative;
}

.content {
  display: grid;
  grid-template-columns: 40px 1fr auto;
  grid-gap: spacing(3);
  align-items: center;
  position: relative;
}

.title {
  font-size: 20px;
  color: var(--text-grayscale90);
  margin-bottom: spacing(0.5);
  font-weight: var(--font-weight-medium);
}

.orgAvatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: var(--border-radius-medium);
}

.statsContainer {
  display: flex;

  .stats {
    padding: 0 spacing(2);
    text-align: center;
    border-left: 1px solid var(--grayscale30);

    &:last-child {
      padding: 0 0 0 spacing(2);
    }

    &Title {
      font-size: 12px;
      color: var(--text-grayscale70);
      font-weight: var(--font-weight-medium);
    }

    &Amount {
      font-size: 18px;
      color: var(--text-grayscale90);
    }
  }
}

.deleteOrgBadge {
  background-color: var(--red-pomegranate);
  align-self: flex-start;
  & span {
    color: var(--white);
  }
}
