@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.buttonWrapper {
  display: flex;
  position: relative;
  margin-bottom: spacing(1);
  user-select: none;
  border-radius: 30px;
  z-index: 1;
}

.button {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 4px 12px 4px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grayscale30);
  color: var(--text-grayscale90);
  background: var(--white);
  white-space: nowrap;
  min-width: 100px;
  max-width: 250px;
  margin: 0;
  line-height: 20px;
  text-align: center;
  box-shadow: 0 1px 1px 0 var(--shadow-color);

  &:focus {
    outline: none;
  }

  &Disabled {
    text-decoration: line-through;
    color: var(--text-grayscale70);
    border-color: var(--grayscale60);
  }

  &Editing {
    background: var(--grayscale20);
  }

  &Icon {
    width: 17px;
    position: relative;
    margin: 0px 5px 0 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      height: 17px;
    }
  }

  &Label {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &IconContactDetails svg {
    position: relative;
    top: 2px;
    fill: none;

    g {
      path:first-child,
      circle {
        stroke: var(--black);
        fill: none;
      }
    }
  }
}
