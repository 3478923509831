@import '../../../styles/variables';
@import 'frontend/styles/functions';

.content {
  padding: 0;
}

.boxContainer {
  padding-top: spacing();
}

.switchContainer {
  display: flex;
  align-items: center;

  label {
    padding: 5px 0;
  }
}

.helpText {
  line-height: 20px;
  color: var(--text-grayscale70);
}

.scheduleContainer {
  .inputGroup {
    label {
      display: block;
    }
  }

  .calendarGroup {
    display: grid;
    grid-row-gap: spacing(3);
    grid-column-gap: spacing(6);

    input {
      text-align: center;
    }

    .gridOne {
      grid-column: 1;
    }
    .gridTwo {
      grid-column: 2;
    }
    .gridThree {
      grid-column: 3;
    }
    .gridOneToThree {
      grid-column: 1/4;
    }
  }
}

.sectionTitle {
  font-weight: var(--font-weight-medium);
}

.link {
  color: var(--text-grayscale70);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.selectContainer {
  width: 50%;
  max-width: 400px;

  @media (max-width: $desktop-small) {
    width: 100%;
  }
}
