@import 'frontend/styles/functions';

.listContainer {
  position: relative;

  > div {
    margin-bottom: spacing(2);
  }

  .loadNewerButton {
    position: absolute;
    top: -(spacing(1));
  }
}
