@import 'frontend/styles/functions';

.buttonBlock {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: spacing(2);
  align-items: flex-end;

  > button {
    margin-bottom: 1px;
  }
}
