@import '../../styles/variables';

.centeredComponent {
  display: grid;
  flex: 1;
  height: 100%;

  .centeredInner {
    margin: auto;
  }
}
