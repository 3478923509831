@import 'frontend/styles/functions';

.ghostCircle {
  position: relative;
  width: 60px;
  height: 100px;
}

.ghostLines {
  position: relative;
  width: 100%;
  height: 100px;
}

.ghostContainer {
  position: relative;
  overflow: hidden;
  height: 56px;
  border-radius: 8px;
  margin: spacing(2) 0;
}
