@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.description {
  margin-bottom: 0;
}

.table {
  margin-top: spacing(3);
}
