@import 'frontend/styles/functions';

.chatMessageReviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: spacing(1) spacing(2);
  margin-top: spacing(2);
  & .chatMessageReviewActionsTitle {
    font-size: var(--font-size-small);
    line-height: 18px;
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);
  }
  & .chatMessageReviewButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacing(0.75);
    & .chatMessageReviewNegativeAnswers {
      padding: spacing(1) 0;
      & .chatMessageReviewNegativeAnswer {
        padding: 0 spacing(1) 0 spacing(1.5);
      }
    }
  }
}
