@import 'frontend/styles/functions';

.card {
  padding-block: spacing(2);
  height: fit-content;
  margin-bottom: spacing(1);
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 1em;
  margin-bottom: spacing(1);
  padding-inline: spacing(2);

  .image {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    object-fit: cover;
    margin-block: auto;
  }

  .title {
    font-weight: var(--font-weight-medium);
    margin-block: auto;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}

.row {
  display: flex;
  padding-inline: spacing(2);
  max-height: 0;
  gap: spacing(1);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-block 0.3s;
  opacity: 0;

  &Visible {
    opacity: 1;
    max-height: calc(22px + spacing(1));
    padding-block: spacing(0.5);
  }

  .image {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: cover;
  }

  .dropdown {
    height: 22px;
    width: 22px;
  }

  .dropdownWrapper {
    margin-left: auto;
    margin-block: auto;
    visibility: hidden;
  }

  &HasOption:hover,
  &HasOption:focus-within {
    .dropdownWrapper {
      visibility: visible;
    }
  }
}

.name {
  margin-block: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.showAll {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  margin-top: spacing(1);
  padding-inline: spacing(2);

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }
}
