@import 'frontend/styles/variables';

.chatMessage {
  position: relative;
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;

  &.chatMessageFromBot {
    white-space: initial;
  }

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }

  ul {
    padding-left: 25px !important; // Overriding inline styles
  }

  a {
    color: var(--blue-ribbon);

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }
}
