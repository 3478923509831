@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  margin-bottom: 50px;

  a.title {
    font-size: 28px;
    color: var(--blue-ribbon);
    display: inline-block;
    margin-bottom: spacing(1);
  }
  .updatedOn {
    font-weight: 800;
    font-size: 11px;
  }
}
