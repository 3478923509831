@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.pluginForm {
  .formHeader {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: spacing(1);
    align-items: baseline;

    a {
      color: var(--text-grayscale70);
      text-decoration: underline;

      &:hover {
        color: var(--blue-ribbon);
      }
    }
  }

  p {
    color: var(--text-grayscale70);
  }

  .contextKeys {
    .contextKeysWrap,
    label,
    input {
      display: inline-block;
    }
    label {
      padding-right: 6px;
    }
    .contextKeysWrap {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}

.deleteDialogueButton {
  display: inline-flex;
  justify-content: center;
  vertical-align: top;

  svg {
    margin: 0;
    font-size: 18px;
  }
}

.logoContainer {
  padding: spacing(2) 0;
  display: flex;
  align-items: center;

  .portrait {
    height: 60px;
    width: 60px;
    margin-right: spacing(3);
  }

  .upload {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 10px;
    }
  }
}
