@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 1fr 1fr;
}

.dialogueRow {
  display: flex;
  align-items: center;

  .icon {
    margin-right: spacing(1);
    width: 38px;
    height: 38px;
    border-radius: var(--border-radius-small);
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #e5f0ff;
  }
  .dialogue {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin-right: spacing(1);
    }

    .title {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }
}

.progressBar {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;

  > div {
    width: 100%;
    position: relative;

    span {
      height: 6px;
      width: 100%;
      display: block;
      border-radius: 12px;
    }
  }

  .total {
    background-color: var(--grayscale5);
  }

  .overlay {
    top: 0;
    left: 0;
    position: absolute;
    background-color: var(--green-harlequin);
  }

  .stats {
    font-size: 14px;
    color: var(--text-grayscale90);
    margin-left: spacing(2);
  }
}
