@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.slotItem {
  &Title {
    margin: spacing(0.5) 0 spacing(1);
    font-size: var(--font-size-h4);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
