@import 'frontend/styles/functions';

.agentsFilterRadio {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  flex: 1;
  padding: 0;
  .agentsFilterItem {
    font-size: var(--font-size-medium);
    color: var(--text-grayscale80);
    display: flex;
    flex-direction: row;
    align-items: center;
    & img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: spacing(1);
    }
  }
  & input:checked + div:last-child {
    background-color: var(--grayscale0);
  }

  & > div:last-child {
    padding: spacing(1);
    &:hover {
      background-color: var(--grayscale0);
    }

    &:after {
      display: none;
    }
  }
}

.agentsFilterItem {
  font-size: var(--font-size-medium);
  color: var(--text-grayscale80);
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: spacing(1);
  }
}
