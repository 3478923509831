@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.resolve {
  font-weight: var(--font-weight-medium);
  &:first-child {
    margin-right: spacing(1);
  }

  > div,
  &:not(:hover) {
    background-color: var(--grayscale5);
    border-color: var(--grayscale5);
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
