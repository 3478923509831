@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  width: fit-content;

  .grid {
    display: grid;
    grid-column-gap: spacing(1);
    grid-row-gap: spacing(2);
    margin-bottom: spacing(4);

    > * {
      text-align: center;
      justify-self: center;
      align-self: center;
    }

    .rowTitle {
      font-size: 14px;
      text-align: left;
      justify-self: flex-start;
    }

    &Header {
      font-weight: var(--font-weight-medium);
      font-size: 16px;
      margin-top: spacing(2);
      margin-bottom: spacing(2);
      padding-bottom: spacing(2);
      border-bottom: 1px solid var(--grayscale30);

      > * {
        align-self: end;
      }
    }
  }

  .sectionTitle {
    font-size: 16px;
    font-weight: var(--font-weight-medium);
  }
}
