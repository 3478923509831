@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.sectionHeader {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: spacing(2);
  align-items: center;
  margin-top: spacing(5);
  padding-bottom: spacing(1);

  h3,
  p {
    margin-bottom: spacing();
  }

  h3 {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
  }
}
