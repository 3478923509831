@import 'frontend/styles/functions';

.fieldActionsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > button {
    margin-top: spacing(1);
    margin-right: spacing(1);
    &.rangeButton {
      & > svg {
        & > path {
          // The scale is broken a bit
          // Remove when design pushes a proper icon fix
          stroke-width: 0;
        }
      }
    }
  }
}
