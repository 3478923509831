@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.listWrapper {
  overflow: hidden;
  border-radius: var(--border-radius-large);
}

.dialogueMenuItem {
  text-decoration: none;
  text-align: left;
  padding: 0;
  background-color: var(--grayscale20);
  font-weight: var(--font-weight-medium);
  font-size: 14px;
  border: none;

  &:focus {
    @include noOutline;
  }

  &:hover {
    background-color: var(--grayscale5);
  }

  a {
    color: var(--grayscale70);
    display: block;
    text-decoration: inherit;
    padding: spacing(1) spacing(2);

    &:hover {
      text-decoration: none;
    }
  }
}

.border {
  height: 1px;
  width: 100%;
  background-color: var(--grayscale30);
}
