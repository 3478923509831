@import 'frontend/styles/functions';

.ruleListItem {
  display: flex;
  align-items: center;
  padding: spacing(1);
  border-radius: spacing(1);
  gap: spacing(0.5);

  .ruleName {
    flex: 1;
    font-weight: var(--font-weight-medium);
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    margin-right: spacing(1);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--grayscale0);
  }

  &:active {
    color: var(--primary-color);
    background-color: var(--grayscale10);
  }
}

.listItemNumber {
  display: flex;
  width: 24px;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  color: var(--grayscale70);
}

.settings {
  display: none;
  align-self: center;
  margin-left: auto;
  border-radius: spacing(0.5);

  .ruleListItem:hover &,
  .ruleListItem:focus-within & {
    display: block;
  }

  &:hover {
    background-color: var(--grayscale10);
  }
}

/* To make the overlay bleed out of overflow: scroll */
.settingsOverlay {
  position: fixed;
  top: unset;
  left: unset;
}
