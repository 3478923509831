@import 'frontend/styles/functions';

.modalContent {
  p {
    color: var(--grayscale70);
    line-height: 1.45;
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: var(--font-size-medium);
  cursor: pointer;
}

.languageSelection {
  display: flex;

  div,
  label {
    display: flex;
    flex: 1;
  }

  .selectLanguage {
    font-size: var(--font-size-medium);
  }
}

.checkbox {
  margin-right: 10px;
}
