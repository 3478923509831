@import 'frontend/styles/functions';

.container {
  background-color: inherit;
  display: flex;
  flex-direction: column;
  flex: 1;

  & .chatsLengthWrapper {
    display: flex;
    flex-direction: column;
    &.chatScrolled {
      z-index: 1;
      box-shadow: var(--box-shadow-card-bottom);
    }
    & .chatsLength {
      padding: 0 spacing(1) spacing(1);
      color: var(--text-grayscale80);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
    }
  }

  & .chatsContent {
    overflow-y: auto;
    flex-grow: 1;
    flex-basis: 0px;
    gap: spacing(0.75);
    scrollbar-gutter: stable;
    display: flex;
    flex-direction: column;
    padding: spacing(1) spacing(0.5);
    &.scrolledContainer {
      position: relative;
    }
    &.emptyContainer {
      justify-content: center;
    }

    & .emptyStateWrapper {
      max-width: 270px;
    }
  }
}
