@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.inputContainer {
  width: 50%;
  margin: spacing(4) 0;
  &.urlInputContainer {
    margin-bottom: spacing(2);
  }
  @media (max-width: $desktop-small) {
    width: 100%;
  }
  & > .fieldDescription {
    max-width: none;
  }

  & .urlsWrapper {
    margin-top: spacing(2);
    display: flex;
    gap: spacing(1.5);
    flex-direction: column;
    & .urlContent {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  & .inputRadioWrapper {
    display: flex;
    flex-direction: row;
    & > div {
      width: auto;
      margin-right: spacing(2);
    }
  }
}

.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > label:last-child {
    margin-left: spacing(1);
  }
}

.buttons {
  display: flex;
  margin: 35px 0 0;
  justify-content: space-between;
}

.fieldDescription {
  max-width: 50%;
  color: var(--grayscale80);
  font-size: var(--font-size-small);
  @media (max-width: $desktop-small) {
    max-width: 100%;
  }
  & p {
    font-size: var(--font-size-small);
  }
}

.globalIconsContainer {
  padding: spacing(2);
  label {
    font-weight: 500;
    margin-bottom: 0;
  }

  & .globalIconsTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    margin-top: spacing(0.5);
  }

  .slider {
    margin: spacing(2) 0;
  }

  .buttons {
    display: flex;
    margin: 35px 0 0;
    justify-content: space-between;
  }
}

.chatbubbleSettingsWrapper {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 360px) 1fr;
  gap: spacing(3);
  & .globalIconsPreview {
    padding: spacing(2);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    border-right: 1px solid var(--grayscale15);
    &.emptyPreview {
      align-items: center;
      & .addIconBtn {
        margin-top: spacing(2);
      }
    }

    & .addMoreIconBtn {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      justify-content: center;
    }

    & .globalIconsList {
      padding: spacing(1.25) spacing(1);
      gap: spacing(1);
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & .globalIconsContent {
        gap: spacing(1);
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        & .globalIconsEditableBtn {
          min-height: 48px;
        }
      }

      & .globalIconTitle {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-medium);
        margin-right: spacing(1);
      }

      & .globalIconImg {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .globalIconPlaceholder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: spacing(0.75) spacing(0.75) spacing(0.75) spacing(2);
        color: var(--white);
        background-color: var(--white);
        border-radius: 48px;
        font-weight: var(--font-weight-medium);
        gap: spacing(1);
        &.globalIconPlaceholderText {
          padding: spacing(0.75);
        }
        & .globalIconChatbubbleBtnImageContainer {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.buildPanelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: spacing(2) spacing(2) 0;
  & .buildPanelTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    & > svg {
      font-size: 22px;
    }
  }

  & .formSubmitBtn {
    margin-left: spacing(1);
  }
}
