@import '../../../../../styles/variables';

.passwordInput {
  width: 100%;
  border: 1px solid var(--grayscale20);
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  padding: 6px 11px;
  color: var(--text-grayscale70);
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.multipleWarning {
  color: var(--orange-color);
  font-weight: bold;
}
