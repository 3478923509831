@import 'frontend/styles/functions';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cellText {
  &Wrapper {
    display: flex;
    align-items: center;
    gap: spacing(1);
  }

  &Disabled {
    text-decoration: line-through;
  }

  &DisabledWord {
    color: var(--text-grayscale70);
  }
}

.primaryLanguageIcon {
  color: var(--triad-yellow-medium);
}

.dot {
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: spacing(1);
    border-radius: 50%;
    background-color: var(--green-harlequin);
  }

  &.cellTextDisabled::before {
    background-color: var(--grayscale50);
  }
}

.languageName {
  font-weight: var(--font-weight-medium);
}

.dropdownTrigger {
  opacity: 0;
  border-radius: var(--border-radius-medium);

  .rowClassName:hover &,
  .rowClassName:focus-within & {
    opacity: 1;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--grayscale0);
  }

  &:active,
  &[aria-expanded='true'] {
    background-color: var(--grayscale10);

    & > svg > path {
      stroke: var(--blue-ribbon);
    }
  }

  &:focus-visible {
    outline: 1px solid var(--blue-ribbon);
  }
}
