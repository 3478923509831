@import 'frontend/styles/functions';

.wrapper {
  margin-bottom: spacing(3);
}

.labelsSection {
  max-height: 50vh;
  overflow-y: auto;
}
