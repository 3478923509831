@import 'frontend/styles/functions';

.container {
  background-color: var(--grayscale5);
}

.header {
  padding: spacing(0.5) spacing(1) spacing(1) spacing(2);
  border-bottom: 1px solid var(--white);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: spacing(1);
  font-size: 14px;
  font-weight: var(--font-weight-medium);

  > label {
    margin: 5px 0;
    font-size: 13px;
  }
}
.close {
  width: auto;
  padding: unset;
}
