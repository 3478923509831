@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/features/Inbox/inbox-styles';

.handoverBanner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--grayscale90);
  color: var(--white);
  text-align: center;
  padding: 3px 10px;
  z-index: 2;
}

.conversation {
  height: $inboxHeight;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $phone) {
    height: $inboxMobileHeight;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    height: 20px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0;
    transition: opacity linear 300ms;
  }
  &.showFade:before {
    opacity: 1;
    transition: opacity linear 300ms;
  }

  &Ghost {
    padding: spacing(3);
    box-sizing: border-box;
  }

  &BubblesWrapper {
    overflow-y: scroll;
    padding: 0 spacing(2) spacing(2);
    box-sizing: border-box;
    height: 100%;
    margin: 0 spacing(0.5) spacing(0.5) 0;
  }
}

.userIsTyping {
  position: absolute;
  bottom: 1px;
  left: 43px;
  font-size: 12px;

  span {
    font-weight: var(--font-weight-medium);
  }
}

.loadMore {
  text-align: center;
}
