@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.privacyWrapper {
  p,
  a {
    color: var(--text-grayscale70);
  }
}

.personalDaysButton {
  height: 40px;
  width: 40px;
  max-width: 40px;
  border: 1px solid var(--grayscale30);
}

.personalDaysWrapper {
  display: flex;
  align-items: center;
}

.plusMinusNumber {
  width: 3em;
  border: none;
  padding: 0 5px;
  font-size: 20px;
  text-align: center;
  font-weight: var(--font-weight-medium);

  &:focus {
    @include noOutline;
  }
}

.selectLanguage {
  max-width: 200px;
}

.anonymizationRow {
  border-bottom: 1px solid var(--grayscale5);
}

.content {
  padding: 0;
}

.sectionTitle {
  font-weight: var(--font-weight-medium);
}
