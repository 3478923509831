@import 'frontend/styles/functions';

.panelWrapper {
  padding-top: 20px;
}

.downloadButtonWrapper {
  display: flex;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: flex-start;
}
