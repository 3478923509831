@import 'frontend/styles/functions';

.container {
  width: max-content;
  color: var(--grayscale80);
  cursor: pointer;
  user-select: none;
}

.choiceWrapper {
  display: flex;
  padding: spacing(0.5) spacing(2);
  gap: spacing(0.5);
  align-items: center;

  &:hover {
    background-color: var(--grayscale10);
  }

  &:active {
    color: var(--blue-ribbon);
  }
}

.icon {
  font-size: 16px;
}

.choiceText {
  margin: spacing(0.5);
}
