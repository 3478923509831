.ghostMetaContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ghostCircle {
  position: relative;
  width: 100%;
  height: 70px;
}
.ghostLines {
  position: relative;
  width: 100%;
  height: 100px;
}

.ghostContainer {
  position: relative;
  overflow: hidden;
  height: 100px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0;

  &.topContainer {
    height: 110px;

    .ghostStripe {
      display: block;
    }
    .ghostLines {
      height: 40px;
    }
  }
}
