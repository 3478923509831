@import 'frontend/styles/functions';

.wrapper {
  min-width: 160px;
  width: max-content;
  padding: spacing(1) 0;
  margin: 0;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-450);
  list-style-type: none;
  color: var(--text-grayscale80);

  & .separator {
    width: 100%;
    height: 1px;
    margin-block: spacing(0.5);
    background-color: var(--grayscale15);
  }

  .li {
    margin-top: spacing(0.25);
    line-height: 20px;
    &:first-child {
      margin-top: 0;
    }

    &:has(.option:disabled) {
      cursor: not-allowed;
    }

    & .option {
      border: none;
      background-color: transparent;
      color: inherit;
      font: inherit;
      display: flex;
      width: 100%;
      padding: spacing(0.5) spacing(1.5);
      gap: spacing(0.5);
      align-items: center;
      cursor: pointer;

      &.redText {
        color: var(--red-pomegranate);
      }

      & .icon {
        font-size: 18px;
        flex-shrink: 0;
      }

      &:hover,
      &:focus-visible {
        background-color: var(--grayscale5);

        &:where(:not(.redText)) {
          color: var(--text-grayscale90);
        }
      }

      &:active {
        background-color: var(--grayscale10);

        &:where(:not(.redText)) {
          color: var(--blue-ribbon);
        }
      }

      &:focus-visible {
        outline: 1px solid var(--blue-ribbon);
        outline-offset: -1px;
      }

      &:disabled {
        opacity: 0.3;
        pointer-events: none;
        user-select: none;
      }
    }
  }
}
