@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';
@import 'frontend/features/Inbox/inbox-styles';

:global {
  body .inbox-message-hash-highlight,
  body .inbox-message-hash-highlight:hover {
    background-color: var(--blue-ribbon--very-light);
    transition: background-color 200ms linear 500ms;
  }
}

.chatGroup {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  grid-gap: 6px 25px;
  margin: spacing(1) 0;
  word-break: break-word;
  width: auto;
  padding: 20px;
  border-radius: var(--border-radius-medium);

  &:hover {
    background-color: var(--grayscale20);

    .options {
      display: inline-flex;
    }

    .chatGroupButton {
      background: var(--grayscale30);

      &Click {
        background: var(--grayscale10);
      }
    }
  }

  &Active {
    background-color: var(--grayscale20);
  }

  @media (max-width: $desktop-small) {
    grid-gap: 6px 20px;
  }
}

.seenIcon {
  position: relative;
  right: 9px;
  top: 4px;
  font-size: 14px;
  margin-right: 0;
}

.botOrAgent,
.user {
  grid-template-columns: auto 1fr;
  position: relative;

  .avatar {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-start;
    object-fit: cover;
  }
  .email {
    grid-column: 2;
    grid-row: 2;
    color: var(--grayscale70);
    font-size: 90%;
    margin-bottom: spacing(1);
  }
  .verticalLine {
    grid-column: 1;
    grid-row: 2 / 4;
    height: 100%;
    background: linear-gradient(var(--grayscale30), var(--grayscale30)) no-repeat center/1px 100%;
    margin-top: spacing(1);
  }
  .chatbubbles {
    grid-column: 2;
    grid-row: 3;
    align-items: flex-start;
  }
  .bubbleInfo {
    grid-column: 2;
    grid-row: 1;
    text-align: left;
    margin-bottom: 4px;
    color: var(--text-grayscale70);

    & > * {
      margin-right: 15px;
    }
  }
}

.botOrAgent .avatar {
  border: 2px solid var(--blue-ribbon);
}

.user:after {
  background-color: var(--grayscale20);
}

.hasCandidates {
  margin-bottom: 15px;
}

.replyCandidate {
  grid-column: 1 / 3;
  grid-row: 4;
  margin-top: 15px;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--grayscale10);
    position: absolute;
    left: 0;
    right: 0;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
  align-self: end;
}

.chatbubbles {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.time {
  font-size: 12px;
  color: var(--text-grayscale70);
}

.name {
  font-size: 12px;
  display: inline-block;
}

.options {
  display: none;
  position: absolute;
  top: -8px;
  right: 15px;
  margin-right: 0;
  border-radius: var(--border-radius-medium);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}
.optionsItem {
  padding: 3px 5px;
  box-sizing: border-box;
  border: 1px solid var(--grayscale15);
  border-left: none;
  background-color: var(--white);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  display: flex;
  font-size: 14px;
  align-items: center;
  color: var(--text-grayscale70);
  margin: 0;

  &:focus {
    @include noOutline;
  }

  &:hover {
    color: var(--blue-ribbon);

    .externalLinkIcon,
    .mlIcon,
    .urlIcon {
      color: var(--blue-ribbon);
    }
  }

  &:last-child {
    border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  }
  &:first-child {
    border-left: 1px solid var(--grayscale15);
    border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
  }
  &:only-child {
    border-radius: var(--border-radius-small);
  }
}

.externalLinkIcon,
.urlIcon,
.mlIcon {
  font-size: 18px;
}

.threeDots {
  min-width: 30px;
  display: flex;
  justify-content: center;

  .dots {
    @include threeDots(var(--text-grayscale90));
  }

  &:hover .dots {
    @include threeDots(var(--blue-ribbon));
  }
}
.urlIcon {
  font-size: 18px;
}
