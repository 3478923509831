@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.separator {
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.button {
  cursor: pointer;
  box-shadow: none;
  width: max-content;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #fff;
  display: inline-block;
  background-color: #0069ff;

  transition: opacity 0.1s ease-in;

  &:hover {
    opacity: 0.8;
  }
}
