@import 'frontend/styles/functions';

.reply {
  display: flex;
  flex-direction: row;
  padding: spacing(0.5);
  border-radius: var(--border-radius-small);

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: var(--grayscale15);
  }

  .replyScore {
    background-color: var(--blue-ribbon);
    color: var(--white);
    border-radius: var(--border-radius-small);
    padding: spacing(0.25) spacing(0.75) spacing(0.25);
  }

  .text {
    text-decoration: none;
    color: var(--text-grayscale90);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &Disabled {
    opacity: 0.5;

    .text {
      text-decoration: line-through;

      &:hover {
        text-decoration: line-through;
      }
    }
  }
}
