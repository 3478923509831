@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.sectionContainer {
  margin-bottom: spacing(3);
  background-color: var(--white);
  border-radius: var(--border-radius-large);
}

.seperator {
  border-bottom: 1px solid var(--grayscale5);
}

.title {
  font-size: 16px;
  display: flex;
  flex-grow: spacing(1);
  margin-bottom: 0;
  font-weight: var(--font-weight-medium);
  padding: spacing(2);
  align-items: center;

  .dialogue {
    margin-right: 5px;
  }
}

.listHeader {
  padding-left: spacing(2);
  display: grid;
  grid-gap: spacing(2);
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: spacing(1);
  margin-top: spacing(1);

  span {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    min-width: 300px;
  }
}

.listContent {
  display: grid;
  grid-gap: spacing(1);
}
