@import 'frontend/styles/variables';

span.tagKindly {
  background: var(--grayscale60);
  color: var(--white);

  &Transparent {
    background: rgba(0, 0, 0, 0.43);
  }
}
