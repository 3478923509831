@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.modalContainer {
  max-width: 1100px;
}

.modalContent {
  padding: 0;
}

.titleContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 103px;
  border-bottom: 1px solid var(--grayscale5);
  padding: spacing(2) 0;
  font-weight: var(--font-weight-medium);
}

.title {
  padding-left: 30px;
  font-size: 14px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: var(--font-weight-medium);
  color: var(--text-grayscale90);
  text-decoration: none;
  display: flex;
  align-items: center;

  .icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: spacing(1);
  }
  .text {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &:hover {
    text-decoration: underline;
  }
}

.modalFooter {
  display: flex;
  gap: spacing(2);
  justify-content: flex-end;
  align-items: center;

  span {
    font-size: 14px;
    color: var(--text-grayscale90);
  }
}
