@import 'frontend/styles/functions';

.fieldInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & .fieldInnerWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex: 1;

    & > svg {
      margin-bottom: 4px;
      margin-left: 12px;
    }

    & .hidePlaceholderContent {
      width: 36px;
    }

    & .fieldContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      &:not(:first-child) {
        margin-left: spacing(2);
      }
    }
  }
  &.fieldInputColumn {
    flex-direction: column;
    align-items: flex-start;
    & .fieldInnerWrapper {
      align-self: stretch;
    }
  }

  & .fieldDescription {
    margin-top: spacing(2);
    font-size: var(--font-size-small);
    color: var(--text-grayscale70);
  }
}
