@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';
@import 'frontend/styles/functions';

.highlight {
  position: relative;

  > :first-child {
    background-color: #b4d5fe;
  }

  &Button {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    bottom: calc(50% - 52px);
    left: calc(50% - 49px);
    font-size: 14px;
    background: var(--grayscale5);
    padding: 0px 6px 0 3px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    line-height: 31px;
    border: none;
    box-shadow: 1px 3px 4px 1px var(--shadow-color);
    font-weight: 500;
    z-index: 2;
    @include noOutline;

    &:hover {
      background: var(--grayscale20);

      .highlightButtonTriangle {
        fill: var(--grayscale20);
        @include noOutline;
      }
    }

    &:active {
      background: var(--grayscale30);

      .highlightButtonTriangle {
        fill: var(--grayscale30);
      }
    }

    &Icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 5px;
      background-color: var(--blue-ribbon);
      color: var(--white);
      width: 17px;
      height: 17px;
      padding: 0;

      svg {
        flex-grow: 1;
        width: 13px;
        height: 13px;
      }
    }

    &Triangle {
      position: absolute;
      top: -8px;
      height: 8px;
      width: 16px;
      left: calc(50% - 8px);
      fill: var(--grayscale5);
    }
  }
}
