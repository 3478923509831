@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.composerWrapper {
  position: relative;
  border-radius: var(--border-radius-small);
  border: 2px solid var(--grayscale30);
  font-size: 14px;

  &:hover,
  &:focus-within {
    border-color: var(--blue-ribbon);
  }

  &.formControlMischka {
    background-color: var(--grayscale5);
    border: 2px solid var(--grayscale5);
    border-radius: var(--border-radius-large);

    :global {
      .DraftEditor-root {
        background-color: var(--grayscale5);
      }
    }

    &:hover {
      background-color: var(--grayscale10);
      border-color: var(--grayscale10);

      :global {
        .DraftEditor-root {
          background-color: var(--grayscale10);
        }
      }
    }

    &:focus-within {
      border-color: var(--blue-ribbon);

      :global {
        .DraftEditor-root {
          background-color: var(--white);
          border-radius: var(--border-radius-medium);
        }
      }
    }
  }
}

:global {
  .DraftEditor-root {
    background: var(--white);
    line-height: 23px;
    padding: spacing(0.75) spacing(1) spacing(0.5);
    border-radius: var(--border-radius-medium);
    caret-color: black;
  }

  div.public-DraftStyleDefault-block {
    margin: 0;
  }
}
