@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/functions';

// TODO make more generic select input, since its use in multiple places and can be found in urlInput, etc...
@mixin inputControl {
  @include noOutline;
  font-size: 14px;
  border: 2px solid var(--grayscale5);
  background-color: var(--grayscale5);
  border-radius: var(--border-radius-large);
  box-sizing: border-box;
  color: var(--text-grayscale90);

  &:hover {
    background-color: var(--grayscale10);
    border-color: var(--grayscale10);
  }

  &:focus {
    border-color: var(--blue-ribbon);
    background-color: var(--white);
  }
}
.affixWrapper {
  display: flex;
  flex-direction: row;
  .select {
    @include inputControl;
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
    min-width: spacing(11);
    padding-left: 11px;
    cursor: pointer;

    &.error {
      border-color: var(--red-pomegranate);
    }

    &Wrapper {
      position: relative;
      height: 100%;
      & > div:first-child {
        height: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        z-index: 1;

        border-top: 7px solid var(--text-grayscale90);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
  }

  & > div:last-child {
    margin-left: 12px;
    flex: 1;
  }
}
