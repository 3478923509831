@import 'frontend/styles/functions';

.fieldCheckboxWrapper {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: spacing(2);
  &:first-child {
    margin-left: 0;
  }

  & .fieldSwitch {
    margin-right: spacing(1);
  }
}
