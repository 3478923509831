@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  margin: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: spacing(5);
}

.orgListSearch input {
  background-color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  padding-left: 32px;
}

.listHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 spacing(2);

  h4 {
    font-weight: var(--font-weight-medium);
  }
}

.listContainer {
  margin-bottom: spacing(6);

  > a {
    color: inherit;
    text-decoration: none;

    > div {
      margin-bottom: spacing(2);
    }
  }
}

.deleteOrgRow {
  margin-bottom: spacing(2);
}
