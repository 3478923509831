@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.container {
  :global .select-search-box__select {
    max-width: 300px;
    left: -50px;
  }
}

.selectedTimezone {
  padding: 2px;

  button {
    font-weight: unset;
    color: var(--text-grayscale90);
    padding-left: 0px;
  }
}
