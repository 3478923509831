@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.regex {
  --regex-input-height: 34px;

  display: flex;
  margin: spacing(1) 0 spacing(2);

  &Part {
    height: var(--regex-input-height, 34px);
    padding: spacing(1);
    border: 2px solid var(--grayscale20);
    background: var(--white);
  }

  &Label {
    line-height: var(--font-size-medium);
    border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
    border-right: none;
  }

  button {
    height: var(--regex-input-height, 34px);
    padding: spacing(0.5);
    border: solid var(--grayscale20);
    border-width: 2px 2px 2px 0;
    border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  }

  .input {
    flex-grow: 1;

    input {
      border-radius: 0;
    }
  }
}
