@import 'frontend/styles/functions';

.componentWrapper {
  display: flex;
  width: 100%;
  max-width: 300px;
  margin: auto auto 0;
  gap: spacing(1);
  flex-direction: column;
}

.customSelect {
  position: relative;
  display: grid;
  grid-template-areas:
    'image agent chevron'
    'image status chevron';
  place-items: center;
  gap: spacing(0.5) spacing(1);

  width: 100%;
  padding: spacing(1);
  color: var(--text-grayscale90);
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  overflow: hidden;

  &:hover {
    background-color: var(--grayscale0);
  }
}

.nativeSelect {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none; // select element is not resizable in Safari without this

  &:disabled {
    cursor: not-allowed;
  }
}

.agent {
  &Img {
    grid-area: image;

    width: 32px;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: inset 0 2px 6px #0000000a;

    &Away {
      filter: grayscale(100%);
    }
  }

  &Name {
    grid-area: agent;

    max-width: 100%;
    justify-self: start;
    font-weight: var(--font-weight-medium);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
  }

  &Status {
    grid-area: status;

    min-width: max-content;
    justify-self: start;
    font-size: var(--font-size-small);
    line-height: 1;

    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      aspect-ratio: 1;
      margin-inline-end: spacing(0.5);
      border-radius: 50%;
      background-color: var(--_dot-color);
    }

    &Auto:before {
      background-color: unset;
      width: 7px;
      border: 1px solid var(--_dot-color);
    }
  }
}

.iconChevron {
  grid-area: chevron;

  width: 100%;
  margin-left: spacing(1);
}
