@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.loaderWrapper {
  border: 2px solid var(--grayscale5);
  border-radius: var(--border-radius-medium);
  padding: 7px 10px 2px;
}

.renderDialogue {
  position: relative;
  display: inline-block;
  padding: spacing(1);
  cursor: pointer;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}
.highlighted {
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--yellow-sunglow);
    margin-right: spacing(1);
  }
}

.disabled {
  color: var(--grayscale60);
  text-decoration: line-through;
  text-decoration-color: var(--grayscale90);
  word-break: break-word;
}

.isSelected {
  font-weight: var(--font-weight-medium);
  background: var(--grayscale5);
  border-radius: var(--border-radius-large);

  &:after {
    display: block;
    content: '';
    width: 13px;
    height: 12px;
    top: 11px;
    right: 8px;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 13'%3E%3Ctitle%3ECheck icon%3C/title%3E%3Cpath fill='none' stroke='%23000000' d='M2 6.5L5.61 11 12 2'/%3E%3C/svg%3E");
  }
}

.labelWrapper {
  display: flex;
  gap: spacing(1);
  justify-content: space-between;
  align-items: center;
}

.tooltipWrapper {
  div.tooltip {
    top: calc(100% + 8px);
    white-space: normal;
  }
}
