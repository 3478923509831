@import '../../../../styles/variables';
@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.form {
  &DropdownContent {
    font-size: 14px;
  }

  &DropdownWrapper {
    margin-left: 6px;
    & > button {
      padding: 8px;
      &:active {
        color: var(--blue-ribbon);
      }
    }
  }
  &Wrapper {
    & .formWrapperContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      & .emptyFormWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .emptyFormContent {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & .emptyFormAddActions {
            margin-top: 8px;
          }
        }
        & .emptyFormInfo {
          margin: 34px 0;
          & .emptyFormInfoBox {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }

  &Actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.addFieldTrigger {
  font-weight: 450;
}
