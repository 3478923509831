@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.actionsButton {
  margin-left: auto;
}

.button {
  padding: spacing(0.5) spacing(2);
  border: 1px solid var(--grayscale30);
  border-radius: 20px;
}

.dialogue {
  padding-left: spacing(2);
  position: relative;
  color: var(--text-grayscale70);
  text-decoration: underline;

  &:hover {
    color: var(--text-grayscale90);
  }

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0;
    top: 6px;
    background-color: var(--yellow-sunglow);
  }

  &Disabled {
    color: var(--text-grayscale70);
    text-decoration: line-through;
  }
}

.brokenLink {
  color: var(--red-pomegranate);
}

.buttonIcon {
  font-size: 20px;
  padding-right: spacing(0.5);
}
