// TODO: Think this can be specified in tolerance prop instead
@import 'frontend/styles/functions';

:global {
  .selectable-group {
    --_top-select-padding: 0px;
    --_bottom-select-padding: 50px;

    position: relative;
    top: var(--_top-select-padding);
    left: -40px;
    width: calc(100% + 25px);
    padding: var(--_top-select-padding) 0 var(--_bottom-select-padding) 15px;
    margin-bottom: calc(var(--_bottom-select-padding) * -1);
    user-select: none;
  }
  .selectable-selectbox {
    position: absolute;
    z-index: 10;
    cursor: default;
    background: none;
    border: 1px dashed grey;
  }
}
