@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Completed {
    justify-content: flex-end;
  }

  button {
    font-size: 14px;
    color: var(--text-grayscale90);
  }

  .goToIcon {
    align-self: center;

    svg path {
      stroke: var(--grayscale70);
      fill: var(--grayscale70);
    }
  }

  .resolve {
    &:first-child {
      margin-right: spacing(1);
    }
    font-weight: var(--font-weight-medium);

    > div,
    &:not(:hover) {
      background-color: var(--grayscale5);
      border-color: var(--grayscale5);
    }
  }

  .undo {
    font-weight: var(--font-weight-medium);
    color: var(--white);

    > div,
    &:not(:hover) {
      background-color: var(--grayscale70);
      border-color: var(--grayscale70);
    }
  }
}
