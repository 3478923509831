@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import '../../Build';

.pageContent {
  display: grid;
  height: calc(100vh - 105px);
  padding: 0px;
  grid-template-areas:
    'header panel'
    'container panel';
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr) auto;
  overflow: hidden;
  .newDialogue {
    display: block;
    max-width: 700px;
    margin: auto;
    margin-top: 10vh;
    text-align: center;

    &Buttons {
      display: grid;
      grid-auto-columns: auto;
      grid-gap: var(--gutter-medium);
      grid-auto-flow: column;
      justify-content: center;
    }
  }

  .tabsContainer {
    grid-area: container;

    display: grid;
    grid-template-columns: auto 2px 360px 2px 10fr auto;
    height: 100%;
  }

  .tabContentWrapper {
    overflow: auto;
    position: relative;
    padding: spacing(3);
    margin: spacing(0.5) spacing(0.5) spacing(0.5) 0;
  }
}
