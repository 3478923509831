.overlayWrapper {
  position: fixed;
  left: var(--_contextMenuLeft);
  top: var(--_contextMenuTop);
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: var(--box-shadow-level2);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  z-index: 5;
  &.menuLeft {
    transform: translateX(calc(-100% + 12px));
  }
}

.translateUp {
  transform: translateY(-100%);
}

.translateRight {
  transform: translateX(-100%);
}

.contextMenuOverlayWrapper {
  width: 0px;
  height: 0px;
}
