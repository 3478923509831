@import 'frontend/styles/functions';

.fakeButton {
  position: relative;
  display: inline-flex;
  padding: spacing(0.5) spacing(2.5);
  border: 1px solid rgba(0, 0, 0, 0.08);
  align-items: center;
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  font-weight: var(--font-weight-medium);
  gap: spacing(1);
}

.iconsWrapper {
  position: absolute;
  display: flex;
  inset: 0;
  justify-content: space-evenly;
  border-radius: inherit;
  background-color: white;
  transform: scaleX(0);

  .fakeButton:hover & {
    transform: scaleX(1);
    transition: transform 0.1s linear;
  }
}

.iconButton {
  all: unset;
  outline: revert;
  cursor: pointer;

  svg {
    font-size: 20px;
    vertical-align: middle;
  }
}

.iconTrash {
  color: var(--red-pomegranate);
}
