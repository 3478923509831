@import 'frontend/styles/functions';

.search {
  &Content {
    position: relative;
  }

  svg {
    color: var(--grayscale70);
  }

  &Input {
    input {
      font-size: var(--font-size-medium);
      border-radius: var(--border-radius-large);
      padding: 6px 21px 6px 24px;
      color: var(--text-grayscale90);
      font-weight: var(--font-weight-medium);
    }
  }

  &Results {
    max-height: calc(100vh - 227px);
    overflow: auto;
    padding-right: 20px;
    position: relative;
  }

  &ResultsHeader {
    margin-top: spacing(3);
    padding: 5px;
    word-wrap: break-word;

    span {
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-medium);
      color: var(--text-grayscale90);
    }
  }

  &EmptyResult {
    padding: 5px;
    display: block;
    font-size: var(--font-size-medium);
  }

  &Close {
    position: absolute;
    border: 0;
    top: 4px;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    background: transparent;

    &:hover {
      cursor: pointer;
    }
  }
}
.iconClose {
  font-size: 18px;
  color: #333;
}
