@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.container {
  margin: 0 spacing(2);
  color: var(--text-grayscale90);
  text-decoration: none;
  position: relative;
  max-width: 150px;
  text-align: left;
  overflow: hidden;
  max-height: 35px;

  &:focus {
    @include noOutline;
  }
}

.titleWrapper {
  display: flex;
}

%titleDefault {
  display: inline;
  font-size: 14px;
  font-family: var(--font-family);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

%animateDefault {
  position: absolute;
  display: none;
  pointer-events: none;
}

.title {
  font-weight: 600;
  @extend %titleDefault;

  &.animate {
    @extend %animateDefault;
    top: 0;
  }
}

.subTitle {
  @extend %titleDefault;

  &.animate {
    @extend %animateDefault;
    bottom: 0;
  }
}

.static.overflow {
  &:hover {
    opacity: 0;

    ~ .animate {
      display: block;
      animation-name: slide;
      animation-duration: 3s;
      animation-delay: 500ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-100% + 150px));
  }
}
