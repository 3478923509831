@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/mixins';

$padding: spacing(1);
$progress-bar-height: 5px;

.container {
  background-color: none;
  top: 120px;
  width: 360px !important;
  display: flex;
  flex-direction: column;

  .toast {
    border-radius: 6px;
    background-color: var(--white);
    padding: $padding;
    align-self: flex-end;
    min-height: unset;
    width: auto;
    max-width: 100%;

    .ignore {
      color: var(--black);
      text-decoration: underline;
      padding-left: 0;
    }

    .toastBody {
      margin: 0;
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: normal;
      color: var(--black);

      a {
        color: var(--black);
        text-decoration: none;
      }

      .toastContent {
        display: flex;
        align-items: flex-start;

        p {
          margin: 0;
        }

        .sentryId {
          background-color: var(--grayscale5);
          border-radius: var(--border-radius-small);
          display: inline-block;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 1px;
        }
      }

      hr {
        border: 1px solid var(--grayscale5);
        margin-right: -$padding;
        margin-left: -$padding;
      }
    }

    .closeToast {
      position: absolute;
      top: 3px;
      right: 0;

      &:focus {
        @include noOutline;
      }
      &:hover {
        background-color: var(--grayscale15);
      }
      &:active {
        background-color: var(--grayscale20);
      }
    }

    .progressBar {
      background: var(--grayscale30);
      height: $progress-bar-height;
    }
  }
}
.errorMessage {
  padding-right: spacing(4);
}

.icon {
  position: relative;
  top: -1px;
  margin-right: spacing(1);
}
