@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.image {
  &Dragging {
    opacity: 0.2;
  }

  &Single {
    width: auto;
    height: 100px;

    img {
      object-fit: unset;
      width: auto;
      height: 100%;
    }
  }

  &WithOptions:hover {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: var(--border-radius-large);
    }

    .imageButtons {
      display: block;
    }
  }

  &Buttons {
    z-index: 2;
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    svg {
      margin: 0 spacing(1);
      padding: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &:hover .imageButtons {
    display: block;
  }

  &EditButton {
    cursor: pointer;
    display: inline-block;
    background: none;
    border: none;
    @include noOutline;

    svg {
      pointer-events: none;
    }
  }
}
