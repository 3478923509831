@import 'frontend/styles/functions';

.chatMessagesWrapper {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  flex-basis: 0;
  width: 100%;
  & .chatMessagesContainer {
    gap: spacing(2);
    display: flex;
    flex-direction: column;
    max-width: 832px;
    width: 100%;
    align-self: center;
    flex: 1;
    justify-content: flex-end;
    padding: spacing(2) spacing(2.5625);
    background-color: var(--white);
    & > div:last-child {
      padding-bottom: spacing(1);
    }
    &.chatMessagesEmpty {
      justify-content: center;
    }

    & .chatMessagesEmptyWrapper {
      width: 180px;
    }
  }
}
