@import '../../styles/variables';

@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin tooltipStyles {
  background-color: var(--grayscale90);
  color: var(--white);
  border-radius: var(--border-radius-small);
  box-shadow: 0 0 12px var(--shadow-color);
  z-index: 99999;
  padding: 11px 15px 9px;

  &.hasChevron {
    &:after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      top: -6px;
      left: 0;
      right: 0;
      margin: auto;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--grayscale90);
    }
  }
}

.tooltipWrapper {
  position: relative;
  &.alwaysActive .tooltip,
  &:hover .tooltip {
    display: table;
    animation-name: showTooltip;
    animation-duration: 500ms;
  }

  &.hideOnFocusWithin {
    &:hover:not(:focus-within) .tooltip {
      display: table;
      animation-name: showTooltip;
      animation-duration: 500ms;
    }

    &:focus-within .tooltip {
      display: none;
    }
  }

  .tooltip {
    @include tooltipStyles;
    position: absolute;
    width: max-content;
    max-width: var(--_maxWidth);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
}

.standalone {
  position: absolute;
  left: 50%;
  top: calc(100% + 10px);
  width: max-content;
  max-width: var(--_maxWidth);

  span {
    @include tooltipStyles;
    display: inline-flex;
    white-space: pre-line;
    position: relative;
    left: -50%;
    animation-name: showTooltip;
    animation-duration: 500ms;
  }
}
