@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.tabContent {
  max-width: 550px;
  padding-bottom: 50px; // to avoid stuff to go under our chat bubble
}

.dropdownButton {
  height: 35px;
  aspect-ratio: 1;
  border: 1px solid var(--grayscale15);
  border-radius: var(--border-radius-medium);
  color: var(--grayscale70);
  justify-content: center;

  &:hover,
  &:active,
  &[aria-expanded='true'] {
    background-color: var(--grayscale10);
  }

  &:active,
  &[aria-expanded='true'] {
    color: var(--primary-color);
    background-color: var(--grayscale15);
  }

  > svg {
    vertical-align: middle;
  }
}
