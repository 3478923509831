@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.tabsHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tabList {
  display: flex;
  gap: spacing(1);
  flex-wrap: wrap;
  margin: 0 0 50px 0;
  padding: 0;
  align-items: center;
}

.tab {
  display: inline-block;
  margin: 0;
  position: relative;
  list-style: none;
  padding: 9px 30px 8px;
  cursor: pointer;
  background: var(--white);
  color: var(--text-grayscale90);
  font-size: 14px;
  font-weight: 500;
  border-radius: var(--border-radius-large);
  border: none;

  @media screen and (max-width: $tablet-wide) {
    padding: 9px spacing(2) 8px;
  }

  &:focus {
    @include noOutline;
  }

  &Selected,
  &:hover {
    background: var(--grayscale5);
  }

  &.tabColorMischka {
    background: var(--grayscale5);

    &.tabSelected,
    &:hover {
      background: var(--white);
    }
  }

  &Error:not(.tabHorizontal) {
    span:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: var(--red-pomegranate);
      border-radius: 50%;
      left: 11px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &Horizontal {
    display: block;
    border-right: 1px solid var(--grayscale20);
    border-bottom: none;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-bottom: 1px solid var(--grayscale20);
      border-radius: 0 0 5px 5px;
    }

    &:hover:before {
      background-color: var(--grayscale20);
    }
    &.tabSelected {
      color: var(--blue-ribbon);
      background-color: transparent;
      &:before {
        background-color: var(--blue-ribbon);
        transition: all ease-in-out 100ms;
      }
    }
    &.tabError:before {
      background-color: var(--red-pomegranate);
    }

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: calc(100% - 9px);
      left: -11px;
      top: 50%;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      transform: translateY(-50%);
      transition: all ease-in-out 100ms;
    }
  }
  &Notebook {
    font-size: 12px;
    padding: 8px 16px 9px;
    border-width: 1px;
    border-bottom: 0;
    font-weight: normal;

    &:first-child {
      border-bottom-left-radius: 0;
      border-bottom: 0;
    }
    &:last-child {
      border-bottom: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.content {
  /*
  Setting position, visibility, z-index and height to make the tabs content
  hidden but rendered without using display: none; which casued TextareaAutosize
  to calculate wrong height. All tabs must always be rendered, if else,
  final-form validation will not work properly.
  */
  visibility: hidden;
  z-index: -1;
  position: absolute;
  height: 0px;
  overflow: hidden;

  &Selected {
    position: relative;
    visibility: visible;
    z-index: 1;
    height: auto;
    // The selected tab needs overflow: visible or the entity
    // dropdown will be partially hidden
    overflow: visible;
  }
}
