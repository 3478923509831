@import 'frontend/styles/functions';

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: spacing(2);

  h3,
  p {
    margin: unset;
  }
}

.headingWrapper {
  display: flex;
  width: 100%;
  gap: spacing(2);
  justify-content: space-between;
}

.noPointerEvents {
  pointer-events: none;
}
