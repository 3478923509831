@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  padding-block: spacing(2);
}

.table {
  margin-top: spacing(3);

  div div div:last-child {
    justify-content: flex-end;
    align-items: center;
  }
}
