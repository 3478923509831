@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.value {
  color: var(--text-grayscale90);
  font-size: 20px;
  font-weight: 500;
  margin-top: spacing(1);

  &Small {
    font-weight: normal;
    font-size: 18px;
    color: var(--black);
  }
}
