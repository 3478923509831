@import 'frontend/styles/functions';

.replyWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  position: relative;
  padding-bottom: spacing(3);

  .errorMessage {
    top: spacing(-4);
  }
}
.deleteIcon {
  position: relative;
  top: 5px;
  margin-left: spacing();
  box-sizing: unset;
}
