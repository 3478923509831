@import 'frontend/styles/functions';

.brokenLinkIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .brokenLinkIconContainer {
    background-color: var(--triad-red-light);
    padding: spacing(0.5);
    display: flex;
    margin-right: spacing(1);
    border-radius: var(--border-radius-large);
    & .buttonIcon {
      color: var(--red-pomegranate);
      font-size: 18px;
    }
  }
}

.brokenLinksSection {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  &.brokenListSectionEmpty {
    height: 100%;
  }

  & .brokenListSectionContent {
    flex: 1;
  }
}

.brokenLinksEmptyState {
  justify-content: center;
}
