@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.dialogueTitle {
  display: flex;
  font-size: 14px;
  padding-top: spacing(1);
  padding-bottom: spacing(1);
  border-radius: var(--border-radius-large);
  justify-content: space-between;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  &Active .sample {
    color: var(--text-grayscale90);
    font-weight: var(--font-weight-medium);
  }
}

.sample {
  &Wrapper {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Count {
    text-decoration: none;
    padding: 0 spacing(1);
    color: var(--text-grayscale90);

    &Active {
      color: var(--blue-ribbon);
    }
  }
}

.tooltip {
  z-index: 100000;
}
