@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.createPanel {
  padding: 0;
  width: unset;
  display: grid;
  overflow: hidden;
  grid-template-columns: 180px 1fr;
}

.nudgeIllustration {
  background-size: cover;
  background-position: center;
}

.textContainer {
  padding: spacing(4);

  h5 {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
  }
}

.createButton {
  float: left;
  display: block;
  margin-top: spacing(2);
  padding: 6px spacing(2);
}
