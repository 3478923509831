@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.mainMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  font-size: 14px;
  text-align: right;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
}

.subMenu,
.subMenuMobile {
  background-color: var(--white);
  padding: 0 24px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: calc(var(--navbar-z-index) - 2);
  align-items: center;
  overflow-x: auto;
  height: 36px;
  display: flex;
  text-align: left;
}

.subMenu {
  &Item {
    color: var(--text-grayscale70);
    padding: 9px 15px;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    font-weight: var(--font-weight-medium);

    &:hover {
      color: var(--text-grayscale90);
    }

    &Active {
      color: var(--blue-ribbon);
      position: relative;
    }
  }

  .indent {
    padding-left: 15px;
  }
}

.menuItem {
  display: inline-flex;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 14px;
  color: var(--text-grayscale90);
  text-decoration: none;
  border-radius: var(--border-radius-large);
  white-space: nowrap;
  font-weight: var(--font-weight-medium);
  align-items: center;
  gap: 2px;

  &:hover {
    background-color: var(--grayscale0);
  }
  &:focus {
    @include noOutline;
  }
}
.menuItemActive {
  color: var(--blue-ribbon);
  background-color: var(--grayscale10);
}

// Mobile menu

.mobileMenu {
  display: none;
  position: fixed;
  background-color: var(--white);
  left: 0;
  bottom: 0;
  top: 103px;
  width: 300px;
  padding: 30px 0;
  overflow: auto;
  border-right: 1px solid var(--border-color-base);
}

%activeMobileItem {
  background-color: var(--grayscale5);
}

.mobileMenuItem {
  display: block;
  color: var(--text-grayscale90);
  padding: 15px 25px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;

  @include noOutline;

  &Active {
    @extend %activeMobileItem;
  }
}

.mobileSubMenu {
  &Item {
    display: block;
    color: var(--text-grayscale90);
    padding: 15px 25px 15px 45px;
    font-size: 16px;
    text-decoration: none;

    &Active,
    &.active {
      @extend %activeMobileItem;
    }

    &Open {
      display: block;
    }
    &Closed {
      display: none;
    }
  }

  &Parent {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 25px;
      width: 0px;
      height: 0px;

      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid var(--grayscale90);
    }

    &Open:after {
      background-color: var(--grayscale90);
      border-left: 4px solid var(--grayscale90);
      border-right: 4px solid var(--grayscale90);
      border-top: 2px solid var(--grayscale90);
    }
  }
}

.mobileMenu,
.mobileMenuToggleButton,
.subMenuMobile {
  display: none;
}

@media (max-width: $tablet-wide) {
  .mobileMenu,
  .mobileMenuToggleButton {
    display: block;
  }
  .subMenuMobile {
    display: flex;
  }
  .mainMenu,
  .subMenu {
    display: none;
  }
}

.hidden {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
}

.visible {
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}
