@import '../../styles/forms';
@import 'frontend/styles/functions';

.wrapper {
  position: relative;
  width: 150px;
  height: auto;

  .colorInput {
    opacity: 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }
}

.color {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: unset;
  height: 36px;
  background: var(--grayscale0);
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.06),
    inset 0px 0px 1px rgba(0, 0, 0, 0.32);
  position: absolute;
  left: 0;
  right: 0;
  border-radius: var(--border-radius-large);
  top: 19px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  padding-right: spacing();
}

.colorHex {
  text-transform: uppercase;
}
.colorPreview {
  width: 22px;
  height: 22px;
  border-radius: var(--border-radius-small);
  margin: 0 spacing(1);
}

.hiddenBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 10;
}

.picker {
  position: absolute;
  z-index: 2;
  box-shadow: 0 5px 10px 0 var(--shadow-color);
  border-radius: spacing(1);
}
.pickerInputWrap {
  width: 100%;
  background: white;
  padding: spacing(1) 0;
  text-align: center;
  border-bottom-left-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);

  &::before {
    content: '#';
    font-family: monospace;
    color: var(--grayscale70);
    position: relative;
    left: spacing(-1) - 4px;
  }

  input {
    cursor: inherit;
  }
}

.pickerInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  outline: 0;
  width: 60px;
  font-family: monospace;
  position: relative;
  left: spacing(-1);
}

// Wrapped with a button
.colorPickerWrapper {
  width: max-content;
  position: relative;

  .color {
    opacity: 0;
    cursor: pointer;
  }

  button {
    padding: 0;
    border: none;
    outline: none;
    width: inherit;
    height: inherit;
    cursor: pointer;
    background-color: transparent;
  }
}
