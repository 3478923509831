@import 'frontend/styles/variables';
@import 'frontend/styles/functions';
@import 'frontend/styles/mixins';

.containerHeader {
  grid-column: 1 / 4;
  grid-row: 1;
  padding: spacing(2) spacing(2);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: spacing(1);
  align-items: center;
  border-bottom: 1px solid var(--grayscale5);
}

.header {
  &Title {
    font-size: 16px;
    font-weight: var(--font-weight-medium);
  }

  &Slug {
    font-style: italic;
    color: var(--text-grayscale70);
  }
}

.formSubmitBtn {
  margin-left: spacing(1);
}
