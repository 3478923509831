@import 'frontend/styles/variables';

.mailToLink {
  font-weight: var(--font-weight-medium);
}

.formErrors * {
  color: var(--white);
}

.globalIconsPanel {
  padding: 0;
}
