@import 'frontend/styles/functions';

.dropdownContent {
  display: inline-block;
  position: fixed;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: var(--box-shadow-level2);
  border-radius: var(--border-radius-large);
  overflow-y: auto;
  z-index: 200;
  visibility: hidden;
  width: max-content;
  &.active {
    visibility: visible;
  }

  %topArrow {
    content: '';
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--grayscale5) transparent;
  }
}
