@import 'frontend/styles/variables';
@import 'frontend/styles/mixins';

.wrapper {
  padding: 20px;
  position: relative;
  border-radius: var(--border-radius-medium);

  &:hover,
  &Focus {
    background-color: var(--grayscale20);

    .options {
      display: block;
    }
  }

  &Deleted {
    background-color: var(--red-pomegranate);
    opacity: 0;
    transition: all ease-out 700ms;
  }
}

.options {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;

  &Item {
    min-width: 100px;
    &:hover {
      background-color: var(--grayscale20);
    }
    &:focus {
      @include noOutline;
    }
  }
}
