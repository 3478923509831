@import 'frontend/styles/functions';
@import 'frontend/styles/variables';

.errorMessage {
  top: -spacing(4);
}

.dropdown {
  position: absolute;
  margin-left: -2px;
}

.editor {
  border: none;

  :global {
    .DraftEditor-root {
      background-color: var(--grayscale5);
    }
  }

  > div:first-child {
    padding: 4px 0 3px 5px;
  }
}
