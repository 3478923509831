@import './colors';

/* FIXME: this file is deprecated and will be completely replaced by CSS vars in base.scss. */
/* Please use the new vars there and relative syntax ( e.g. var(--gutter-small) ) for new features. */

// Screen sizes
$desktop: 1366px;
$desktop-small: 1280px;
$tablet-wide: 1024px;
$tablet: 768px;
$phone: 500px;

// Icons
$x-icon-fat: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI3cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgNyA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPkNvbWJpbmVkIFNoYXBlPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IkJ1aWxkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJCdWlsZC0tJmd0Oy1LZXl3b3JkcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2OC4wMDAwMDAsIC00MTUuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY2OC4wMDAwMDAsIDQxNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTTMuOTE0MzY5MDMsMy4yMDY3MjkwMyBMNi40MTQ0LDUuNzA2NzYgTDUuNzA2NCw2LjQxMzc2IEwzLjIwNzE1LDMuOTE0MDcyIEwwLjcwNzksNi40MTM3NiBMLTAuMDAwMSw1LjcwNjc2IEwyLjQ5OTkzMDk3LDMuMjA2NzI5MDMgTDAuMDAwNCwwLjcwNjc2IEwwLjcwNzQsLTAuMDAwMjQgTDMuMjA3MTUsMi40OTk1MSBMNS43MDY5LC0wLjAwMDI0IEw2LjQxMzksMC43MDY3NiBMMy45MTQzNjkwMywzLjIwNjcyOTAzIFoiIGlkPSJDb21iaW5lZC1TaGFwZSI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
