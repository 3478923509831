@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.content {
  max-width: 1040px;

  label {
    flex: 1;
    font-size: 14px;
  }
}

.title {
  font-weight: var(--font-weight-medium);
}

.apiContainer {
  margin: spacing(4) 0;
  code {
    display: block;
    margin-top: spacing(3);
    margin-bottom: spacing(2);
  }
}

.inputContainer {
  width: 50%;

  @media (max-width: $tablet-wide) {
    width: 100%;
  }
}
