@import 'frontend/styles/functions';

.chatContainerHeader {
  padding: spacing(1) spacing(2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid var(--grayscale15);
  min-height: 56px;
  & .chatContainerHeaderLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    & .chatContainerHeaderLeftImg {
      & .avatar {
        position: relative;
        width: 32px;
        height: 32px;
        flex: initial;
        margin-right: spacing(0.5);

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    & .chatContainerHeaderLeftDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: spacing(1.125);
      & .chatContainerHeaderLeftTitle {
        color: var(--text-grayscale90);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-medium);
        line-height: 20px;
      }
      & .chatContainerHeaderLeftInfo {
        color: var(--text-grayscale70);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-normal);
        line-height: 18px;
      }
    }
  }
  & .chatContainerHeaderRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacing(1.125);
  }
}
