@import 'frontend/styles/variables';
@import 'frontend/styles/functions';

.wrapper {
  margin-top: spacing(5);
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  color: var(--grayscale80);
  font-weight: var(--font-weight-medium);
  font-size: 14px;
  margin-left: spacing(1);
}

.recentlyUsedWrapper {
  margin-top: spacing(2);
}

.recentlyUsedBody {
  margin-top: 0;
}
